import React from "react";
import { FormattedMessage } from "react-intl";
import { Form } from "react-bootstrap";
import Bodega from "../filters/filtersBodegaHeader";
import Producto from "../filters/filtersProductHeader";

import "react-datepicker/dist/react-datepicker.css";

const MyHeader = (props) => {
  return (
    <header className="header--search">
      <Bodega
        id={false}
        bodegaIdError={props.form.bodega_id_error}
        handleChange={props.handleChange}
        bodegaId={props.form.bodega_id}
      />
      <Producto
        bodegaIdError={props.form.bodega_id_error}
        handleChange={props.handleChange}
        bodegaId={props.form.bodega_id}
        productoId={props.form.producto_id}
      />
      <div className="box-header left">
        <Form.Control
          as="select"
          value={props.form.estado}
          onChange={props.handleChange}
          name="estado"
        >
          <FormattedMessage id="NoSeeUsedParts">
            {(message) => <option value="0">{message}</option>}
          </FormattedMessage>
          <FormattedMessage id="SeeUsedParts">
            {(message) => <option value="1">{message}</option>}
          </FormattedMessage>
          ))}
        </Form.Control>
      </div>
    </header>
  );
};

export default MyHeader;
