import initialState from "./initialState";
import {
  GET_PLANNER_INIT,
  GET_PLANNER_SUCCESS,
  GET_PLANNER_FAILURE,
  RESET_PLANNER,
  UPDATE_PLANNER_INIT,
  UPDATE_PLANNER_SUCCESS,
  UPDATE_PLANNER_FAILURE,
  RESET_PLANNER_UPDATE,
} from "../../actions/planner/types";

export default function supervisor(state = initialState, action) {
  switch (action.type) {
    /* Setting */
    case RESET_PLANNER:
      return {
        ...state,
        dataPlanner: {},
        loadingPlanner: true,
        statusPlanner: 0,
      };
    case GET_PLANNER_INIT:
      return {
        ...state,
        loadingPlanner: action.payload,
      };
    case GET_PLANNER_SUCCESS:
      return {
        ...state,
        dataPlanner: action.payload.data,
        loadingPlanner: false,
        statusPlanner: action.payload.status,
      };
    case GET_PLANNER_FAILURE:
      return {
        ...state,
        dataPlanner: {},
        loadingPlanner: false,
        statusPlanner: 501,
      };
    case RESET_PLANNER_UPDATE:
      return {
        ...state,
        dataPlannerUpdate: {},
        loadingPlannerUpdate: false,
        statusPlannerUpdate: 0,
      };
    case UPDATE_PLANNER_INIT:
      return {
        ...state,
        loadingPlannerUpdate: true,
      };
    case UPDATE_PLANNER_SUCCESS:
      return {
        ...state,
        dataPlannerUpdate: action.payload.data,
        loadingPlannerUpdate: false,
        statusPlannerUpdate: action.payload.status,
      };
    case UPDATE_PLANNER_FAILURE:
      return {
        ...state,
        dataPlannerUpdate: {},
        loadingPlannerUpdate: false,
        statusPlannerUpdate: 501,
      };
    default:
      return state;
  }
}
