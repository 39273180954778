import initialState from "./initialState";
import {
  GET_CLIENT_INIT,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_FAILURE,
  RESET_CLIENT,
  GET_DIVISION_INIT,
  GET_DIVISION_SUCCESS,
  GET_DIVISION_FAILURE,
  RESET_DIVISION,
  GET_SUBDIVISION_INIT,
  GET_SUBDIVISION_SUCCESS,
  GET_SUBDIVISION_FAILURE,
  RESET_SUBDIVISION,
  SET_ID_DIVISION,
  ADD_ADDRESS_INIT,
  ADD_ADDRESS_SUCCESS,
  ADD_ADDRESS_FAILURE,
  RESET_ADD_ADDRESS,
  UPDATE_STATUS_CLIENT_INIT,
  UPDATE_STATUS_CLIENT_SUCCESS,
  UPDATE_STATUS_CLIENT_FAILURE,
  RESET_UPDATE_STATUS_CLIENT,
} from "../../actions/clients/types";

export default function client(state = initialState, action) {
  switch (action.type) {
    /* Setting */
    case SET_ID_DIVISION:
      return {
        ...state,
        id_division: action.payload,
      };
    case RESET_CLIENT:
      return {
        ...state,
        dataClient: {},
        loadingClient: true,
        statusClient: 0,
      };
    case GET_CLIENT_INIT:
      return {
        ...state,
        loadingClient: action.payload,
      };
    case GET_CLIENT_SUCCESS:
      return {
        ...state,
        dataClient: action.payload.data,
        loadingClient: false,
        statusClient: action.payload.status,
      };
    case GET_CLIENT_FAILURE:
      return {
        ...state,
        dataClient: {},
        loadingClient: false,
        statusClient: 501,
      };

    case RESET_DIVISION:
      return {
        ...state,
        dataDivision: {},
        loadingDivision: false,
        statusDivision: 0,
      };
    case GET_DIVISION_INIT:
      return {
        ...state,
        loadingDivision: true,
      };
    case GET_DIVISION_SUCCESS:
      return {
        ...state,
        dataDivision: action.payload.data,
        loadingDivision: false,
        statusDivision: action.payload.status,
      };
    case GET_DIVISION_FAILURE:
      return {
        ...state,
        dataDivision: {},
        loadingDivision: false,
        statusDivision: 501,
      };

    case RESET_SUBDIVISION:
      return {
        ...state,
        dataSubDivision: {},
        loadingSubDivision: false,
        statusSubDivision: 0,
      };
    case GET_SUBDIVISION_INIT:
      return {
        ...state,
        loadingSubDivision: true,
      };
    case GET_SUBDIVISION_SUCCESS:
      return {
        ...state,
        dataSubDivision: action.payload.data,
        loadingSubDivision: false,
        statusSubDivision: action.payload.status,
      };
    case GET_SUBDIVISION_FAILURE:
      return {
        ...state,
        dataSubDivision: {},
        loadingSubDivision: false,
        statusSubDivision: 501,
      };

    case RESET_ADD_ADDRESS:
      return {
        ...state,
        dataAddAddress: {},
        loadingAddAddress: false,
        statusAddAddress: 0,
      };
    case ADD_ADDRESS_INIT:
      return {
        ...state,
        loadingAddAddress: true,
      };
    case ADD_ADDRESS_SUCCESS:
      return {
        ...state,
        dataAddAddress: action.payload.data,
        loadingAddAddress: false,
        statusAddAddress: action.payload.status,
      };
    case ADD_ADDRESS_FAILURE:
      return {
        ...state,
        dataAddAddress: {},
        loadingAddAddress: false,
        statusAddAddress: 501,
      };
    case RESET_UPDATE_STATUS_CLIENT:
      return {
        ...state,
        dataUpdateStatusClient: {},
        loadingUpdateStatusClient: false,
        statusUpdateStatusClient: 0,
      };
    case UPDATE_STATUS_CLIENT_INIT:
      return {
        ...state,
        loadingUpdateStatusClient: true,
      };
    case UPDATE_STATUS_CLIENT_SUCCESS:
      return {
        ...state,
        dataUpdateStatusClient: action.payload.data,
        loadingUpdateStatusClient: false,
        statusUpdateStatusClient: action.payload.status,
      };
    case UPDATE_STATUS_CLIENT_FAILURE:
      return {
        ...state,
        dataUpdateStatusClient: {},
        loadingUpdateStatusClient: false,
        statusUpdateStatusClient: 501,
      };

    default:
      return state;
  }
}
