import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import Division from "../filters/filtersDivision";
import SubDivision from "../filters/filtersSubDivision";
import { setIdDivision } from "../../actions/clients";
import { getGlosaAddress } from "../../actions/inventory";

import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import Modal from "./modal";

const FormComponent = (props) => {
  const [form, setForm] = useState({
    nombre: "",
    nombre_error: false,
    user: "",
    user_error: false,
    pass: "",
    pass_error: false,
    nombre_bodega: "",
    nombre_bodega_error: false,
    apellido: "",
    apellido_error: false,
    uid: "",
    uid_error: false,
    phone: "",
    phone_error: false,
    email: "",
    email_error: false,
    language: "es",
    language_error: false,
    division_id: "",
    division_id_error: false,
    subdivision_id: "",
    subdivision_id_error: false,
    calle_numero: "",
    calle_numero_error: false,
    ciudad: "",
    ciudad_error: false,
    lugar: "",
    lugar_error: false,
    codigo_postal: "",
    codigo_postal_error: false,
  });
  const [modal, setModal] = useState(false);

  const stateInventory = useSelector((state) => state.inventory);
  const dispatch = useDispatch();

  useEffect(() => {
    const callsAPI = async () => {
      dispatch(getGlosaAddress({ loading: true }));
    };
    callsAPI();
  }, [dispatch, props.clienteId]);

  /* Set form */
  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
      [`${event.target.name}_error`]: false,
    }));

    if (event.target.name === "division_id") {
      dispatch(setIdDivision(event.target.value));
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();
    let allGood = true;

    if (form.cliente_id === "") {
      setForm((prev) => ({ ...prev, cliente_id_error: "RequiredField" }));
      allGood = false;
    }
    if (form.division_id === "") {
      setForm((prev) => ({ ...prev, division_id_error: "RequiredField" }));
      allGood = false;
    }
    if (form.subdivision_id === "") {
      setForm((prev) => ({ ...prev, subdivision_id_error: "RequiredField" }));
      allGood = false;
    }
    if (form.calle_numero === "") {
      setForm((prev) => ({ ...prev, calle_numero_error: "RequiredField" }));
      allGood = false;
    }
    if (form.ciudad === "") {
      setForm((prev) => ({ ...prev, ciudad_error: "RequiredField" }));
      allGood = false;
    }
    if (form.lugar === "") {
      setForm((prev) => ({ ...prev, lugar_error: "RequiredField" }));
      allGood = false;
    }
    if (form.nombre === "") {
      setForm((prev) => ({ ...prev, nombre_error: "RequiredField" }));
      allGood = false;
    }
    if (form.user === "") {
      setForm((prev) => ({ ...prev, user_error: "RequiredField" }));
      allGood = false;
    }
    if (form.apellido === "") {
      setForm((prev) => ({ ...prev, apellido_error: "RequiredField" }));
      allGood = false;
    }
    if (form.nombre_bodega === "") {
      setForm((prev) => ({ ...prev, nombre_bodega_error: "RequiredField" }));
      allGood = false;
    }
    if (form.email === "") {
      setForm((prev) => ({ ...prev, email_error: "RequiredField" }));
      allGood = false;
    }
    if (form.pass === "") {
      setForm((prev) => ({ ...prev, pass_error: "RequiredField" }));
      allGood = false;
    }
    if (form.phone === "") {
      setForm((prev) => ({ ...prev, phone_error: "RequiredField" }));
      allGood = false;
    }
    if (form.uid === "") {
      setForm((prev) => ({ ...prev, uid_error: "RequiredField" }));
      allGood = false;
    }
    if (allGood) {
      setModal(true);
    }
  };

  return (
    <React.Fragment>
      <Modal
        history={props.history}
        form={form}
        setModal={setModal}
        modal={modal}
      />
      {stateInventory.statusGlosaAddress === 200 && (
        <Form onSubmit={handleSubmit}>
          <section className="module--form">
            <div className="module--form__box title">
              <h1>
                <FormattedMessage id="CreateInstaller" />
              </h1>
            </div>
            <div className="module--form__box">
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="User" />
                  {form.user_error && (
                    <span className="text-error">
                      {" "}
                      * ( <FormattedMessage id={form.user_error} />)
                    </span>
                  )}
                </Form.Label>
                <Form.Control
                  defaultValue={form.user}
                  onChange={handleChange}
                  type="text"
                  name="user"
                />
              </Form.Group>
            </div>
            <div className="module--form__box">
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="Password" />
                  {form.pass_error && (
                    <span className="text-error">
                      {" "}
                      * ( <FormattedMessage id={form.pass_error} />)
                    </span>
                  )}
                </Form.Label>
                <Form.Control
                  defaultValue={form.pass}
                  onChange={handleChange}
                  type="text"
                  name="pass"
                />
              </Form.Group>
            </div>
            <div className="module--form__box">
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="Name" />
                  {form.nombre_error && (
                    <span className="text-error">
                      {" "}
                      * ( <FormattedMessage id={form.nombre_error} />)
                    </span>
                  )}
                </Form.Label>
                <Form.Control
                  defaultValue={form.nombre}
                  onChange={handleChange}
                  type="text"
                  name="nombre"
                />
              </Form.Group>
            </div>
            <div className="module--form__box">
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="LastName" />
                  {form.apellido_error && (
                    <span className="text-error">
                      {" "}
                      * ( <FormattedMessage id={form.apellido_error} />)
                    </span>
                  )}
                </Form.Label>
                <Form.Control
                  defaultValue={form.apellido}
                  onChange={handleChange}
                  type="text"
                  name="apellido"
                />
              </Form.Group>
            </div>
            <div className="module--form__box">
              <Form.Group>
                <Form.Label>
                  UID
                  {form.uid_error && (
                    <span className="text-error">
                      {" "}
                      * ( <FormattedMessage id={form.uid_error} />)
                    </span>
                  )}
                </Form.Label>
                <Form.Control
                  defaultValue={form.uid}
                  onChange={handleChange}
                  type="text"
                  name="uid"
                />
              </Form.Group>
            </div>
            <div className="module--form__box">
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="Email" />
                  {form.email_error && (
                    <span className="text-error">
                      {" "}
                      * ( <FormattedMessage id={form.email_error} />)
                    </span>
                  )}
                </Form.Label>
                <Form.Control
                  defaultValue={form.email}
                  onChange={handleChange}
                  type="text"
                  name="email"
                />
              </Form.Group>
            </div>
            <div className="module--form__box">
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="Phone" />
                  {form.phone_error && (
                    <span className="text-error">
                      {" "}
                      * ( <FormattedMessage id={form.phone_error} />)
                    </span>
                  )}
                </Form.Label>
                <Form.Control
                  defaultValue={form.phone}
                  onChange={handleChange}
                  type="text"
                  name="phone"
                />
              </Form.Group>
            </div>
            <div className="module--form__box">
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="Languages" />
                  {form.language_error && (
                    <span className="text-error">
                      {" "}
                      * ( <FormattedMessage id={form.language_error} />)
                    </span>
                  )}
                </Form.Label>
                <Form.Control
                  as="select"
                  value={form.language}
                  onChange={handleChange}
                  name="language"
                  data-number="ok"
                >
                  <FormattedMessage id="Spanish">
                    {(message) => <option value="es">{message}</option>}
                  </FormattedMessage>
                  <FormattedMessage id="English">
                    {(message) => <option value="en">{message}</option>}
                  </FormattedMessage>
                </Form.Control>
              </Form.Group>
            </div>
          </section>
          <section className="module--form">
            <div className="module--form__box title">
              <h1>
                <FormattedMessage id="Warehouse" />
              </h1>
            </div>
            <div className="module--form__box">
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="Name" />
                  {form.nombre_bodega_error && (
                    <span className="text-error">
                      {" "}
                      * ( <FormattedMessage id={form.nombre_bodega_error} />)
                    </span>
                  )}
                </Form.Label>
                <Form.Control
                  defaultValue={form.nombre_bodega}
                  onChange={handleChange}
                  type="text"
                  name="nombre_bodega"
                />
              </Form.Group>
            </div>
            <React.Fragment>
              <Division
                label={stateInventory.dataGlosaAddress.datos.label_divisiones}
                handleChange={handleChange}
                divisionIdError={form.division_id_error}
                divisionId={form.division_id}
              />
              <SubDivision
                label={
                  stateInventory.dataGlosaAddress.datos.label_subdivisiones
                }
                handleChange={handleChange}
                subDivisionIdError={form.subdivision_id_error}
                subDivisionId={form.subdivision_id}
              />
            </React.Fragment>
            <div className="module--form__box">
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="StreetAndNumber" />
                  {form.calle_numero_error && (
                    <span className="text-error">
                      {" "}
                      * ( <FormattedMessage id={form.calle_numero_error} />)
                    </span>
                  )}
                </Form.Label>
                <Form.Control
                  defaultValue={form.calle_numero}
                  onChange={handleChange}
                  type="text"
                  name="calle_numero"
                />
              </Form.Group>
            </div>

            <div className="module--form__box">
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="City" />
                  {form.ciudad_error && (
                    <span className="text-error">
                      {" "}
                      * ( <FormattedMessage id={form.ciudad_error} />)
                    </span>
                  )}
                </Form.Label>
                <Form.Control
                  defaultValue={form.ciudad}
                  onChange={handleChange}
                  type="text"
                  name="ciudad"
                />
              </Form.Group>
            </div>
            <div className="module--form__box">
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="WareHousePlace" />
                  {form.lugar_error && (
                    <span className="text-error">
                      {" "}
                      * ( <FormattedMessage id={form.lugar_error} />)
                    </span>
                  )}
                </Form.Label>
                <Form.Control
                  defaultValue={form.lugar}
                  onChange={handleChange}
                  type="text"
                  name="lugar"
                />
              </Form.Group>
            </div>
            <div className="module--form__box">
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="PostCode" />
                  {form.codigo_postal_error && (
                    <span className="text-error">
                      * ( <FormattedMessage id={form.codigo_postal_error} />)
                    </span>
                  )}
                </Form.Label>
                <Form.Control
                  defaultValue={form.codigo_postal}
                  onChange={handleChange}
                  type="text"
                  name="codigo_postal"
                />
              </Form.Group>
            </div>
            <div className="module--form__box full">
              <Button disabled={props.loading} variant="main" type="submit">
                <FormattedMessage id="Add" />
              </Button>
            </div>
          </section>
        </Form>
      )}
    </React.Fragment>
  );
};
FormComponent.propTypes = {};

export default FormComponent;
