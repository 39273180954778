const initialState = {
  dataPlanner: {},
  loadingPlanner: true,
  statusPlanner: 0,
  dataPlannerUpdate: {},
  loadingPlannerUpdate: false,
  statusPlannerUpdate: 0,
};

export default initialState;
