import {
  GET_CLIENT_INIT,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_FAILURE,
  RESET_CLIENT,
  GET_DIVISION_INIT,
  GET_DIVISION_SUCCESS,
  GET_DIVISION_FAILURE,
  RESET_DIVISION,
  GET_SUBDIVISION_INIT,
  GET_SUBDIVISION_SUCCESS,
  GET_SUBDIVISION_FAILURE,
  RESET_SUBDIVISION,
  SET_ID_DIVISION,
  ADD_ADDRESS_INIT,
  ADD_ADDRESS_SUCCESS,
  ADD_ADDRESS_FAILURE,
  RESET_ADD_ADDRESS,
  UPDATE_STATUS_CLIENT_INIT,
  UPDATE_STATUS_CLIENT_SUCCESS,
  UPDATE_STATUS_CLIENT_FAILURE,
  RESET_UPDATE_STATUS_CLIENT,
} from "./types";
import API from "./api";

export function setIdDivision(id) {
  return {
    type: SET_ID_DIVISION,
    payload: id,
  };
}

/* Actions Creators */

export function resetClient() {
  return {
    type: RESET_CLIENT,
  };
}

/* Get Client (Async) */

function getClientSuccess(data) {
  return {
    type: GET_CLIENT_SUCCESS,
    payload: data,
  };
}

function getClientFailure(error) {
  console.log(error);
  return {
    type: GET_CLIENT_FAILURE,
    payload: error,
  };
}

function getClientInit(data) {
  return {
    type: GET_CLIENT_INIT,
    payload: data.loading,
  };
}

export function getClient(data) {
  return async (dispatch) => {
    dispatch(getClientInit(data));
    try {
      const resp = await API.data.getClient(data);
      return dispatch(getClientSuccess(resp));
    } catch (error) {
      return dispatch(getClientFailure(error));
    }
  };
}

export function resetDivision() {
  return {
    type: RESET_DIVISION,
  };
}

/* Get Division (Async) */

function getDivisionSuccess(data) {
  return {
    type: GET_DIVISION_SUCCESS,
    payload: data,
  };
}

function getDivisionFailure(error) {
  console.log(error);
  return {
    type: GET_DIVISION_FAILURE,
    payload: error,
  };
}

function getDivisionInit() {
  return {
    type: GET_DIVISION_INIT,
  };
}

export function getDivision(data) {
  return async (dispatch) => {
    dispatch(getDivisionInit());
    try {
      const resp = await API.data.getDivision(data);
      return dispatch(getDivisionSuccess(resp));
    } catch (error) {
      return dispatch(getDivisionFailure(error));
    }
  };
}

export function resetSubDivision() {
  return {
    type: RESET_SUBDIVISION,
  };
}

function getSubDivisionSuccess(data) {
  return {
    type: GET_SUBDIVISION_SUCCESS,
    payload: data,
  };
}

function getSubDivisionFailure(error) {
  console.log(error);
  return {
    type: GET_SUBDIVISION_FAILURE,
    payload: error,
  };
}

function getSubDivisionInit() {
  return {
    type: GET_SUBDIVISION_INIT,
  };
}

export function getSubDivision(data) {
  return async (dispatch) => {
    dispatch(getSubDivisionInit());
    try {
      const resp = await API.data.getSubDivision(data);
      return dispatch(getSubDivisionSuccess(resp));
    } catch (error) {
      return dispatch(getSubDivisionFailure(error));
    }
  };
}

export function resetAddAddress() {
  return {
    type: RESET_ADD_ADDRESS,
  };
}

function addAddressSuccess(data) {
  return {
    type: ADD_ADDRESS_SUCCESS,
    payload: data,
  };
}

function addAddressFailure(error) {
  console.log(error);
  return {
    type: ADD_ADDRESS_FAILURE,
    payload: error,
  };
}

function addAddressInit() {
  return {
    type: ADD_ADDRESS_INIT,
  };
}

export function addAddress(data) {
  return async (dispatch) => {
    dispatch(addAddressInit());
    try {
      const resp = await API.data.addAddress(data);
      return dispatch(addAddressSuccess(resp));
    } catch (error) {
      return dispatch(addAddressFailure(error));
    }
  };
}

export function resetUpdateStatusClient() {
  return {
    type: RESET_UPDATE_STATUS_CLIENT,
  };
}

/* Get UpdateStatusClient (Async) */

function updateStatusClientSuccess(data) {
  return {
    type: UPDATE_STATUS_CLIENT_SUCCESS,
    payload: data,
  };
}

function updateStatusClientFailure(error) {
  console.log(error);
  return {
    type: UPDATE_STATUS_CLIENT_FAILURE,
    payload: error,
  };
}

function updateStatusClientInit() {
  return {
    type: UPDATE_STATUS_CLIENT_INIT,
  };
}

export function updateStatusClient(data) {
  return async (dispatch) => {
    dispatch(updateStatusClientInit());
    try {
      const resp = await API.data.updateStatusClient(data);
      return dispatch(updateStatusClientSuccess(resp));
    } catch (error) {
      return dispatch(updateStatusClientFailure(error));
    }
  };
}
