import {
  ADD_INSTALLER_INIT,
  ADD_INSTALLER_SUCCESS,
  ADD_INSTALLER_FAILURE,
  RESET_ADD_INSTALLER,
} from "./types";
import API from "./api";

/* Actions Creators */

export function resetAddInstaller() {
  return {
    type: RESET_ADD_INSTALLER,
  };
}

/* Get AddInstaller (Async) */

function addInstallerSuccess(data) {
  return {
    type: ADD_INSTALLER_SUCCESS,
    payload: data,
  };
}

function addInstallerFailure(error) {
  console.log(error);
  return {
    type: ADD_INSTALLER_FAILURE,
    payload: error,
  };
}

function addInstallerInit() {
  return {
    type: ADD_INSTALLER_INIT,
  };
}

export function addInstaller(data) {
  return async (dispatch) => {
    dispatch(addInstallerInit());
    try {
      const resp = await API.data.addInstaller(data);
      return dispatch(addInstallerSuccess(resp));
    } catch (error) {
      return dispatch(addInstallerFailure(error));
    }
  };
}
