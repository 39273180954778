import React from "react";

import Form from "../../components/update_task/form";

const UpdateTask = (props) => {
  return (
    <div>
      <Form history={props.history} urlId={props.match.params.id} />
    </div>
  );
};

export default UpdateTask;
