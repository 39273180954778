import initialState from "./initialState";
import {
  ADD_TASK_INIT,
  ADD_TASK_SUCCESS,
  ADD_TASK_FAILURE,
  RESET_ADD_TASK,
} from "../../actions/create_task/types";

export default function task(state = initialState, action) {
  switch (action.type) {
    /* Setting */
    case RESET_ADD_TASK:
      return {
        ...state,
        dataAddTask: {},
        loadingAddTask: false,
        statusAddTask: 0,
      };
    case ADD_TASK_INIT:
      return {
        ...state,
        loadingAddTask: true,
      };
    case ADD_TASK_SUCCESS:
      return {
        ...state,
        dataAddTask: action.payload.data,
        loadingAddTask: false,
        statusAddTask: action.payload.status,
      };
    case ADD_TASK_FAILURE:
      return {
        ...state,
        dataAddTask: {},
        loadingAddTask: false,
        statusAddTask: 501,
      };

    default:
      return state;
  }
}
