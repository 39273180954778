import React, { useState } from "react";

import { Table } from "react-bootstrap";

import { FormattedMessage } from "react-intl";

import Modal from "./modalDelete";
import ModalEdit from "./modalEdit";

const MyTable = (props) => {
  const [modal, setModal] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [data, setData] = useState(false);
  return (
    <React.Fragment>
      <Modal form={props.form} data={data} modal={modal} setModal={setModal} />
      <ModalEdit
        form={props.form}
        data={data}
        modal={modalEdit}
        setModal={setModalEdit}
      />
      {props.data.datos.piezas.length > 0 && (
        <div className="box-table">
          <Table striped hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>
                  <FormattedMessage id="Code" />
                </th>
                <th>
                  <FormattedMessage id="Status" />
                </th>
                <th className="center">
                  <FormattedMessage id="Action" />
                </th>
              </tr>
            </thead>
            <tbody>
              {props.data.datos.piezas.map((item, i) => (
                <tr key={i}>
                  <td>{item.pieza_id}</td>
                  <td>{item.codigo}</td>
                  <td>
                    <FormattedMessage
                      id={item.usado ? "UsedPart" : "UnusedPart"}
                    />
                  </td>
                  <td className="center">
                    <span href="#ver" className="table-button--normal buttons">
                      <i className="fas fa-ellipsis-h"></i>
                      <div className="table-button--normal--option">
                        <ul>
                          <li>
                            <span
                              onClick={() => {
                                setModal(true);
                                setData({
                                  pieza_id: item.pieza_id,
                                  timestamp: item.timestamp,
                                });
                              }}
                            >
                              <FormattedMessage id="Delete" />
                            </span>
                          </li>
                          <li>
                            <span
                              onClick={() => {
                                setModalEdit(true);
                                setData({
                                  pieza_id: item.pieza_id,
                                  timestamp: item.timestamp,
                                });
                              }}
                            >
                              <FormattedMessage id="DisablePart" />
                            </span>
                          </li>
                        </ul>
                      </div>
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </React.Fragment>
  );
};

export default MyTable;
