import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";

import { FormattedMessage } from "react-intl";
import Modal from "./modal";

const FormComponent = (props) => {
  const index = props.item.roles.findIndex((item) => item.nombre === "esAdmin");

  const [form, setForm] = useState({
    id: props.item.id,
    nombre: props.item.names,
    nombre_error: false,
    user: props.item.username,
    user_error: false,
    pass: "",
    pass_error: false,
    apellido: props.item.last_names,
    apellido_error: false,
    uid: props.item.uid,
    uid_error: false,
    phone: props.item.telefono,
    phone_error: false,
    email: props.item.email,
    email_error: false,
    language: props.item.lenguaje,
    language_error: false,
    admin: index >= 0 ? "esAdmin" : "noEsAdmin",
    admin_error: false,
  });
  const [modal, setModal] = useState(false);

  /* Set form */
  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
      [`${event.target.name}_error`]: false,
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();
    let allGood = true;

    if (form.nombre === "") {
      setForm((prev) => ({ ...prev, nombre_error: "RequiredField" }));
      allGood = false;
    }
    if (form.user === "") {
      setForm((prev) => ({ ...prev, user_error: "RequiredField" }));
      allGood = false;
    }
    if (form.apellido === "") {
      setForm((prev) => ({ ...prev, apellido_error: "RequiredField" }));
      allGood = false;
    }
    if (form.email === "") {
      setForm((prev) => ({ ...prev, email_error: "RequiredField" }));
      allGood = false;
    }
    if (form.pass === "") {
      setForm((prev) => ({ ...prev, pass_error: "RequiredField" }));
      allGood = false;
    }
    if (form.phone === "") {
      setForm((prev) => ({ ...prev, phone_error: "RequiredField" }));
      allGood = false;
    }
    if (form.uid === "") {
      setForm((prev) => ({ ...prev, uid_error: "RequiredField" }));
      allGood = false;
    }
    if (form.admin === "") {
      setForm((prev) => ({ ...prev, admin_error: "RequiredField" }));
      allGood = false;
    }
    if (allGood) {
      setModal(true);
    }
  };

  return (
    <React.Fragment>
      <Modal
        history={props.history}
        form={form}
        setModal={setModal}
        modal={modal}
      />
      <Form onSubmit={handleSubmit}>
        <section className="module--form">
          <div className="module--form__box title">
            <h1>
              <FormattedMessage id="CreateSupervisor" />
            </h1>
          </div>
          <div className="module--form__box">
            <Form.Group>
              <Form.Label>
                <FormattedMessage id="User" />
                {form.user_error && (
                  <span className="text-error">
                    {" "}
                    * ( <FormattedMessage id={form.user_error} />)
                  </span>
                )}
              </Form.Label>
              <Form.Control
                defaultValue={form.user}
                onChange={handleChange}
                type="text"
                name="user"
              />
            </Form.Group>
          </div>
          <div className="module--form__box">
            <Form.Group>
              <Form.Label>
                <FormattedMessage id="Password" />
                {form.pass_error && (
                  <span className="text-error">
                    {" "}
                    * ( <FormattedMessage id={form.pass_error} />)
                  </span>
                )}
              </Form.Label>
              <Form.Control
                defaultValue={form.pass}
                onChange={handleChange}
                type="text"
                name="pass"
              />
            </Form.Group>
          </div>
          <div className="module--form__box">
            <Form.Group>
              <Form.Label>
                <FormattedMessage id="Name" />
                {form.nombre_error && (
                  <span className="text-error">
                    {" "}
                    * ( <FormattedMessage id={form.nombre_error} />)
                  </span>
                )}
              </Form.Label>
              <Form.Control
                defaultValue={form.nombre}
                onChange={handleChange}
                type="text"
                name="nombre"
              />
            </Form.Group>
          </div>
          <div className="module--form__box">
            <Form.Group>
              <Form.Label>
                <FormattedMessage id="LastName" />
                {form.apellido_error && (
                  <span className="text-error">
                    {" "}
                    * ( <FormattedMessage id={form.apellido_error} />)
                  </span>
                )}
              </Form.Label>
              <Form.Control
                defaultValue={form.apellido}
                onChange={handleChange}
                type="text"
                name="apellido"
              />
            </Form.Group>
          </div>
          <div className="module--form__box">
            <Form.Group>
              <Form.Label>
                UID
                {form.uid_error && (
                  <span className="text-error">
                    {" "}
                    * ( <FormattedMessage id={form.uid_error} />)
                  </span>
                )}
              </Form.Label>
              <Form.Control
                defaultValue={form.uid}
                onChange={handleChange}
                type="text"
                name="uid"
              />
            </Form.Group>
          </div>
          <div className="module--form__box">
            <Form.Group>
              <Form.Label>
                <FormattedMessage id="Email" />
                {form.email_error && (
                  <span className="text-error">
                    {" "}
                    * ( <FormattedMessage id={form.email_error} />)
                  </span>
                )}
              </Form.Label>
              <Form.Control
                defaultValue={form.email}
                onChange={handleChange}
                type="text"
                name="email"
              />
            </Form.Group>
          </div>
          <div className="module--form__box">
            <Form.Group>
              <Form.Label>
                <FormattedMessage id="Phone" />
                {form.phone_error && (
                  <span className="text-error">
                    {" "}
                    * ( <FormattedMessage id={form.phone_error} />)
                  </span>
                )}
              </Form.Label>
              <Form.Control
                defaultValue={form.phone}
                onChange={handleChange}
                type="text"
                name="phone"
              />
            </Form.Group>
          </div>
          <div className="module--form__box">
            <Form.Group>
              <Form.Label>
                <FormattedMessage id="Languages" />
                {form.language_error && (
                  <span className="text-error">
                    {" "}
                    * ( <FormattedMessage id={form.language_error} />)
                  </span>
                )}
              </Form.Label>
              <Form.Control
                as="select"
                value={form.language}
                onChange={handleChange}
                name="language"
                data-number="ok"
              >
                <FormattedMessage id="Spanish">
                  {(message) => <option value="es">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="English">
                  {(message) => <option value="en">{message}</option>}
                </FormattedMessage>
              </Form.Control>
            </Form.Group>
          </div>
          <div className="module--form__box">
            <Form.Group>
              <Form.Label>
                <FormattedMessage id="Admin" />
                {form.admin_error && (
                  <span className="text-error">
                    {" "}
                    * ( <FormattedMessage id={form.admin_error} />)
                  </span>
                )}
              </Form.Label>
              <Form.Control
                as="select"
                value={form.admin}
                onChange={handleChange}
                name="admin"
                data-number="ok"
              >
                <option value=""></option>
                <FormattedMessage id="Yes">
                  {(message) => <option value="esAdmin">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="No">
                  {(message) => <option value="noEsAdmin">{message}</option>}
                </FormattedMessage>
              </Form.Control>
            </Form.Group>
          </div>
          <div className="module--form__box full">
            <Button disabled={props.loading} variant="main" type="submit">
              <FormattedMessage id="Update" />
            </Button>
          </div>
        </section>
      </Form>
    </React.Fragment>
  );
};
FormComponent.propTypes = {};

export default FormComponent;
