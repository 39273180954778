import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
/* Actions */
import { getControl, resetControl } from "../../actions/movements";
import { resetGlosaAddress, resetInventory } from "../../actions/inventory";

import { FormattedMessage } from "react-intl";

/* Components */
import Modal from "../../components/common/modal";
import Table from "../../components/control/table";
import TableData from "../../components/control/tableData";
import Header from "../../components/control/header";

const Control = (props) => {
  const [form, setForm] = useState({
    bodega_id: "",
    bodega_id_error: false,
    producto_id: "",
    producto_id_error: false,
    estado: "1",
  });

  const state = useSelector((state) => state.movements);
  const dispatch = useDispatch();

  useEffect(() => {
    const callsAPI = async () => {
      if (form.bodega_id !== "" && form.producto_id !== "") {
        dispatch(
          getControl({
            loading: true,
            bodega_id: parseInt(form.bodega_id, 10),
            producto_id: parseInt(form.producto_id, 10),
            ver_usadas: form.estado === "1" ? true : false,
          })
        );
      }
    };
    callsAPI();
  }, [dispatch, form]);

  useEffect(() => {
    return () => {
      dispatch(resetGlosaAddress());
      dispatch(resetControl());
      dispatch(resetInventory());
    };
  }, [dispatch]);

  const handleChange = (event) => {
    event.persist();

    setForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
      [`${event.target.name}_error`]: false,
    }));
  };

  if (state.statusControl === 401 || state.statusControl === 501) {
    return <Modal history={props.history} state={true} />;
  }
  if (state.loadingControl) {
    return (
      <div className="module--loading">
        <i className="fas fa-cog fa-spin"></i>
      </div>
    );
  }
  if (state.statusControl === 0) {
    return (
      <React.Fragment>
        <div className="module--table">
          <Header handleChange={handleChange} form={form} />
        </div>
        <div className="module-message">
          <h1>
            <FormattedMessage id="YouhaveToSelectTheWarehouseAndProduct" />
          </h1>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="module--table">
        <Header handleChange={handleChange} form={form} />
        <TableData form={form} data={state.dataControl} />
      </div>
      <div className="module--table top">
        <Table form={form} data={state.dataControl} />
      </div>
    </React.Fragment>
  );
};

export default Control;
