import {
  ADD_TASK_INIT,
  ADD_TASK_SUCCESS,
  ADD_TASK_FAILURE,
  RESET_ADD_TASK,
} from "./types";
import API from "./api";

/* Actions Creators */

export function resetAddTask() {
  return {
    type: RESET_ADD_TASK,
  };
}

/* Get AddTask (Async) */

function addTaskSuccess(data) {
  return {
    type: ADD_TASK_SUCCESS,
    payload: data,
  };
}

function addTaskFailure(error) {
  console.log(error);
  return {
    type: ADD_TASK_FAILURE,
    payload: error,
  };
}

function addTaskInit() {
  return {
    type: ADD_TASK_INIT,
  };
}

export function addTask(data) {
  return async (dispatch) => {
    dispatch(addTaskInit());
    try {
      const resp = await API.data.addTask(data);
      return dispatch(addTaskSuccess(resp));
    } catch (error) {
      return dispatch(addTaskFailure(error));
    }
  };
}
