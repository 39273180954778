import React from "react";
import Config from "../../actions/config";

const Paginator = (props) => {
  let i = props.paginator.blockStart;

  let total = props.total / Config.nxpage;
  total = total + 1;

  const buttons = [];
  while (i <= props.paginator.blockEnd && i <= props.total) {
    if (i < total)
      buttons.push(
        <div
          className={props.paginator.main === i ? "active" : ""}
          data-num={i}
          onClick={props.handlePaginator}
          data-main={i}
          key={i}
        >
          {i}
        </div>
      );
    i++;
  }

  return (
    <div className="paginator top">
      {props.paginator.main > 1 && (
        <div
          data-num={parseInt(props.paginator.main, 10) - 1}
          onClick={props.handlePaginator}
        >
          <i className="fas fa-chevron-left" />
        </div>
      )}
      {buttons}
      {props.paginator.main < total - 1 && (
        <div
          data-num={parseInt(props.paginator.main, 10) + 1}
          onClick={props.handlePaginator}
        >
          <i className="fas fa-chevron-right" />
        </div>
      )}
    </div>
  );
};

Paginator.propTypes = {};

export default Paginator;
