import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Client from "../filters/filtersClient";
import moment from "moment";
import DatePicker from "react-datepicker";
import Sitio from "../filters/filtersSitio";
import Container from "../filters/filtersContainer";
import Instalador from "../filters/filtersInstalador";
import Product from "../filters/filtersProduct";

import { useSelector, useDispatch } from "react-redux";
import { addTask, resetAddTask } from "../../actions/create_task";
import { FormattedMessage } from "react-intl";
import { Modal, Breadcrumb } from "react-bootstrap";

/* Task */
import { setIdClient, setIdProduct } from "../../actions/filters";

/* Components */
import ModalAddress from "./modalAddress";

import "react-datepicker/dist/react-datepicker.css";

const FormComponent = (props) => {
  const [form, setForm] = useState({
    guia: "",
    guia_error: false,
    cliente_id: "",
    cliente_id_error: false,
    sitio_id: "",
    sitio_id_error: false,
    container_id: "",
    container_id_error: false,
    instalador_id: "",
    instalador_id_error: false,
    cantidad: "",
    cantidad_error: false,
    descripcion: "",
    descripcion_error: false,
    startDate_error: false,
    endDate_error: false,
  });

  const [myHours, setMyHours] = useState({
    startHour: new Date("2021-09-25 08:00"),
    endHour: new Date("2021-09-25 23:00"),
    startHourSimple: "08:00",
    endHourSimple: "23:00",
  });

  const [products, setProducts] = useState([
    {
      unique: "id" + new Date().getTime(),
      product_id: "",
      cantidad: 1,
      name: "",
      error: false,
    },
  ]);
  const [modal, setModal] = useState(false); // State Control Modal
  const [count, setCount] = useState(0); // State Count for updating my product array

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [modalAddress, setModalAddress] = useState(false);

  const state = useSelector((state) => state.createTask);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setIdProduct(""));
      dispatch(setIdClient(""));
      dispatch(resetAddTask());
    };
  }, [dispatch]);

  /* Set form */
  const handleChange = (event) => {
    event.persist();

    setForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
      [`${event.target.name}_error`]: false,
    }));

    if (event.target.name === "cliente_id") {
      dispatch(setIdClient(event.target.value));
    }
    if (event.target.name === "container_id") {
      dispatch(setIdProduct(event.target.value));
    }
    if (event.target.name === "sitio_id") {
      let hoursStart =
        event.target.options[event.target.selectedIndex].dataset.start;
      let hoursEnd =
        event.target.options[event.target.selectedIndex].dataset.end;

      setMyHours({
        startHour: new Date(`2021-09-25 ${hoursStart}`),
        endHour: new Date(`2021-09-25  ${hoursEnd}`),
        startHourSimple: hoursStart,
        endHourSimple: hoursEnd,
      });
    }
  };

  /* Open modal add task and validat the fields*/
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();
    let isOk = false;

    let t1 = myHours.startHourSimple;
    let r1 = Number(t1.split(":")[0]) * 60 + Number(t1.split(":")[1]);

    let t2 = myHours.endHourSimple;
    let r2 = Number(t2.split(":")[0]) * 60 + Number(t2.split(":")[1]);

    /* Validation  if the input is empty*/
    if (startDate === null) {
      setForm((prev) => ({ ...prev, startDate_error: "RequiredField" }));
      isOk = true;
    } else {
      let t = moment(startDate).format("HH:mm");
      let r = Number(t.split(":")[0]) * 60 + Number(t.split(":")[1]);
      if (r < r1 || r > r2) {
        setForm((prev) => ({ ...prev, startDate_error: "OutOfTime" }));
        isOk = true;
      }
    }
    if (endDate === null) {
      setForm((prev) => ({ ...prev, endDate_error: "RequiredField" }));
      isOk = true;
    } else {
      let t0 = moment(endDate).format("HH:mm");
      let r0 = Number(t0.split(":")[0]) * 60 + Number(t0.split(":")[1]);

      if (r0 < r1 || r0 > r2) {
        setForm((prev) => ({ ...prev, endDate_error: "OutOfTime" }));
        isOk = true;
      }
    }

    if (form.guia === "") {
      setForm((prev) => ({ ...prev, guia_error: "RequiredField" }));
      isOk = true;
    }
    if (form.cliente_id === "") {
      setForm((prev) => ({ ...prev, cliente_id_error: "RequiredField" }));
      isOk = true;
    }
    if (form.container_id === "") {
      setForm((prev) => ({ ...prev, container_id_error: "RequiredField" }));
      isOk = true;
    }
    if (form.sitio_id === "") {
      setForm((prev) => ({ ...prev, sitio_id_error: "RequiredField" }));
      isOk = true;
    }
    if (form.descripcion === "") {
      setForm((prev) => ({ ...prev, descripcion_error: "RequiredField" }));
      isOk = true;
    }

    /* Validation arrays of the products  if the input is empty*/
    products.forEach((item, i) => {
      if (item.product_id === "") {
        products[i].error = "RequiredField";
        isOk = true;
      }
    });
    setProducts(products);
    setCount(count + 1);

    if (!isOk) {
      setModal(true);
    }
  };

  /* Send post add Task */
  const sendPostAddTask = async () => {
    dispatch(
      addTask({
        form: form,
        products: products,
        startDate: moment(startDate).format("DD-MM-YYYY HH:mm"),
        endDate: moment(endDate).format("DD-MM-YYYY HH:mm"),
      })
    );
  };

  const closeModal = () => {
    if (props.planner) {
      props.closeModalUpdateError();
    }
    setModal(false);
    dispatch(resetAddTask());
    if (state.statusAddTask === 200 && !props.planner) {
      if (state.dataAddTask.ejecucion.estado) {
        props.history.push("/dashboard/instalation_pending");
      }
    }
  };

  /* Add product */
  const handleAddProduct = (event) => {
    setProducts((state) => [
      ...state,
      {
        unique: "id" + new Date().getTime(),
        product_id: "",
        cantidad: 1,
        name: "",
        error: false,
      },
    ]);
  };

  /* Delete Product */
  const handleRemoveProduct = (index) => {
    let newProducts = products;
    newProducts.splice(index, 1);
    setProducts(newProducts);
    setCount(count + 1);
  };

  /* Set  Product */
  const handleChangeProduct = (event, index) => {
    event.persist();
    let newProducts = products;
    newProducts[index][event.target.name] = event.target.value;

    /* Condition if the product is unique */
    if (event.target.name === "product_id") {
      const idProduct =
        event.target.options[event.target.options.selectedIndex].dataset
          .idproduct;

      const catogoryProduct =
        event.target.options[event.target.options.selectedIndex].dataset
          .nameproduct;

      if (
        idProduct === "1" ||
        idProduct === "2" ||
        idProduct === "3" ||
        idProduct === "4"
      ) {
        const inputCantidad = document.getElementById(
          newProducts[index].unique
        );
        inputCantidad.disabled = true;
        newProducts[index].cantidad = 1;
        newProducts[index].error = false;
        newProducts[index].name = catogoryProduct;
      } else {
        const inputCantidad = document.getElementById(
          newProducts[index].unique
        );
        inputCantidad.disabled = false;
        newProducts[index].cantidad = 1;
        newProducts[index].error = false;
        newProducts[index].name = catogoryProduct;
      }
    }
    setProducts(newProducts);
    setCount(count + 1);
  };

  /* Clear error date */
  const errorDate = (error) => {
    setForm((prev) => ({
      ...prev,
      [error]: false,
    }));
  };

  /* Modal address */
  const handleModalAddress = (error) => {
    setModalAddress(true);
  };

  const setClientId = (value) => {
    setForm((prev) => ({
      ...prev,
      cliente_id: value,
    }));
  };

  const setSitioId = (value) => {
    setForm((prev) => ({
      ...prev,
      sitio_id: value,
    }));
  };

  const handleHours = (obj) => {
    setMyHours({
      startHour: new Date(`2021-09-25 ${obj.hoursStart}`),
      endHour: new Date(`2021-09-25  ${obj.hoursEnd}`),
      startHourSimple: obj.hoursStart,
      endHourSimple: obj.hoursEnd,
    });
  };

  return (
    <React.Fragment>
      <ModalAddress
        setClientId={setClientId}
        setSitioId={setSitioId}
        handleHours={handleHours}
        clienteId={form.cliente_id}
        setModal={setModalAddress}
        modal={modalAddress}
      />
      {!props.planner && (
        <Breadcrumb>
          <li className="breadcrumb-item active">
            <Link to="/dashboard/instalation_pending">
              <i className="fas fa-arrow-left"></i>{" "}
              <FormattedMessage id="PendingTasks" />
            </Link>
          </li>
          <Breadcrumb.Item active>
            <FormattedMessage id="AddTask" />
          </Breadcrumb.Item>
        </Breadcrumb>
      )}
      <Form onSubmit={handleSubmit}>
        <section
          className={props.planner ? `module--form planner` : `module--form`}
        >
          <div className="module--form__box title">
            <h1>
              <FormattedMessage id="CreateTask" />
            </h1>
          </div>
          <Client
            handleChange={handleChange}
            clienteIdError={form.cliente_id_error}
            clienteId={form.cliente_id}
          />
          <div className="module--form__box">
            <Form.Group>
              <Form.Label>
                <FormattedMessage id="GuideOrInvoice" />
                {form.guia_error && (
                  <span className="text-error">
                    {" "}
                    * ( <FormattedMessage id={form.guia_error} />)
                  </span>
                )}
              </Form.Label>
              <Form.Control
                defaultValue={form.guia}
                onChange={handleChange}
                type="text"
                name="guia"
              />
            </Form.Group>
          </div>

          <Container
            containerIdError={form.container_id_error}
            handleChange={handleChange}
            containerId={form.container_id}
          />
          <Sitio
            handleModalAddress={handleModalAddress}
            sitioIdError={form.sitio_id_error}
            handleChange={handleChange}
            sitioId={form.sitio_id}
          />

          <div className="module--form__box">
            <Form.Group controlId="code">
              <Form.Label>
                <FormattedMessage id="DateStart" />
                {form.startDate_error && (
                  <span className="text-error">
                    {" "}
                    * ( <FormattedMessage id={form.startDate_error} />)
                  </span>
                )}
              </Form.Label>
              <DatePicker
                className="dateInput"
                dateFormat="dd-MM-yyyy h:mm aa"
                showTimeSelect={!props.planner}
                showTimeInput={props.planner}
                selected={startDate}
                minTime={myHours.startHour}
                maxTime={myHours.endHour}
                onChange={(date) => {
                  setStartDate(date);
                  errorDate("startDate_error");
                }}
              />
            </Form.Group>
          </div>
          <div className="module--form__box">
            <Form.Group controlId="code">
              <Form.Label>
                <FormattedMessage id="DateEnd" />
                {form.endDate_error && (
                  <span className="text-error">
                    {" "}
                    * ( <FormattedMessage id={form.endDate_error} />)
                  </span>
                )}
              </Form.Label>
              <DatePicker
                className="dateInput"
                dateFormat="dd-MM-yyyy h:mm aa"
                showTimeSelect={!props.planner}
                showTimeInput={props.planner}
                minTime={myHours.startHour}
                maxTime={myHours.endHour}
                selected={endDate}
                onChange={(date) => {
                  setEndDate(date);
                  errorDate("endDate_error");
                }}
              />
            </Form.Group>
          </div>
          <Instalador
            instaladorIdError={form.instalador_id_error}
            handleChange={handleChange}
            instaladorId={form.instalador_id}
          />
          <div className="module--form__box">
            <Form.Group>
              <Form.Label>
                <FormattedMessage id="InstallationDescription" />
                {form.descripcion_error && (
                  <span className="text-error">
                    {" "}
                    * ( <FormattedMessage id={form.descripcion_error} />)
                  </span>
                )}
              </Form.Label>
              <Form.Control
                defaultValue={form.descripcion}
                onChange={handleChange}
                type="text"
                name="descripcion"
              />
            </Form.Group>
          </div>
        </section>

        <section
          className={props.planner ? `module--form planner` : `module--form`}
        >
          <div className="module--form__box title">
            <h1>
              <FormattedMessage id="Products" />
            </h1>
            <div>
              <Button variant="info" type="button" onClick={handleAddProduct}>
                +
              </Button>
            </div>
          </div>

          {products.map((item, i) => {
            return (
              <React.Fragment key={item.unique}>
                <Product
                  num={i}
                  products={products}
                  handleRemoveProduct={handleRemoveProduct}
                  handleChange={handleChangeProduct}
                  productId={item.product_id}
                  productError={item.error}
                  name="producto_id"
                />
                <div className="module--form__box">
                  <Form.Group>
                    <Form.Label>
                      <FormattedMessage id="Quantity" />
                    </Form.Label>
                    <Form.Control
                      value={item.cantidad}
                      onChange={(event) => handleChangeProduct(event, i)}
                      type="number"
                      name="cantidad"
                      id={item.unique}
                    />
                  </Form.Group>
                </div>
              </React.Fragment>
            );
          })}
          <div className="module--form__box full">
            <Button disabled={props.loading} variant="main" type="submit">
              <FormattedMessage id="Add" />
            </Button>
          </div>
        </section>
      </Form>

      {/* Question modal confirm  */}
      <Modal
        show={modal}
        onHide={() => closeModal()}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            <FormattedMessage id="AddTask" />
          </Modal.Title>
        </Modal.Header>
        {state.statusAddTask === 0 && (
          <Modal.Body>
            <p className="modal_down">
              <FormattedMessage id="MassageModalTask" />
            </p>
            <h4 className="modal_icon">
              <i className="fas fa-info-circle"></i>
            </h4>
          </Modal.Body>
        )}
        {state.statusAddTask === 200 && state.dataAddTask.ejecucion.estado && (
          <Modal.Body>
            <p className="modal_down">
              <FormattedMessage id="WasSuccessfullyAdded" />
            </p>
            <h4 className="modal_icon">
              <i className="fas fa-smile"></i>
            </h4>
          </Modal.Body>
        )}
        {state.statusAddTask === 200 && !state.dataAddTask.ejecucion.estado && (
          <Modal.Body>
            <p className="modal_down">
              <FormattedMessage id="WasNotAdded" />
            </p>
            <h4 className="modal_icon">
              <i className="fas fa-sad-tear"></i>
            </h4>
          </Modal.Body>
        )}
        {state.statusAddTask === 0 && (
          <Modal.Footer>
            <Button
              disabled={state.loadingAddTask}
              variant="info"
              type="submit"
              onClick={() => closeModal()}
            >
              <FormattedMessage id="Cancel" />
            </Button>
            <Button
              disabled={state.loadingAddTask}
              variant="main"
              type="submit"
              onClick={() => sendPostAddTask()}
            >
              {state.loadingAddTask ? (
                <FormattedMessage id="Loading" />
              ) : (
                <FormattedMessage id="Add" />
              )}
            </Button>
          </Modal.Footer>
        )}
        {state.statusAddTask === 200 && (
          <Modal.Footer>
            <Button
              disabled={state.loadingAddTask}
              variant="info"
              type="submit"
              onClick={() => closeModal()}
            >
              <FormattedMessage id="Ok" />
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </React.Fragment>
  );
};
FormComponent.propTypes = {};

export default FormComponent;
