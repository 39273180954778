import React from "react";

import Breadcrumbs from "../../components/create_client/breadcrumbs";
import Form from "../../components/create_client/form";

const CreateClient = (props) => {
  return (
    <div>
      <Breadcrumbs />
      <Form history={props.history} />
    </div>
  );
};

export default CreateClient;
