import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

function ModalUpdateError(props) {
  return (
    <>
      <Modal
        onHide={() => props.closeModalUpdateError()}
        show={props.modal}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id={"WasNotUpdated"} />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="modal_down">
            {!props.mensaje && <FormattedMessage id="WasNotUpdated" />}
            {props.mensaje && props.mensaje.ejecucion.mensaje}
          </p>
          <h4 className="modal_icon">
            <i className="fas fa-sad-tear"></i>
          </h4>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="info"
            type="submit"
            onClick={() => props.closeModalUpdateError()}
          >
            <FormattedMessage id="Ok" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalUpdateError;
