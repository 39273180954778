import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getMovements, resetMovements } from "../../actions/movements";
import moment from "moment";

/* Components */
import Modal from "../../components/common/modal";
import Table from "../../components/movements/table";
import Header from "../../components/movements/header";

const Movements = (props) => {
  const dateToday = new Date();
  const [form, setForm] = useState({
    bodega_id: "",
    bodega_id_error: false,
    producto_id: "",
    producto_id_error: false,
  });
  const [startDate, setStartDate] = useState(
    new Date(dateToday.getTime() - 30 * 24 * 60 * 60 * 1000)
  );
  const [endDate, setEndDate] = useState(dateToday);

  const state = useSelector((state) => state.movements);
  const dispatch = useDispatch();

  useEffect(() => {
    const callsAPI = async () => {
      dispatch(
        getMovements({
          loading: true,
          version: 2,
          dateStart: `${moment(
            new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000)
          ).format("DD-MM-YYYY")} 00:00`,
          dateEnd: `${moment(new Date()).format("DD-MM-YYYY")} 00:00`,
          bodega_id: null,
          producto_id: null,
        })
      );
    };
    callsAPI();
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetMovements());
    };
  }, [dispatch]);

  const handleChange = (event) => {
    event.persist();
    console.log(event.target.name);
    setForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
      [`${event.target.name}_error`]: false,
    }));
    let myStartDate = moment(startDate).format("DD-MM-YYYY");
    let myEndDate = moment(endDate).format("DD-MM-YYYY");
    if (event.target.name === "bodega_id") {
      dispatch(
        getMovements({
          dateStart: startDate && endDate ? `${myStartDate} 00:00` : null,
          dateEnd: startDate && endDate ? `${myEndDate} 00:00` : null,
          bodega_id: parseInt(event.target.value, 10),
          producto_id: parseInt(form.producto_id, 10),
          loading: true,
        })
      );
    } else {
      dispatch(
        getMovements({
          dateStart: startDate && endDate ? `${myStartDate} 00:00` : null,
          dateEnd: startDate && endDate ? `${myEndDate} 00:00` : null,
          producto_id: parseInt(event.target.value, 10),
          bodega_id: parseInt(form.bodega_id, 10),
          loading: true,
        })
      );
    }
  };

  const handleDateStart = async (date) => {
    setStartDate(date);
    let myStartDate = moment(date).format("DD-MM-YYYY");
    let myEndDate = moment(endDate).format("DD-MM-YYYY");
    if (date === null) {
      myStartDate = "null";
      myEndDate = "null";
    }
    if (endDate !== null) {
      dispatch(
        getMovements({
          dateStart: `${myStartDate} 00:00`,
          dateEnd: `${myEndDate} 00:00`,
          loading: true,
          bodega_id: form.bodega_id === "" ? null : form.bodega_id,
          producto_id: form.producto_id === "" ? null : form.producto_id,
        })
      );
    }
  };

  const handleDateEnd = (date) => {
    setEndDate(date);

    let myStartDate = moment(startDate).format("DD-MM-YYYY");
    let myEndDate = moment(date).format("DD-MM-YYYY");
    if (date === null) {
      myStartDate = "null";
      myEndDate = "null";
    }

    if (startDate !== null) {
      dispatch(
        getMovements({
          dateStart: `${myStartDate} 00:00`,
          dateEnd: `${myEndDate} 00:00`,
          loading: true,
          bodega_id: form.bodega_id === "" ? null : form.bodega_id,
          producto_id: form.producto_id === "" ? null : form.producto_id,
        })
      );
    }
  };

  if (state.statusMovements === 401 || state.statusMovements === 501) {
    return <Modal history={props.history} state={true} />;
  }
  if (state.loadingMovements) {
    return (
      <div className="module--loading">
        <i className="fas fa-cog fa-spin"></i>
      </div>
    );
  }

  return (
    <div className="module--table">
      <Header
        handleChange={handleChange}
        form={form}
        startDate={startDate}
        handleDateStart={handleDateStart}
        endDate={endDate}
        handleDateEnd={handleDateEnd}
      />
      <Table data={state.dataMovements} />
    </div>
  );
};

export default Movements;
