const initialState = {
  dataSupervisor: {},
  loadingSupervisor: true,
  statusSupervisor: 0,
  dataUpdateStatusSupervisor: {},
  loadingUpdateStatusSupervisor: false,
  statusUpdateStatusSupervisor: 0,
};

export default initialState;
