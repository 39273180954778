import initialState from "./initialState";
import {
  UPDATE_CLIENT_INIT,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILURE,
  RESET_UPDATE_CLIENT,
} from "../../actions/update_client/types";

export default function client(state = initialState, action) {
  switch (action.type) {
    case RESET_UPDATE_CLIENT:
      return {
        ...state,
        dataUpdateClient: {},
        loadingUpdateClient: false,
        statusUpdateClient: 0,
      };
    case UPDATE_CLIENT_INIT:
      return {
        ...state,
        loadingUpdateClient: true,
      };
    case UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        dataUpdateClient: action.payload.data,
        loadingUpdateClient: false,
        statusUpdateClient: action.payload.status,
      };
    case UPDATE_CLIENT_FAILURE:
      return {
        ...state,
        dataUpdateClient: {},
        loadingUpdateClient: false,
        statusUpdateClient: 501,
      };

    default:
      return state;
  }
}
