import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import moment from "moment";

import {
  deleteTask,
  resetDeleteTask,
  getTask,
} from "../../actions/instalation_pending";

function ModalDelete(props) {
  const state = useSelector((state) => state.instalationPending);
  const dispatch = useDispatch();

  const postChange = (event) => {
    event.preventDefault();
    event.persist();
    dispatch(deleteTask({ id: props.data.id }));
  };

  const closeModal = () => {
    props.setModal(false);
    dispatch(
      getTask({
        start: props.paginator.start,
        end: props.paginator.end,
        status: "pending",
        loading: false,
        dateStart: props.startDate
          ? moment(props.startDate).format("DD-MM-YYYY")
          : "null",
        dateEnd: props.endDate
          ? moment(props.endDate).format("DD-MM-YYYY")
          : "null",
        patron: props.search,
        sort: props.sort,
      })
    );
    dispatch(resetDeleteTask());
  };

  return (
    <>
      <Modal
        onHide={closeModal}
        show={props.modal}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id={"ok"}>
            <FormattedMessage id={"DeleteTask"} />
          </Modal.Title>
        </Modal.Header>
        {state.statusDeleteTask === 0 && (
          <Modal.Body>
            <p className="modal_down">
              <FormattedMessage id="AreYouSure" />
            </p>
          </Modal.Body>
        )}
        {state.statusDeleteTask === 200 &&
          state.dataDeleteTask.ejecucion.estado && (
            <Modal.Body>
              <p className="modal_down">
                <FormattedMessage id="WasSuccessfullyDeleted" />
              </p>
              <h4 className="modal_icon">
                <i className="fas fa-smile"></i>
              </h4>
            </Modal.Body>
          )}
        {state.statusDeleteTask === 200 &&
          !state.dataDeleteTask.ejecucion.estado && (
            <Modal.Body>
              <p className="modal_down">
                <FormattedMessage id="WasNotDeleted" />
              </p>
              <h4 className="modal_icon">
                <i className="fas fa-sad-tear"></i>
              </h4>
            </Modal.Body>
          )}
        {state.statusDeleteTask === 0 && (
          <Modal.Footer>
            <Button
              disabled={state.loadingDeleteTask}
              variant="main"
              type="submit"
              onClick={postChange}
            >
              {state.loadingDeleteTask ? (
                <FormattedMessage id="Loading" />
              ) : (
                <FormattedMessage id="Send" />
              )}
            </Button>
          </Modal.Footer>
        )}
        {state.statusDeleteTask === 200 && (
          <Modal.Footer>
            <Button
              disabled={state.loadingDeleteTask}
              variant="info"
              type="submit"
              onClick={() => closeModal()}
            >
              <FormattedMessage id="Ok" />
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}

export default ModalDelete;
