import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import {
  addStock,
  resetAddStock,
  getWareHouse,
} from "../../actions/ware_house";

function ModalAddProduct(props) {
  const [form, setForm] = useState({
    cantidad: "",
    cantidad_error: false,
    descripcion: "",
    descripcion_error: false,
  });
  const [codigo, setCodigo] = useState([]);
  const [codigoError, setCodigoError] = useState(false);
  const [count, setCount] = useState(0);

  const state = useSelector((state) => state.wareHouse);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
      [`${event.target.name}_error`]: false,
    }));

    if (
      event.target.name === "cantidad" &&
      props.data.producto.tipo_producto.id === 1
    ) {
      const arr = [];
      for (let i = 0; i < event.target.value; i++) {
        const index = codigo.findIndex((item) => item.id === i);
        if (index >= 0) {
          arr.push({ code: codigo[index].code, id: codigo[index].id });
        } else {
          arr.push({ code: "", id: i });
        }
      }
      setCodigo(arr);
    }
  };

  const handleChangeProduct = (obj) => {
    const arr = codigo;
    const index = codigo.findIndex((item) => item.id === obj.id);

    if (index >= 0) {
      codigo[index].code = obj.value;
    }

    setCodigo(arr);
    setCount(count + 1);
  };

  const closeModal = () => {
    props.setModal(false);
    setForm({
      cantidad: "",
      cantidad_error: false,
      descripcion: "",
      descripcion_error: false,
    });
    setCodigo([]);
    dispatch(getWareHouse({ bodega_id: props.id, loading: false }));
    dispatch(resetAddStock());
  };

  const postChange = (event) => {
    event.preventDefault();
    event.persist();
    const arr = [];
    let allGood = true;
    if (form.cantidad === "") {
      setForm((prev) => ({ ...prev, cantidad_error: "RequiredField" }));
      allGood = false;
    }
    if (form.descripcion === "") {
      setForm((prev) => ({ ...prev, descripcion_error: "RequiredField" }));
      allGood = false;
    }
    codigo.forEach((item) => {
      arr.push(item.code);
      if (item.code === "") {
        setCodigoError("CodeIsRequired");
        allGood = false;
      }
    });

    const obj = {
      bodega_id: props.data.bodega_id,
      descripcion: form.descripcion,
      ingresos: [
        {
          producto_id: props.data.producto.id,
          cantidad: parseInt(form.cantidad, 10),
          codigos: arr,
        },
      ],
    };

    if (allGood) {
      dispatch(addStock(obj));
    }
  };

  return (
    <>
      <Modal
        onHide={() => closeModal()}
        show={props.modal}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="AddProduct" />
          </Modal.Title>
        </Modal.Header>

        {state.statusAddStock === 0 && (
          <Modal.Body>
            {props.data && (
              <p className="modal_down">{props.data.producto.nombre}</p>
            )}
            <div className="module--form__box">
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="Description" />
                  {form.descripcion_error && (
                    <span className="text-error">
                      {" "}
                      * ( <FormattedMessage id={form.descripcion_error} />)
                    </span>
                  )}
                </Form.Label>
                <Form.Control
                  defaultValue={form.descripcion}
                  onChange={handleChange}
                  type="text"
                  name="descripcion"
                />
              </Form.Group>
            </div>
            <div className="module--form__box">
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="Quantity" />
                  {form.cantidad_error && (
                    <span className="text-error">
                      {" "}
                      * ( <FormattedMessage id={form.cantidad_error} />)
                    </span>
                  )}
                </Form.Label>
                <Form.Control
                  defaultValue={form.cantidad}
                  onChange={handleChange}
                  type="number"
                  name="cantidad"
                />
              </Form.Group>
            </div>
            {codigo.map((item, i) => (
              <div key={i} className="module--form__box">
                <Form.Group>
                  <Form.Label>
                    <FormattedMessage id="Code" />
                    {codigoError && item.code === "" && (
                      <span className="text-error">
                        {" "}
                        * ( <FormattedMessage id="CodeIsRequired" />)
                      </span>
                    )}
                  </Form.Label>
                  <Form.Control
                    defaultValue={item.code}
                    onChange={(event) =>
                      handleChangeProduct({
                        id: item.id,
                        value: event.target.value,
                      })
                    }
                    type="text"
                    name="descripcion"
                  />
                </Form.Group>
              </div>
            ))}
          </Modal.Body>
        )}

        {state.statusAddStock === 200 && state.dataAddStock.ejecucion.estado && (
          <Modal.Body>
            <p className="modal_down">
              <FormattedMessage id="TheProductWasSuccessfullyAdded" />
            </p>
            <h4 className="modal_icon">
              <i className="fas fa-smile"></i>
            </h4>
          </Modal.Body>
        )}

        {state.statusAddStock === 200 && !state.dataAddStock.ejecucion.estado && (
          <Modal.Body>
            <p className="modal_down">
              <FormattedMessage id="TheProductWasNotAdded" />
            </p>
            <h4 className="modal_icon">
              <i className="fas fa-sad-tear"></i>
            </h4>
          </Modal.Body>
        )}

        {state.statusAddStock === 0 && (
          <Modal.Footer>
            <Button
              disabled={state.loadingAddStock}
              variant="main"
              type="submit"
              onClick={postChange}
            >
              {state.loadingAddStock ? (
                <FormattedMessage id="Loading" />
              ) : (
                <FormattedMessage id="Send" />
              )}
            </Button>
          </Modal.Footer>
        )}
        {state.statusAddStock === 200 && (
          <Modal.Footer>
            <Button
              disabled={state.loadingAddStock}
              variant="info"
              type="submit"
              onClick={() => closeModal()}
            >
              <FormattedMessage id="Ok" />
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}

export default ModalAddProduct;
