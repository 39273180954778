import initialState from "./initialState";
import {
  GET_SUPERVISOR_INIT,
  GET_SUPERVISOR_SUCCESS,
  GET_SUPERVISOR_FAILURE,
  RESET_SUPERVISOR,
  UPDATE_STATUS_SUPERVISOR_INIT,
  UPDATE_STATUS_SUPERVISOR_SUCCESS,
  UPDATE_STATUS_SUPERVISOR_FAILURE,
  RESET_UPDATE_STATUS_SUPERVISOR,
} from "../../actions/supervisor/types";

export default function supervisor(state = initialState, action) {
  switch (action.type) {
    /* Setting */
    case RESET_SUPERVISOR:
      return {
        ...state,
        dataSupervisor: {},
        loadingSupervisor: true,
        statusSupervisor: 0,
      };
    case GET_SUPERVISOR_INIT:
      return {
        ...state,
        loadingSupervisor: action.payload,
      };
    case GET_SUPERVISOR_SUCCESS:
      return {
        ...state,
        dataSupervisor: action.payload.data,
        loadingSupervisor: false,
        statusSupervisor: action.payload.status,
      };
    case GET_SUPERVISOR_FAILURE:
      return {
        ...state,
        dataSupervisor: {},
        loadingSupervisor: false,
        statusSupervisor: 501,
      };
    case RESET_UPDATE_STATUS_SUPERVISOR:
      return {
        ...state,
        dataUpdateStatusSupervisor: {},
        loadingUpdateStatusSupervisor: false,
        statusUpdateStatusSupervisor: 0,
      };
    case UPDATE_STATUS_SUPERVISOR_INIT:
      return {
        ...state,
        loadingUpdateStatusSupervisor: true,
      };
    case UPDATE_STATUS_SUPERVISOR_SUCCESS:
      return {
        ...state,
        dataUpdateStatusSupervisor: action.payload.data,
        loadingUpdateStatusSupervisor: false,
        statusUpdateStatusSupervisor: action.payload.status,
      };
    case UPDATE_STATUS_SUPERVISOR_FAILURE:
      return {
        ...state,
        dataUpdateStatusSupervisor: {},
        loadingUpdateStatusSupervisor: false,
        statusUpdateStatusSupervisor: 501,
      };

    default:
      return state;
  }
}
