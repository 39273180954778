import React from "react";
import { Droppable } from "react-beautiful-dnd";

import Task from "./task";

const Tramo1 = (props) => {
  return (
    <div className="module-board--tramo-container">
      {props.data.map((item) => {
        return (
          <div
            key={item.casilla_numero}
            className={`module-board--tramo ${props.noBorder} `}
          >
            <Droppable
              droppableId={`${props.column}-${item.casilla_numero}`}
              index={item.casilla_numero}
            >
              {(provided, snapshot) => (
                <div
                  className={"expanded-drop"}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {item.tareas.map((item) => (
                    <Task
                      handleModalUpdate={props.handleModalUpdate}
                      handleModalTask={props.handleModalTask}
                      key={item.tarea_id}
                      id={`task-${item.tarea_id}`}
                      index={item.tarea_id}
                      data={item}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        );
      })}
    </div>
  );
};

export default Tramo1;
