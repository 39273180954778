import {
  UPDATE_SUPERVISOR_INIT,
  UPDATE_SUPERVISOR_SUCCESS,
  UPDATE_SUPERVISOR_FAILURE,
  RESET_UPDATE_SUPERVISOR,
} from "./types";
import API from "./api";

export function resetUpdateSupervisor() {
  return {
    type: RESET_UPDATE_SUPERVISOR,
  };
}

/* Get UpdateSupervisor (Async) */

function updateSupervisorSuccess(data) {
  return {
    type: UPDATE_SUPERVISOR_SUCCESS,
    payload: data,
  };
}

function updateSupervisorFailure(error) {
  console.log(error);
  return {
    type: UPDATE_SUPERVISOR_FAILURE,
    payload: error,
  };
}

function updateSupervisorInit() {
  return {
    type: UPDATE_SUPERVISOR_INIT,
  };
}

export function updateSupervisor(data) {
  return async (dispatch) => {
    dispatch(updateSupervisorInit());
    try {
      const resp = await API.data.updateSupervisor(data);
      return dispatch(updateSupervisorSuccess(resp));
    } catch (error) {
      return dispatch(updateSupervisorFailure(error));
    }
  };
}
