import React, { useState } from "react";

import { Table } from "react-bootstrap";

import { FormattedMessage } from "react-intl";
import Modal from "./modalAjuste";

const MyTable = (props) => {
  const [modal, setModal] = useState(false);
  const [data, setData] = useState(false);

  return (
    <div className="box-table">
      <Modal form={props.form} data={data} modal={modal} setModal={setModal} />
      <Table striped hover>
        <thead>
          <tr>
            <th>Id</th>
            <th>
              <FormattedMessage id="Product" />
            </th>
            <th>
              <FormattedMessage id="Quantity" />
            </th>
            <th className="center">
              <FormattedMessage id="Action" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{props.data.datos.acopio_id}</td>
            <td>{props.data.datos.producto_id}</td>
            <td>{props.data.datos.cantidad}</td>
            <td className="center">
              <span href="#ver" className="table-button--normal buttons">
                <i className="fas fa-ellipsis-h"></i>
                <div className="table-button--normal--option">
                  <ul>
                    <li>
                      <span
                        onClick={() => {
                          setModal(true);
                          setData({
                            acopio_id: props.data.datos.acopio_id,
                            timestamp: props.data.datos.timestamp,
                          });
                        }}
                      >
                        <FormattedMessage id={"EditQuantity"} />
                      </span>
                    </li>
                  </ul>
                </div>
              </span>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default MyTable;
