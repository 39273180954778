import {
  GET_WARE_HOUSE_INIT,
  GET_WARE_HOUSE_SUCCESS,
  GET_WARE_HOUSE_FAILURE,
  RESET_WARE_HOUSE,
  ADD_STOCK_INIT,
  ADD_STOCK_SUCCESS,
  ADD_STOCK_FAILURE,
  RESET_ADD_STOCK,
  MOVE_STOCK_INIT,
  MOVE_STOCK_SUCCESS,
  MOVE_STOCK_FAILURE,
  RESET_MOVE_STOCK,
} from "./types";
import API from "./api";

/* Actions Creators */

export function resetWareHouse() {
  return {
    type: RESET_WARE_HOUSE,
  };
}

/* Get WareHouse (Async) */

function getWareHouseSuccess(data) {
  return {
    type: GET_WARE_HOUSE_SUCCESS,
    payload: data,
  };
}

function getWareHouseFailure(error) {
  return {
    type: GET_WARE_HOUSE_FAILURE,
    payload: error,
  };
}

function getWareHouseInit(data) {
  return {
    type: GET_WARE_HOUSE_INIT,
    payload: data.loading,
  };
}

export function getWareHouse(data) {
  return async (dispatch) => {
    dispatch(getWareHouseInit(data));
    try {
      const resp = await API.data.getWareHouse(data);
      return dispatch(getWareHouseSuccess(resp));
    } catch (error) {
      return dispatch(getWareHouseFailure(error));
    }
  };
}

export function resetAddStock() {
  return {
    type: RESET_ADD_STOCK,
  };
}

/* Get AddStock (Async) */

function addStockSuccess(data) {
  return {
    type: ADD_STOCK_SUCCESS,
    payload: data,
  };
}

function addStockFailure(error) {
  return {
    type: ADD_STOCK_FAILURE,
    payload: error,
  };
}

function addStockInit(data) {
  return {
    type: ADD_STOCK_INIT,
  };
}

export function addStock(data) {
  return async (dispatch) => {
    dispatch(addStockInit());
    try {
      const resp = await API.data.addStock(data);
      return dispatch(addStockSuccess(resp));
    } catch (error) {
      return dispatch(addStockFailure(error));
    }
  };
}

export function resetMoveStock() {
  return {
    type: RESET_MOVE_STOCK,
  };
}

/* Get MoveStock (Async) */

function moveStockSuccess(data) {
  return {
    type: MOVE_STOCK_SUCCESS,
    payload: data,
  };
}

function moveStockFailure(error) {
  return {
    type: MOVE_STOCK_FAILURE,
    payload: error,
  };
}

function moveStockInit(data) {
  return {
    type: MOVE_STOCK_INIT,
  };
}

export function moveStock(data) {
  return async (dispatch) => {
    dispatch(moveStockInit());
    try {
      const resp = await API.data.moveStock(data);
      return dispatch(moveStockSuccess(resp));
    } catch (error) {
      return dispatch(moveStockFailure(error));
    }
  };
}
