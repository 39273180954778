import {
  ADD_CLIENT_INIT,
  ADD_CLIENT_SUCCESS,
  ADD_CLIENT_FAILURE,
  RESET_ADD_CLIENT,
} from "./types";
import API from "./api";

/* Actions Creators */

export function resetAddClient() {
  return {
    type: RESET_ADD_CLIENT,
  };
}

/* Get AddClient (Async) */

function addClientSuccess(data) {
  return {
    type: ADD_CLIENT_SUCCESS,
    payload: data,
  };
}

function addClientFailure(error) {
  console.log(error);
  return {
    type: ADD_CLIENT_FAILURE,
    payload: error,
  };
}

function addClientInit() {
  return {
    type: ADD_CLIENT_INIT,
  };
}

export function addClient(data) {
  return async (dispatch) => {
    dispatch(addClientInit());
    try {
      const resp = await API.data.addClient(data);
      return dispatch(addClientSuccess(resp));
    } catch (error) {
      return dispatch(addClientFailure(error));
    }
  };
}
