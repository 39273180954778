import initialState from "./initialState";
import {
  GET_WARE_HOUSE_INIT,
  GET_WARE_HOUSE_SUCCESS,
  GET_WARE_HOUSE_FAILURE,
  RESET_WARE_HOUSE,
  ADD_STOCK_INIT,
  ADD_STOCK_SUCCESS,
  ADD_STOCK_FAILURE,
  RESET_ADD_STOCK,
  MOVE_STOCK_INIT,
  MOVE_STOCK_SUCCESS,
  MOVE_STOCK_FAILURE,
  RESET_MOVE_STOCK,
} from "../../actions/ware_house/types";

export default function wareHouse(state = initialState, action) {
  switch (action.type) {
    /* Setting */
    case RESET_WARE_HOUSE:
      return {
        ...state,
        data: {},
        loading: true,
        status: 0,
      };
    case GET_WARE_HOUSE_INIT:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_WARE_HOUSE_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        status: action.payload.status,
      };
    case GET_WARE_HOUSE_FAILURE:
      return {
        ...state,
        data: {},
        loading: false,
        status: 501,
      };
    case RESET_ADD_STOCK:
      return {
        ...state,
        dataAddStock: {},
        loadingAddStock: false,
        statusAddStock: 0,
      };
    case ADD_STOCK_INIT:
      return {
        ...state,
        loadingAddStock: true,
      };
    case ADD_STOCK_SUCCESS:
      return {
        ...state,
        dataAddStock: action.payload.data,
        loadingAddStock: false,
        statusAddStock: action.payload.status,
      };
    case ADD_STOCK_FAILURE:
      return {
        ...state,
        dataAddStock: {},
        loadingAddStock: false,
        statusAddStock: 501,
      };
    case RESET_MOVE_STOCK:
      return {
        ...state,
        dataMoveStock: {},
        loadingMoveStock: false,
        statusMoveStock: 0,
      };
    case MOVE_STOCK_INIT:
      return {
        ...state,
        loadingMoveStock: true,
      };
    case MOVE_STOCK_SUCCESS:
      return {
        ...state,
        dataMoveStock: action.payload.data,
        loadingMoveStock: false,
        statusMoveStock: action.payload.status,
      };
    case MOVE_STOCK_FAILURE:
      return {
        ...state,
        dataMoveStock: {},
        loadingMoveStock: false,
        statusMoveStock: 501,
      };
    default:
      return state;
  }
}
