import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  listStockControl,
  resetListStockControl,
} from "../../actions//inventory";

/* Components */

import Modal from "../../components/common/modal";
import Table from "../../components/list_stock_control/table";
import Button from "../../components/list_stock_control/button";
import Header from "../../components/list_stock_control/header";
import Config from "../../actions/config";

const Inventory = (props) => {
  const state = useSelector((state) => state.inventory);
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");

  const [paginator, setPaginator] = useState({
    start: 0,
    end: Config.nxpage,
    main: 1,
    blockStart: 1,
    blockEnd: 10,
    loading: true,
  });

  useEffect(() => {
    const callsAPI = async () => {
      dispatch(listStockControl({ loading: true }));
    };
    callsAPI();
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetListStockControl());
    };
  }, [dispatch]);

  const handlePaginator = async (event) => {
    const num = parseInt(event.currentTarget.dataset.num, 10);

    let valorPaginator = Config.nxpage * parseInt(num, 10);

    let start = valorPaginator - Config.nxpage;
    let end = valorPaginator;

    let blockStart = paginator.blockStart;
    let blockEnd = paginator.blockEnd;

    if (blockStart === num) {
      if (num > 5) {
        blockStart = paginator.blockStart - 5;
        blockEnd = paginator.blockEnd - 5;
      }
    }

    if (blockEnd === num) {
      blockStart = paginator.blockStart + 5;
      blockEnd = paginator.blockEnd + 5;
    }

    const obj = {
      main: num,
      start,
      end,
      blockStart,
      blockEnd,
      loading: false,
    };

    setPaginator(obj);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    event.persist();
    setSearch(event.target.value);

    setPaginator({
      start: 0,
      end: Config.nxpage,
      main: 1,
      blockStart: 1,
      blockEnd: 10,
      loading: false,
    });
  };

  if (
    state.statusListStockControl === 401 ||
    state.statusListStockControl === 501
  ) {
    return <Modal history={props.history} state={true} />;
  }

  if (state.loadingListStockControl) {
    return (
      <div className="module--loading">
        <i className="fas fa-cog fa-spin"></i>
      </div>
    );
  }

  return (
    <div className="module--table">
      <Header search={search} handleSearch={handleSearch} />
      <Button />
      <Table
        search={search}
        handlePaginator={handlePaginator}
        paginator={paginator}
        data={state.dataListStockControl}
      />
    </div>
  );
};

export default Inventory;
