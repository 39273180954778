import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Paginator from "./paginator";

const MyTable = (props) => {
  let filter = props.data.datos.filter((o) => {
    return (
      o.nombre.toLowerCase().indexOf(props.search.toLowerCase()) > -1 ||
      o.instalador_id.names.toLowerCase().indexOf(props.search.toLowerCase()) >
        -1 ||
      o.instalador_id.last_names
        .toLowerCase()
        .indexOf(props.search.toLowerCase()) > -1 ||
      o.direccion_id.division.nombre
        .toLowerCase()
        .indexOf(props.search.toLowerCase()) > -1 ||
      o.direccion_id.pais.nombre
        .toLowerCase()
        .indexOf(props.search.toLowerCase()) > -1 ||
      o.direccion_id.subdivision_id.nombre
        .toLowerCase()
        .indexOf(props.search.toLowerCase()) > -1 ||
      o.direccion_id.calle_numero
        .toLowerCase()
        .indexOf(props.search.toLowerCase()) > -1
    );
  });

  const arr = filter.slice(props.paginator.start, props.paginator.end);

  return (
    <div className="box-table">
      <Table striped hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>
              <FormattedMessage id="Name" />
            </th>
            <th>
              <FormattedMessage id="Installer" />
            </th>
            <th>{props.dataAddress.datos.label_pais}</th>
            <th>{props.dataAddress.datos.label_divisiones}</th>
            <th>{props.dataAddress.datos.label_subdivisiones}</th>
            <th>
              <FormattedMessage id="StreetAndNumber" />
            </th>
            <th className="center">
              <FormattedMessage id="Action" />
            </th>
          </tr>
        </thead>
        <tbody>
          {arr.map((item, i) => (
            <tr key={i}>
              <td>{item.id}</td>
              <td>{item.nombre}</td>
              <td>{`${item.instalador_id.names} ${item.instalador_id.last_names}`}</td>
              <td>{item.direccion_id.pais.nombre}</td>
              <td>{item.direccion_id.division.nombre}</td>
              <td>{item.direccion_id.subdivision_id.nombre}</td>
              <td>{item.direccion_id.calle_numero}</td>
              <td className="center">
                <span href="#ver" className="table-button--normal buttons">
                  <i className="fas fa-ellipsis-h"></i>
                  <div className="table-button--normal--option">
                    <ul>
                      <li>
                        <Link
                          to={{
                            pathname: `/dashboard/ware_house/${item.id}`,
                            state: {
                              timestamp: item.timestamp,
                            },
                          }}
                        >
                          <FormattedMessage id="View" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Paginator
        total={filter.length}
        handlePaginator={props.handlePaginator}
        paginator={props.paginator}
        data={props.dataCount}
      />
    </div>
  );
};

export default MyTable;
