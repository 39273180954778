import initialState from "./initialState";
import {
  GET_MOVEMENTS_INIT,
  GET_MOVEMENTS_SUCCESS,
  GET_MOVEMENTS_FAILURE,
  RESET_MOVEMENTS,
  GET_CONTROL_INIT,
  GET_CONTROL_SUCCESS,
  GET_CONTROL_FAILURE,
  RESET_CONTROL,
  ADD_CANTIDAD_INIT,
  ADD_CANTIDAD_SUCCESS,
  ADD_CANTIDAD_FAILURE,
  RESET_ADD_CANTIDAD,
  DELETE_PIEZA_INIT,
  DELETE_PIEZA_SUCCESS,
  DELETE_PIEZA_FAILURE,
  RESET_DELETE_PIEZA,
  DESACTIVAR_PIEZA_INIT,
  DESACTIVAR_PIEZA_SUCCESS,
  DESACTIVAR_PIEZA_FAILURE,
  RESET_DESACTIVAR_PIEZA,
} from "../../actions/movements/types";

export default function client(state = initialState, action) {
  switch (action.type) {
    /* Setting */

    case RESET_MOVEMENTS:
      return {
        ...state,
        dataMovements: {},
        loadingMovements: true,
        statusMovements: 0,
      };
    case GET_MOVEMENTS_INIT:
      return {
        ...state,
        loadingMovements: action.payload,
      };
    case GET_MOVEMENTS_SUCCESS:
      return {
        ...state,
        dataMovements: action.payload.data,
        loadingMovements: false,
        statusMovements: action.payload.status,
      };
    case GET_MOVEMENTS_FAILURE:
      return {
        ...state,
        dataMovements: {},
        loadingMovements: false,
        statusMovements: 501,
      };

    case RESET_CONTROL:
      return {
        ...state,
        dataControl: {},
        loadingControl: false,
        statusControl: 0,
      };
    case GET_CONTROL_INIT:
      return {
        ...state,
        loadingControl: action.payload,
      };
    case GET_CONTROL_SUCCESS:
      return {
        ...state,
        dataControl: action.payload.data,
        loadingControl: false,
        statusControl: action.payload.status,
      };
    case GET_CONTROL_FAILURE:
      return {
        ...state,
        dataControl: {},
        loadingControl: false,
        statusControl: 501,
      };
    case RESET_ADD_CANTIDAD:
      return {
        ...state,
        dataAddCantidad: {},
        loadingAddCantidad: false,
        statusAddCantidad: 0,
      };
    case ADD_CANTIDAD_INIT:
      return {
        ...state,
        loadingAddCantidad: true,
      };
    case ADD_CANTIDAD_SUCCESS:
      return {
        ...state,
        dataAddCantidad: action.payload.data,
        loadingAddCantidad: false,
        statusAddCantidad: action.payload.status,
      };
    case ADD_CANTIDAD_FAILURE:
      return {
        ...state,
        dataAddCantidad: {},
        loadingAddCantidad: false,
        statusAddCantidad: 501,
      };
    case RESET_DELETE_PIEZA:
      return {
        ...state,
        dataDeletePieza: {},
        loadingDeletePieza: false,
        statusDeletePieza: 0,
      };
    case DELETE_PIEZA_INIT:
      return {
        ...state,
        loadingDeletePieza: true,
      };
    case DELETE_PIEZA_SUCCESS:
      return {
        ...state,
        dataDeletePieza: action.payload.data,
        loadingDeletePieza: false,
        statusDeletePieza: action.payload.status,
      };
    case DELETE_PIEZA_FAILURE:
      return {
        ...state,
        dataDeletePieza: {},
        loadingDeletePieza: false,
        statusDeletePieza: 501,
      };
    case RESET_DESACTIVAR_PIEZA:
      return {
        ...state,
        dataDesactivarPieza: {},
        loadingDesactivarPieza: false,
        statusDesactivarPieza: 0,
      };
    case DESACTIVAR_PIEZA_INIT:
      return {
        ...state,
        loadingDesactivarPieza: true,
      };
    case DESACTIVAR_PIEZA_SUCCESS:
      return {
        ...state,
        dataDesactivarPieza: action.payload.data,
        loadingDesactivarPieza: false,
        statusDesactivarPieza: action.payload.status,
      };
    case DESACTIVAR_PIEZA_FAILURE:
      return {
        ...state,
        dataDesactivarPieza: {},
        loadingDesactivarPieza: false,
        statusDesactivarPieza: 501,
      };

    default:
      return state;
  }
}
