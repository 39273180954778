import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getWareHouse, resetWareHouse } from "../../actions/ware_house";
import { FormattedMessage } from "react-intl";

/* Components */
import Modal from "../../components/common/modal";
import Bread from "../../components/ware_house/bread";
import Button from "../../components/ware_house/button";
import Table from "../../components/ware_house/table";
import Header from "../../components/ware_house/header";
import Config from "../../actions/config";

const WareHouse = (props) => {
  const state = useSelector((state) => state.wareHouse);
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");

  const [paginator, setPaginator] = useState({
    start: 0,
    end: Config.nxpage,
    main: 1,
    blockStart: 1,
    blockEnd: 10,
    loading: true,
  });

  useEffect(() => {
    const callsAPI = async () => {
      dispatch(
        getWareHouse({ bodega_id: props.match.params.id, loading: true })
      );
    };
    callsAPI();
  }, [dispatch, props.match.params.id]);

  useEffect(() => {
    return () => {
      dispatch(resetWareHouse());
    };
  }, [dispatch]);

  const handlePaginator = async (event) => {
    const num = parseInt(event.currentTarget.dataset.num, 10);

    let valorPaginator = Config.nxpage * parseInt(num, 10);

    let start = valorPaginator - Config.nxpage;
    let end = valorPaginator;

    let blockStart = paginator.blockStart;
    let blockEnd = paginator.blockEnd;

    if (blockStart === num) {
      if (num > 5) {
        blockStart = paginator.blockStart - 5;
        blockEnd = paginator.blockEnd - 5;
      }
    }

    if (blockEnd === num) {
      blockStart = paginator.blockStart + 5;
      blockEnd = paginator.blockEnd + 5;
    }

    const obj = {
      main: num,
      start,
      end,
      blockStart,
      blockEnd,
      loading: false,
    };

    setPaginator(obj);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    event.persist();
    setSearch(event.target.value);

    setPaginator({
      start: 0,
      end: Config.nxpage,
      main: 1,
      blockStart: 1,
      blockEnd: 10,
      loading: false,
    });
  };

  if (state.status === 401 || state.status === 501) {
    return <Modal history={props.history} state={true} />;
  }

  if (state.loading) {
    return (
      <div className="module--loading">
        <i className="fas fa-cog fa-spin"></i>
      </div>
    );
  }

  if (!state.data.ejecucion.estado) {
    return (
      <div className="module-message">
        <h1>
          <FormattedMessage id="YouCannotViewTheWareHouse" />
        </h1>
      </div>
    );
  }

  return (
    <div className="module--table">
      <Header search={search} handleSearch={handleSearch} />
      <Bread />
      <Button />
      <Table
        search={search}
        handlePaginator={handlePaginator}
        paginator={paginator}
        id={props.match.params.id}
        data={state.data}
      />
    </div>
  );
};

export default WareHouse;
