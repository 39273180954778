export const RESET_PLANNER = "RESET_PLANNER";
export const RESET_PLANNER_UPDATE = "RESET_PLANNER_UPDATE";

/* (ASYNC) */
export const GET_PLANNER_INIT = "GET_PLANNER_INIT";
export const GET_PLANNER_SUCCESS = "GET_PLANNER_SUCCESS";
export const GET_PLANNER_FAILURE = "GET_PLANNER_FAILURE";

export const UPDATE_PLANNER_INIT = "UPDATE_PLANNER_INIT";
export const UPDATE_PLANNER_SUCCESS = "UPDATE_PLANNER_SUCCESS";
export const UPDATE_PLANNER_FAILURE = "UPDATE_PLANNER_FAILURE";
