import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

function ModalCodes(props) {
  return (
    <>
      <Modal
        onHide={() => props.setModal(false)}
        show={props.modal}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Codes" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {props.data && <p>{props.data.producto.nombre}</p>}

          {props.data &&
            props.data.piezas.map((pr, i) => {
              let active = pr.usado ? "Yes" : "No";
              return (
                <p className="modal_down" key={i}>
                  {`${pr.codigo} =`} <FormattedMessage id="Used" />{" "}
                  <FormattedMessage id={active} />
                </p>
              );
            })}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="info"
            type="submit"
            onClick={() => props.setModal(false)}
          >
            <FormattedMessage id="Ok" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalCodes;
