import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";

import {
  addInstaller,
  resetAddInstaller,
} from "../../actions/create_installer";

function ModalInstaller(props) {
  const state = useSelector((state) => state.createInstaller);
  const dispatch = useDispatch();

  const closeModal = () => {
    props.setModal(false);
    dispatch(resetAddInstaller());

    if (state.statusAddInstaller === 200) {
      if (state.dataAddInstaller.ejecucion.estado) {
        props.history.push("/dashboard/installer");
      }
    }
  };

  const sendPostAddInstaller = () => {
    let pais = localStorage.getItem("pais");
    pais = JSON.parse(pais);

    let franquicia = localStorage.getItem("franquicia");
    franquicia = JSON.parse(franquicia);

    const obj = {
      instalador: {
        username: props.form.user,
        password: props.form.pass,
        names: props.form.nombre,
        last_names: props.form.apellido,
        pais_id: pais.id,
        uid: props.form.uid,
        franquicia_id: franquicia.id,
        email: props.form.email,
        telefono: props.form.phone,
        lenguaje: props.form.language,
        activo: true,
      },
      bodega: {
        nombre: props.form.nombre_bodega,
      },
      direccion_bodega: {
        pais_id: pais.id,
        division_id: parseInt(props.form.division_id, 10),
        subdivision_id: parseInt(props.form.subdivision_id, 10),
        calle_numero: props.form.calle_numero,
        lugar: props.form.lugar,
        ciudad: props.form.ciudad,
        codigo_postal: props.form.codigo_postal,
        latitud: null,
        longitud: null,
      },
    };

    dispatch(addInstaller(obj));
  };

  return (
    <>
      <Modal
        onHide={() => closeModal()}
        show={props.modal}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            <FormattedMessage id="AddInstaller" />
          </Modal.Title>
        </Modal.Header>
        {state.statusAddInstaller === 0 && (
          <Modal.Body>
            <p className="modal_down">
              <FormattedMessage id="AreYouSure" />
            </p>
            <h4 className="modal_icon">
              <i className="fas fa-info-circle"></i>
            </h4>
          </Modal.Body>
        )}
        {state.statusAddInstaller === 200 &&
          state.dataAddInstaller.ejecucion.estado && (
            <Modal.Body>
              <p className="modal_down">
                <FormattedMessage id="WasSuccessfullyAdded" />
              </p>
              <h4 className="modal_icon">
                <i className="fas fa-smile"></i>
              </h4>
            </Modal.Body>
          )}
        {state.statusAddInstaller === 200 &&
          !state.dataAddInstaller.ejecucion.estado && (
            <Modal.Body>
              <p className="modal_down">
                <FormattedMessage id="WasNotAdded" />
              </p>
              <h4 className="modal_icon">
                <i className="fas fa-sad-tear"></i>
              </h4>
            </Modal.Body>
          )}
        {state.statusAddInstaller === 0 && (
          <Modal.Footer>
            <Button
              disabled={state.loadingAddInstaller}
              variant="info"
              type="submit"
              onClick={() => closeModal()}
            >
              <FormattedMessage id="Cancel" />
            </Button>
            <Button
              disabled={state.loadingAddInstaller}
              variant="main"
              type="submit"
              onClick={() => sendPostAddInstaller()}
            >
              {state.loadingAddInstaller ? (
                <FormattedMessage id="Loading" />
              ) : (
                <FormattedMessage id="Add" />
              )}
            </Button>
          </Modal.Footer>
        )}
        {state.statusAddInstaller === 200 && (
          <Modal.Footer>
            <Button
              disabled={state.loadingAddInstaller}
              variant="info"
              type="submit"
              onClick={() => closeModal()}
            >
              <FormattedMessage id="Ok" />
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}

export default ModalInstaller;
