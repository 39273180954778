import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const Admin = (props) => {
  return (
    <li className="relative">
      <button
        id="menu-100"
        href="#person"
        data-menu="ok"
        className={`arrow ${props.mobile}`}
        onClick={props.handleMenu}
        onKeyDown={props.handleMenu}
        data-child="sub--menu_100"
      >
        <i className="fas fa-download"></i>
        <strong>
          <FormattedMessage id="Instalation" />
        </strong>
      </button>
      <div
        data-subbox="ok"
        id="sub--menu_100"
        className={`module--dashboardNav__subMenu ${props.mobile}`}
      >
        <div className="module--dashboardNav__subMenuBox">
          <Link
            to="/dashboard/instalation_pending"
            id="submenu100-a"
            data-submenu="ok"
            onClick={props.handleSubMenu}
            onKeyDown={props.handleSubMenu}
          >
            <i className="far fa-clock"></i>
            <FormattedMessage id="Pending" />
          </Link>
          <Link
            to="/dashboard/instalation_authorize"
            id="submenu100-b"
            data-submenu="ok"
            onClick={props.handleSubMenu}
            onKeyDown={props.handleSubMenu}
            className={`${props.mobile}`}
          >
            <i className="far fa-check-circle"></i>
            <FormattedMessage id="ToAuthorize" />
          </Link>
          <Link
            to="/dashboard/instalation_finished"
            id="submenu100-c"
            data-submenu="ok"
            onClick={props.handleSubMenu}
            onKeyDown={props.handleSubMenu}
            className={`${props.mobile}`}
          >
            <i className="far fa-times-circle"></i>
            <FormattedMessage id="ToFinalize" />
          </Link>
        </div>
      </div>
    </li>
  );
};

export default Admin;
