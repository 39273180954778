import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getInventory } from "../../actions//inventory";
import { FormattedMessage } from "react-intl";

/* Component */
import { Form } from "react-bootstrap";

const FiltersBodegaHeader = (props) => {
  const state = useSelector((state) => state.inventory);
  const dispatch = useDispatch();
  useEffect(() => {
    const callsAPI = async () => {
      dispatch(getInventory({ loading: false }));
    };
    callsAPI();
  }, [dispatch]);

  return (
    <div className="box-header left">
      <Form.Control
        as="select"
        value={props.bodegaId}
        onChange={props.handleChange}
        name="bodega_id"
        className="input-search"
        requeried="true"
        data-number="ok"
      >
        <FormattedMessage id="SelectedWarehouse">
          {(message) => <option value="">{message}</option>}
        </FormattedMessage>
        {state.status === 200 &&
          state.data.datos.map((item) => (
            <option
              key={item.id}
              value={item.id}
              disabled={props.id === item.id ? true : false}
            >
              {`${item.id} - ${item.instalador_id.names} ${item.instalador_id.last_names}`}
            </option>
          ))}
      </Form.Control>
    </div>
  );
};

export default FiltersBodegaHeader;
