import axios from "axios";

const API = {
  data: {
    async getInventory(obj) {
      const response = await axios.get(
        `/api/inventory/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getProductsFilter(obj) {
      const response = await axios.get(
        `/api/productsFilter/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getGlosaAddress(obj) {
      const response = await axios.get(
        `/api/glosaAddress/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async listStockControl(obj) {
      const response = await axios.post(`/api/listStockControl`, obj);
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async updateListStockControl(obj) {
      const response = await axios.post(`/api/updateListStockControl`, obj);
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
  },
};
export default API;
