export const RESET_INSTALLER = "RESET_INSTALLER";

/* (ASYNC) */
export const GET_INSTALLER_INIT = "GET_INSTALLER_INIT";
export const GET_INSTALLER_SUCCESS = "GET_INSTALLER_SUCCESS";
export const GET_INSTALLER_FAILURE = "GET_INSTALLER_FAILURE";

export const RESET_UPDATE_STATUS_INSTALLER = "RESET_UPDATE_STATUS_INSTALLER";

/* (ASYNC) */
export const UPDATE_STATUS_INSTALLER_INIT = "UPDATE_STATUS_INSTALLER_INIT";
export const UPDATE_STATUS_INSTALLER_SUCCESS =
  "UPDATE_STATUS_INSTALLER_SUCCESS";
export const UPDATE_STATUS_INSTALLER_FAILURE =
  "UPDATE_STATUS_INSTALLER_FAILURE";
