import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import {
  addCantidad,
  resetAddCantidad,
  getControl,
} from "../../actions/movements";

function ModalAddProduct(props) {
  const [form, setForm] = useState({
    cantidad: "",
    cantidad_error: false,
    descripcion: "",
    descripcion_error: false,
  });

  const state = useSelector((state) => state.movements);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
      [`${event.target.name}_error`]: false,
    }));
  };

  const closeModal = () => {
    props.setModal(false);
    setForm({
      cantidad: "",
      cantidad_error: false,
      descripcion: "",
      descripcion_error: false,
    });

    dispatch(
      getControl({
        loading: true,
        bodega_id: parseInt(props.form.bodega_id, 10),
        producto_id: parseInt(props.form.producto_id, 10),
        ver_usadas: props.form.estado === "1" ? true : false,
      })
    );
    dispatch(resetAddCantidad());
  };

  const postChange = (event) => {
    event.preventDefault();
    event.persist();

    let allGood = true;
    if (form.cantidad === "") {
      setForm((prev) => ({ ...prev, cantidad_error: "RequiredField" }));
      allGood = false;
    }
    if (form.descripcion === "") {
      setForm((prev) => ({ ...prev, descripcion_error: "RequiredField" }));
      allGood = false;
    }

    const obj = {
      acopio_id: props.data.acopio_id,
      descripcion: form.descripcion,
      cantidad_ajuste: parseInt(form.cantidad, 10),
      timestamp: props.data.timestamp,
    };

    if (allGood) {
      dispatch(addCantidad(obj));
    }
  };

  return (
    <>
      <Modal
        onHide={() => closeModal()}
        show={props.modal}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="EditQuantity" />
          </Modal.Title>
        </Modal.Header>

        {state.statusAddCantidad === 0 && (
          <Modal.Body>
            {props.data && (
              <p className="modal_down">Id:{props.data.acopio_id}</p>
            )}
            <div className="module--form__box">
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="Description" />
                  {form.descripcion_error && (
                    <span className="text-error">
                      {" "}
                      * ( <FormattedMessage id={form.descripcion_error} />)
                    </span>
                  )}
                </Form.Label>
                <Form.Control
                  defaultValue={form.descripcion}
                  onChange={handleChange}
                  type="text"
                  name="descripcion"
                />
              </Form.Group>
            </div>
            <div className="module--form__box">
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="Quantity" />
                  {form.cantidad_error && (
                    <span className="text-error">
                      {" "}
                      * ( <FormattedMessage id={form.cantidad_error} />)
                    </span>
                  )}
                </Form.Label>
                <Form.Control
                  defaultValue={form.cantidad}
                  onChange={handleChange}
                  type="number"
                  name="cantidad"
                />
              </Form.Group>
            </div>
          </Modal.Body>
        )}

        {state.statusAddCantidad === 200 &&
          state.dataAddCantidad.ejecucion.estado && (
            <Modal.Body>
              <p className="modal_down">
                <FormattedMessage id="TheProductWasSuccessfullyUpdated" />
              </p>
              <h4 className="modal_icon">
                <i className="fas fa-smile"></i>
              </h4>
            </Modal.Body>
          )}

        {state.statusAddCantidad === 200 &&
          !state.dataAddCantidad.ejecucion.estado && (
            <Modal.Body>
              <p className="modal_down">
                <FormattedMessage id="TheProductWasNotAdded" />
              </p>
              <h4 className="modal_icon">
                <i className="fas fa-sad-tear"></i>
              </h4>
            </Modal.Body>
          )}

        {state.statusAddCantidad === 0 && (
          <Modal.Footer>
            <Button
              disabled={state.loadingAddCantidad}
              variant="main"
              type="submit"
              onClick={postChange}
            >
              {state.loadingAddCantidad ? (
                <FormattedMessage id="Loading" />
              ) : (
                <FormattedMessage id="Send" />
              )}
            </Button>
          </Modal.Footer>
        )}
        {state.statusAddCantidad === 200 && (
          <Modal.Footer>
            <Button
              disabled={state.loadingAddCantidad}
              variant="info"
              type="submit"
              onClick={() => closeModal()}
            >
              <FormattedMessage id="Ok" />
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}

export default ModalAddProduct;
