import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DragDropContext } from "react-beautiful-dnd";

/* ----------  Actions ----------l*/
import { updatePlanner, resetPlannerUpdate } from "../../actions/planner";

import Days from "./days";
import Tramo from "./tramo";
import Tramo1 from "./tramo1";

const MyBoard = ({
  data,
  loading,
  status,
  dataPlanner,
  statusPlanner,
  handleModalTask,
  handleModalUpdate,
  showView,
}) => {
  const [task, setTask] = useState([]);
  const [largo, setLargo] = useState(1);
  const [count, setCount] = useState(0);
  const [info, setInfo] = useState(false);
  const dispatch = useDispatch();

  /* ----------  Task in state ----------l*/
  /* ----------  Task in state ----------l*/
  /* ----------  Task in state ----------l*/
  /* ----------  Task in state ----------l*/

  useEffect(() => {
    if (status === 200) {
      if (data.ejecucion.estado) {
        setTask(data.datos.casillas);
        setLargo(data.datos.marco.tramos.length);
      }
    }
  }, [data, status]);

  /* ----------  Task update  ----------l*/
  /* ----------  Task update  ----------l*/
  /* ----------  Task update  ----------l*/
  /* ----------  Task update  ----------l*/

  useEffect(() => {
    if (statusPlanner === 200) {
      if (dataPlanner.ejecucion.estado) {
        if (info) {
          const newTask = task;

          const theTaskIndex = newTask[info.indexDestination].tareas.findIndex(
            (item) => item.tarea_id === parseInt(info.idTask, 10)
          );

          newTask[info.indexDestination].tareas[theTaskIndex].timestamp =
            dataPlanner.datos.timestamp;
          setTask(newTask);
          setCount(count + 1);
          setInfo(false);
          dispatch(resetPlannerUpdate());
        }
      }
    }
  }, [dataPlanner, statusPlanner, count, dispatch, info, task]);

  if (loading) {
    return (
      <div className="module-board">
        <div className="module-board--loading">
          <i className="fas fa-cog fa-spin"></i>
        </div>
      </div>
    );
  }

  let monday = task.slice(0, largo);
  let tuesday = task.slice(largo * 1, largo * 1 + largo);
  let wednesday = task.slice(largo * 2, largo * 2 + largo);
  let thursday = task.slice(largo * 3, largo * 3 + largo);
  let friday = task.slice(largo * 4, largo * 4 + largo);
  let saturday = task.slice(largo * 5, largo * 5 + largo);
  let sunday = task.slice(largo * 6, largo * 6 + largo);

  /* ----------  Drag end ----------l*/
  /* ----------  Drag end ----------l*/
  /* ----------  Drag end ----------l*/
  /* ----------  Drag end ----------l*/

  const onDragEnd = async (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newTask = task;

    const casillaDestination = destination.droppableId.split("-");
    const casillaSource = source.droppableId.split("-");
    const idTask = draggableId.split("-");

    const indexDestination = task.findIndex(
      (item) => item.casilla_numero === parseInt(casillaDestination[1], 10)
    );

    const indexSource = task.findIndex(
      (item) => item.casilla_numero === parseInt(casillaSource[1], 10)
    );

    const objSource = task.find(
      (item) => item.casilla_numero === parseInt(casillaSource[1], 10)
    );

    const theTask = objSource.tareas.find(
      (item) => item.tarea_id === parseInt(idTask[1], 10)
    );

    const theTaskIndex = objSource.tareas.findIndex(
      (item) => item.tarea_id === parseInt(idTask[1], 10)
    );

    const objUpdate = {
      tarea_id: objSource.tareas[theTaskIndex].tarea_id,
      timestamp: objSource.tareas[theTaskIndex].timestamp,
      fecha: newTask[indexDestination].fecha,
      tramo_horario_id: newTask[indexDestination].tramo_horario.id,
    };

    newTask[indexDestination].tareas.push(theTask);
    newTask[indexSource].tareas.splice(theTaskIndex, 1);

    setInfo({
      indexDestination: indexDestination,
      idTask: idTask[1],
    });
    setTask([...newTask]);
    setCount(count + 1);
    dispatch(updatePlanner(objUpdate));
  };

  return (
    <div className="module-board">
      <div className="module-board--days">
        <Days data={data.datos.marco.dias_semana} />
      </div>
      <div
        className={
          showView ? `module-board--container small` : `module-board--container`
        }
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Tramo
            focus={data.datos.marco.numero_tramo_focus}
            data={data.datos.marco.tramos}
          />
          <Tramo1
            handleModalUpdate={handleModalUpdate}
            handleModalTask={handleModalTask}
            key={"1"}
            data={monday}
            column={"monday"}
            index={1001}
          />
          <Tramo1
            handleModalUpdate={handleModalUpdate}
            handleModalTask={handleModalTask}
            key={"2"}
            data={tuesday}
            column={"tuesday"}
            index={1002}
          />
          <Tramo1
            handleModalUpdate={handleModalUpdate}
            handleModalTask={handleModalTask}
            key={"3"}
            data={wednesday}
            column={"wednesday"}
            index={1003}
          />
          <Tramo1
            handleModalUpdate={handleModalUpdate}
            handleModalTask={handleModalTask}
            key={"4"}
            data={thursday}
            column={"thursday"}
            index={1004}
          />
          <Tramo1
            handleModalUpdate={handleModalUpdate}
            handleModalTask={handleModalTask}
            key={"5"}
            data={friday}
            column={"friday"}
            index={1005}
          />
          <Tramo1
            handleModalUpdate={handleModalUpdate}
            handleModalTask={handleModalTask}
            key={"6"}
            data={saturday}
            column={"saturday"}
            index={1006}
          />
          <Tramo1
            handleModalUpdate={handleModalUpdate}
            handleModalTask={handleModalTask}
            key={"7"}
            data={sunday}
            column={"sunday"}
            index={1007}
            noBorder="noBorder"
          />
        </DragDropContext>
      </div>
    </div>
  );
};

export default MyBoard;
