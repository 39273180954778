import React from "react";
import { FormattedMessage } from "react-intl";

const MyButton = (props) => {
  return (
    <div className="module-table--button">
      <div className="module-table--button-text">
        <h1>
          <FormattedMessage id="Inventory" />
        </h1>
        <h2>
          <FormattedMessage id="ControlList" />
        </h2>
      </div>
      <div className="module-table--button-add"></div>
    </div>
  );
};

export default MyButton;
