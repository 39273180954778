import React from "react";

import Breadcrumbs from "../../components/create_installer/breadcrumbs";
import Form from "../../components/create_installer/form";

const CreateInstaller = (props) => {
  return (
    <div>
      <Breadcrumbs />
      <Form history={props.history} />
    </div>
  );
};

export default CreateInstaller;
