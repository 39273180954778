import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";

const MyInstaller = ({
  data,
  setInstalador,
  instalador,
  onChangeInstalador,
}) => {
  useEffect(() => {
    const arr = [];
    data.datos.forEach((element) => {
      arr.push(element.id);
    });
    setInstalador(arr);
  }, [data, setInstalador]);

  return (
    <div className="module-planner--filter">
      <h3>
        <FormattedMessage id="Installer" />
      </h3>
      <div className="module-planner--filter-check">
        {data.datos.map((item) => {
          const found = instalador.find((element) => element === item.id);

          return (
            <label key={item.id} className="container-check">
              <span className="checkmark-label">{`${item.names} ${item.last_names}`}</span>
              <input
                onChange={() => onChangeInstalador(item.id)}
                type="checkbox"
                checked={found ? "checked" : ""}
                value={item.id}
              />
              <span className="checkmark"></span>
            </label>
          );
        })}
      </div>
    </div>
  );
};

export default MyInstaller;
