import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const DataMain = (props) => {
  return (
    <Breadcrumb>
      <li className="breadcrumb-item active">
        <Link to={`/dashboard/installer`}>
          <i className="fas fa-arrow-left"></i>{" "}
          <FormattedMessage id="InstallerList" />
        </Link>
      </li>
      <Breadcrumb.Item active>
        <FormattedMessage id="AddInstaller" />
      </Breadcrumb.Item>
    </Breadcrumb>
  );
};

export default DataMain;
