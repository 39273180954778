import {
  GET_PLANNER_INIT,
  GET_PLANNER_SUCCESS,
  GET_PLANNER_FAILURE,
  RESET_PLANNER,
  UPDATE_PLANNER_INIT,
  UPDATE_PLANNER_SUCCESS,
  UPDATE_PLANNER_FAILURE,
  RESET_PLANNER_UPDATE,
} from "./types";
import API from "./api";

/* Actions Creators */

export function resetPlanner() {
  return {
    type: RESET_PLANNER,
  };
}

/* Get Planner (Async) */

function getPlannerSuccess(data) {
  return {
    type: GET_PLANNER_SUCCESS,
    payload: data,
  };
}

function getPlannerFailure(error) {
  console.log(error);
  return {
    type: GET_PLANNER_FAILURE,
    payload: error,
  };
}

function getPlannerInit(data) {
  return {
    type: GET_PLANNER_INIT,
    payload: data.loading,
  };
}

export function getPlanner(data) {
  return async (dispatch) => {
    dispatch(getPlannerInit(data));
    try {
      const resp = await API.data.getPlanner(data);
      return dispatch(getPlannerSuccess(resp));
    } catch (error) {
      return dispatch(getPlannerFailure(error));
    }
  };
}

export function resetPlannerUpdate() {
  return {
    type: RESET_PLANNER_UPDATE,
  };
}

/* Get Planner (Async) */

function updatePlannerSuccess(data) {
  return {
    type: UPDATE_PLANNER_SUCCESS,
    payload: data,
  };
}

function updatePlannerFailure(error) {
  console.log(error);
  return {
    type: UPDATE_PLANNER_FAILURE,
    payload: error,
  };
}

function updatePlannerInit(data) {
  return {
    type: UPDATE_PLANNER_INIT,
  };
}

export function updatePlanner(data) {
  return async (dispatch) => {
    dispatch(updatePlannerInit());
    try {
      const resp = await API.data.updatePlanner(data);
      return dispatch(updatePlannerSuccess(resp));
    } catch (error) {
      return dispatch(updatePlannerFailure(error));
    }
  };
}
