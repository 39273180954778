import {
  GET_SUPERVISOR_INIT,
  GET_SUPERVISOR_SUCCESS,
  GET_SUPERVISOR_FAILURE,
  RESET_SUPERVISOR,
  UPDATE_STATUS_SUPERVISOR_INIT,
  UPDATE_STATUS_SUPERVISOR_SUCCESS,
  UPDATE_STATUS_SUPERVISOR_FAILURE,
  RESET_UPDATE_STATUS_SUPERVISOR,
} from "./types";
import API from "./api";

/* Actions Creators */

export function resetSupervisor() {
  return {
    type: RESET_SUPERVISOR,
  };
}

/* Get Supervisor (Async) */

function getSupervisorSuccess(data) {
  return {
    type: GET_SUPERVISOR_SUCCESS,
    payload: data,
  };
}

function getSupervisorFailure(error) {
  console.log(error);
  return {
    type: GET_SUPERVISOR_FAILURE,
    payload: error,
  };
}

function getSupervisorInit(data) {
  return {
    type: GET_SUPERVISOR_INIT,
    payload: data.loading,
  };
}

export function getSupervisor(data) {
  return async (dispatch) => {
    dispatch(getSupervisorInit(data));
    try {
      const resp = await API.data.getSupervisor(data);
      return dispatch(getSupervisorSuccess(resp));
    } catch (error) {
      return dispatch(getSupervisorFailure(error));
    }
  };
}

export function resetUpdateStatusSupervisor() {
  return {
    type: RESET_UPDATE_STATUS_SUPERVISOR,
  };
}

/* Get UpdateStatusSupervisor (Async) */

function updateStatusSupervisorSuccess(data) {
  return {
    type: UPDATE_STATUS_SUPERVISOR_SUCCESS,
    payload: data,
  };
}

function updateStatusSupervisorFailure(error) {
  console.log(error);
  return {
    type: UPDATE_STATUS_SUPERVISOR_FAILURE,
    payload: error,
  };
}

function updateStatusSupervisorInit() {
  return {
    type: UPDATE_STATUS_SUPERVISOR_INIT,
  };
}

export function updateStatusSupervisor(data) {
  return async (dispatch) => {
    dispatch(updateStatusSupervisorInit());
    try {
      const resp = await API.data.updateStatusSupervisor(data);
      return dispatch(updateStatusSupervisorSuccess(resp));
    } catch (error) {
      return dispatch(updateStatusSupervisorFailure(error));
    }
  };
}
