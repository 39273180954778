import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Instalador from "../filters/filtersInstalador";
import moment from "moment";
import {
  addTaskAssign,
  resetAddTaskAssign,
  getTask,
} from "../../actions/instalation_pending";
import { FormattedMessage } from "react-intl";

function ModalAssing(props) {
  /*  Use  states  */
  const [form, setForm] = useState({
    instalador_id: "",
    instalador_id_error: false,
  });

  /*  User Select from redux  */
  const state = useSelector((state) => state.instalationPending);
  const dispatch = useDispatch();

  const postChange = (event) => {
    event.preventDefault();
    event.persist();
    const formTest = {
      tarea_id: props.data.id,
      timestamp: props.data.timestamp,
      instalador_id: form.instalador_id,
      comienza: moment(props.data.fecha_inicio.substring(0, 16)).format(
        "DD-MM-YYYY HH:mm"
      ),
      termina: moment(props.data.fecha_vencimiento.substring(0, 16)).format(
        "DD-MM-YYYY HH:mm"
      ),
    };
    if (form.instalador_id === "") {
      setForm((prev) => ({ ...prev, instalador_id_error: "RequiredField" }));
    } else {
      dispatch(addTaskAssign(formTest));
    }
  };
  const closeModal = () => {
    props.setModal(false);
    dispatch(
      getTask({
        start: props.paginator.start,
        end: props.paginator.end,
        status: "pending",
        loading: false,
        dateStart: props.startDate
          ? moment(props.startDate).format("DD-MM-YYYY")
          : "null",
        dateEnd: props.endDate
          ? moment(props.endDate).format("DD-MM-YYYY")
          : "null",
        patron: props.search,
        sort: props.sort,
      })
    );
    dispatch(resetAddTaskAssign());
  };

  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
      [`${event.target.name}_error`]: false,
    }));
  };

  return (
    <>
      <Modal
        show={props.modal}
        onHide={closeModal}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="AssignAnInstaller">
            <FormattedMessage id="AssignAnInstaller" />
          </Modal.Title>
        </Modal.Header>
        {state.statusAddTaskAssign === 0 && (
          <Modal.Body>
            <p className="modal_down">
              <FormattedMessage id="MassageModalTask" />
            </p>
            <h4 className="modal_icon">
              <i className="fas fa-info-circle"></i>
            </h4>
            <div>
              <Instalador
                instaladorIdError={form.instalador_id_error}
                handleChange={handleChange}
                instaladorId={form.instalador_id}
              />
            </div>
          </Modal.Body>
        )}
        {state.statusAddTaskAssign === 200 &&
          state.dataAddTaskAssign.ejecucion.estado && (
            <Modal.Body>
              <p className="modal_down">
                <FormattedMessage id="WasSuccessfullyAssigned" />
              </p>
              <h4 className="modal_icon">
                <i className="fas fa-smile"></i>
              </h4>
            </Modal.Body>
          )}
        {state.statusAddTaskAssign === 200 &&
          !state.dataAddTaskAssign.ejecucion.estado && (
            <Modal.Body>
              <p className="modal_down">
                <FormattedMessage id="WasNotAssigned" />
              </p>
              <h4 className="modal_icon">
                <i className="fas fa-sad-tear"></i>
              </h4>
            </Modal.Body>
          )}
        {state.statusAddTaskAssign === 0 && (
          <Modal.Footer>
            <Button
              disabled={state.loadingAddTaskAssign}
              variant="main"
              type="submit"
              onClick={postChange}
            >
              {state.loadingAddTaskAssign ? (
                <FormattedMessage id="Loading" />
              ) : (
                <FormattedMessage id="Send" />
              )}
            </Button>
          </Modal.Footer>
        )}
        {state.statusAddTaskAssign === 200 && (
          <Modal.Footer>
            <Button
              disabled={state.loadingAddTaskAssign}
              variant="info"
              type="submit"
              onClick={() => closeModal()}
            >
              <FormattedMessage id="Ok" />
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}

export default ModalAssing;
