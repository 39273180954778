import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import ReactCountryFlag from "react-country-flag";

function ModalUser(props) {
  let pais = localStorage.getItem("pais");
  pais = JSON.parse(pais);

  let franquicia = localStorage.getItem("franquicia");
  franquicia = JSON.parse(franquicia);

  let email = localStorage.getItem("email");

  return (
    <>
      <Modal
        onHide={() => props.setModalUser(false)}
        show={props.modal}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id={"User"} />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="modal_down">
            <strong>
              <FormattedMessage id="User" />:{" "}
            </strong>
            {email}
          </p>
          <p className="modal_down">
            <strong>
              <FormattedMessage id="Client" />:{" "}
            </strong>
            {franquicia.nombre}
          </p>
          <p className="modal_down">
            <strong>
              <FormattedMessage id="Country" />:{" "}
            </strong>
            {pais.nombre}
          </p>
          <ReactCountryFlag
            style={{
              fontSize: "2em",
              lineHeight: "2em",
            }}
            countryCode={pais.alpha2}
            svg
          />
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="info"
            type="submit"
            onClick={() => props.setModalUser(false)}
          >
            <FormattedMessage id="Ok" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalUser;
