import React, { useEffect, useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

import {
  updateListStockControl,
  resetUpdateListStockControl,
  listStockControl,
} from "../../actions/inventory";

function ModalChange(props) {
  const state = useSelector((state) => state.inventory);
  const dispatch = useDispatch();

  const [form, setForm] = useState({
    producto_id: "",
    nivel2_id: "",
    nivel2Inferior: "",
    nivel2Inferior_error: false,
    nivel2superior: "",
    nivel2superior_error: false,
    nivel3_id: "",
    nivel3Inferior: "",
    nivel3Inferior_error: false,
    nivel3superior: "",
    nivel3superior_error: false,
    nivel4_id: "",
    nivel4Inferior: "",
    nivel4Inferior_error: false,
    nivel4superior: "",
    nivel4superior_error: false,
  });

  useEffect(() => {
    const callsAPI = async () => {
      setForm({
        producto_id: props.data ? props.data.producto.id : "",
        nivel2_id: props.data ? props.data.nivel2.clasificacion_id : "",
        nivel2Inferior: props.data ? props.data.nivel2.inferior : "",
        nivel2Superior: props.data ? props.data.nivel2.superior : "",
        nivel3_id: props.data ? props.data.nivel3.clasificacion_id : "",
        nivel3Inferior: props.data ? props.data.nivel3.inferior : "",
        nivel3Superior: props.data ? props.data.nivel3.superior : "",
        nivel4_id: props.data ? props.data.nivel4.clasificacion_id : "",
        nivel4Inferior: props.data ? props.data.nivel4.inferior : "",
        nivel4Superior: props.data ? props.data.nivel4.superior : "",
      });
    };
    callsAPI();
  }, [props.data]);

  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
      [`${event.target.name}_error`]: false,
    }));
  };

  const closeModal = () => {
    props.setModal(false);
    dispatch(listStockControl({ loading: false }));
    dispatch(resetUpdateListStockControl());
  };

  const postChange = (event) => {
    event.preventDefault();
    event.persist();
    let allGood = true;
    console.log(form.nivel2Inferior);
    if (form.nivel2Inferior === "") {
      setForm((prev) => ({ ...prev, nivel2Inferior_error: "RequiredField" }));
      allGood = false;
    }
    if (form.nivel2superior === "") {
      setForm((prev) => ({ ...prev, nivel2superior_error: "RequiredField" }));
      allGood = false;
    }
    if (form.nivel3Inferior === "") {
      setForm((prev) => ({ ...prev, nivel3Inferior_error: "RequiredField" }));
      allGood = false;
    }
    if (form.nivel3superior === "") {
      setForm((prev) => ({ ...prev, nivel3superior_error: "RequiredField" }));
      allGood = false;
    }
    if (form.nivel4Inferior === "") {
      setForm((prev) => ({ ...prev, nivel4Inferior_error: "RequiredField" }));
      allGood = false;
    }
    if (form.nivel4superior === "") {
      setForm((prev) => ({ ...prev, nivel4superior_error: "RequiredField" }));
      allGood = false;
    }

    const obj = {
      producto_id: form.producto_id,
      nivel2: {
        clasificacion_id: parseInt(form.nivel2_id, 10),
        inferior: parseInt(form.nivel2Inferior, 10),
        superior: parseInt(form.nivel2Superior, 10),
      },
      nivel3: {
        clasificacion_id: parseInt(form.nivel3_id, 10),
        inferior: parseInt(form.nivel3Inferior, 10),
        superior: parseInt(form.nivel3Superior, 10),
      },
      nivel4: {
        clasificacion_id: parseInt(form.nivel4_id, 10),
        inferior: parseInt(form.nivel4Inferior, 10),
        superior: parseInt(form.nivel4Superior, 10),
      },
    };
    console.log(obj);
    if (allGood) {
      dispatch(updateListStockControl(obj));
    }
  };

  return (
    <>
      <Modal
        onHide={() => props.setModal(false)}
        show={props.modal}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="RangeControl" />
          </Modal.Title>
        </Modal.Header>

        {state.statusUpdateListStockControl === 0 && (
          <Modal.Body>
            <div className="module--form__box">
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="Critical" />{" "}
                  <FormattedMessage id="Lower" />
                  {form.nivel2Inferior_error && (
                    <span className="text-error">
                      {" "}
                      * ( <FormattedMessage id={form.nivel2Inferior_error} />)
                    </span>
                  )}
                </Form.Label>
                <Form.Control
                  defaultValue={form.nivel2Inferior}
                  onChange={handleChange}
                  type="number"
                  name="nivel2Inferior"
                />
              </Form.Group>
            </div>
            <div className="module--form__box">
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="Critical" />{" "}
                  <FormattedMessage id="Upper" />
                  {form.nivel2Superior_error && (
                    <span className="text-error">
                      {" "}
                      * ( <FormattedMessage id={form.nivel2Superior_error} />)
                    </span>
                  )}
                </Form.Label>
                <Form.Control
                  defaultValue={form.nivel2Superior}
                  onChange={handleChange}
                  type="number"
                  name="nivel2Superior"
                />
              </Form.Group>
            </div>
            <div className="module--form__box">
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="InStock" />{" "}
                  <FormattedMessage id="Lower" />
                  {form.nivel3Inferior_error && (
                    <span className="text-error">
                      {" "}
                      * ( <FormattedMessage id={form.nivel3Inferior_error} />)
                    </span>
                  )}
                </Form.Label>
                <Form.Control
                  defaultValue={form.nivel3Inferior}
                  onChange={handleChange}
                  type="number"
                  name="nivel3Inferior"
                />
              </Form.Group>
            </div>
            <div className="module--form__box">
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="InStock" />{" "}
                  <FormattedMessage id="Lower" />
                  {form.nivel3Superior_error && (
                    <span className="text-error">
                      {" "}
                      * ( <FormattedMessage id={form.nivel3Superior_error} />)
                    </span>
                  )}
                </Form.Label>
                <Form.Control
                  defaultValue={form.nivel3Superior}
                  onChange={handleChange}
                  type="number"
                  name="nivel3Superior"
                />
              </Form.Group>
            </div>
            <div className="module--form__box">
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="Available" />{" "}
                  <FormattedMessage id="Lower" />
                  {form.nivel4Inferior_error && (
                    <span className="text-error">
                      {" "}
                      * ( <FormattedMessage id={form.nivel4Inferior_error} />)
                    </span>
                  )}
                </Form.Label>
                <Form.Control
                  defaultValue={form.nivel4Inferior}
                  onChange={handleChange}
                  type="number"
                  name="nivel4Inferior"
                />
              </Form.Group>
            </div>
            <div className="module--form__box">
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="Available" />{" "}
                  <FormattedMessage id="Lower" />
                  {form.nivel4Superior_error && (
                    <span className="text-error">
                      {" "}
                      * ( <FormattedMessage id={form.nivel4Superior_error} />)
                    </span>
                  )}
                </Form.Label>
                <Form.Control
                  defaultValue={form.nivel4Superior}
                  onChange={handleChange}
                  type="number"
                  name="nivel4Superior"
                />
              </Form.Group>
            </div>
          </Modal.Body>
        )}

        {state.statusUpdateListStockControl === 200 &&
          state.dataUpdateListStockControl.ejecucion.estado && (
            <Modal.Body>
              <p className="modal_down">
                <FormattedMessage id="WasSuccessfullyUpdated" />
              </p>
              <h4 className="modal_icon">
                <i className="fas fa-smile"></i>
              </h4>
            </Modal.Body>
          )}

        {state.statusUpdateListStockControl === 200 &&
          !state.dataUpdateListStockControl.ejecucion.estado && (
            <Modal.Body>
              <p className="modal_down">
                <FormattedMessage id="WasNotUpdated" />
              </p>
              <h4 className="modal_icon">
                <i className="fas fa-sad-tear"></i>
              </h4>
            </Modal.Body>
          )}

        {state.statusUpdateListStockControl === 0 && (
          <Modal.Footer>
            <Button
              disabled={state.loadingUpdateListStockControl}
              variant="main"
              type="submit"
              onClick={postChange}
            >
              {state.loadingUpdateListStockControl ? (
                <FormattedMessage id="Loading" />
              ) : (
                <FormattedMessage id="Send" />
              )}
            </Button>
          </Modal.Footer>
        )}
        {state.statusUpdateListStockControl === 200 && (
          <Modal.Footer>
            <Button
              disabled={state.loadingUpdateListStockControl}
              variant="info"
              type="submit"
              onClick={() => closeModal()}
            >
              <FormattedMessage id="Ok" />
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}

export default ModalChange;
