const initialState = {
  data: {},
  loading: true,
  status: 0,
  dataAddStock: {},
  loadingAddStock: false,
  statusAddStock: 0,
  dataMoveStock: {},
  loadingMoveStock: false,
  statusMoveStock: 0,
};

export default initialState;
