import {
  UPDATE_CLIENT_INIT,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILURE,
  RESET_UPDATE_CLIENT,
} from "./types";
import API from "./api";

export function resetUpdateClient() {
  return {
    type: RESET_UPDATE_CLIENT,
  };
}

/* Get UpdateClient (Async) */

function updateClientSuccess(data) {
  return {
    type: UPDATE_CLIENT_SUCCESS,
    payload: data,
  };
}

function updateClientFailure(error) {
  console.log(error);
  return {
    type: UPDATE_CLIENT_FAILURE,
    payload: error,
  };
}

function updateClientInit() {
  return {
    type: UPDATE_CLIENT_INIT,
  };
}

export function updateClient(data) {
  return async (dispatch) => {
    dispatch(updateClientInit());
    try {
      const resp = await API.data.updateClient(data);
      return dispatch(updateClientSuccess(resp));
    } catch (error) {
      return dispatch(updateClientFailure(error));
    }
  };
}
