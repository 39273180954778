export const RESET_MOVEMENTS = "RESET_MOVEMENTS";

/* (ASYNC) */
export const GET_MOVEMENTS_INIT = "GET_MOVEMENTS_INIT";
export const GET_MOVEMENTS_SUCCESS = "GET_MOVEMENTS_SUCCESS";
export const GET_MOVEMENTS_FAILURE = "GET_MOVEMENTS_FAILURE";

export const RESET_CONTROL = "RESET_CONTROL";

/* (ASYNC) */
export const GET_CONTROL_INIT = "GET_CONTROL_INIT";
export const GET_CONTROL_SUCCESS = "GET_CONTROL_SUCCESS";
export const GET_CONTROL_FAILURE = "GET_CONTROL_FAILURE";

export const RESET_ADD_CANTIDAD = "RESET_ADD_CANTIDAD";

export const ADD_CANTIDAD_INIT = "ADD_CANTIDAD_INIT";
export const ADD_CANTIDAD_SUCCESS = "ADD_CANTIDAD_SUCCESS";
export const ADD_CANTIDAD_FAILURE = "ADD_CANTIDAD_FAILURE";

export const RESET_DELETE_PIEZA = "RESET_DELETE_PIEZA";

export const DELETE_PIEZA_INIT = "DELETE_PIEZA_INIT";
export const DELETE_PIEZA_SUCCESS = "DELETE_PIEZA_SUCCESS";
export const DELETE_PIEZA_FAILURE = "DELETE_PIEZA_FAILURE";

export const RESET_DESACTIVAR_PIEZA = "RESET_DESACTIVAR_PIEZA";

export const DESACTIVAR_PIEZA_INIT = "DESACTIVAR_PIEZA_INIT";
export const DESACTIVAR_PIEZA_SUCCESS = "DESACTIVAR_PIEZA_SUCCESS";
export const DESACTIVAR_PIEZA_FAILURE = "DELETE_PIEZA_FAILURE";
