import initialState from "./initialState";
import {
  GET_INSTALLER_INIT,
  GET_INSTALLER_SUCCESS,
  GET_INSTALLER_FAILURE,
  RESET_INSTALLER,
  UPDATE_STATUS_INSTALLER_INIT,
  UPDATE_STATUS_INSTALLER_SUCCESS,
  UPDATE_STATUS_INSTALLER_FAILURE,
  RESET_UPDATE_STATUS_INSTALLER,
} from "../../actions/installer/types";

export default function installer(state = initialState, action) {
  switch (action.type) {
    /* Setting */
    case RESET_INSTALLER:
      return {
        ...state,
        dataInstaller: {},
        loadingInstaller: true,
        statusInstaller: 0,
      };
    case GET_INSTALLER_INIT:
      return {
        ...state,
        loadingInstaller: action.payload,
      };
    case GET_INSTALLER_SUCCESS:
      return {
        ...state,
        dataInstaller: action.payload.data,
        loadingInstaller: false,
        statusInstaller: action.payload.status,
      };
    case GET_INSTALLER_FAILURE:
      return {
        ...state,
        dataInstaller: {},
        loadingInstaller: false,
        statusInstaller: 501,
      };
    case RESET_UPDATE_STATUS_INSTALLER:
      return {
        ...state,
        dataUpdateStatusInstaller: {},
        loadingUpdateStatusInstaller: false,
        statusUpdateStatusInstaller: 0,
      };
    case UPDATE_STATUS_INSTALLER_INIT:
      return {
        ...state,
        loadingUpdateStatusInstaller: true,
      };
    case UPDATE_STATUS_INSTALLER_SUCCESS:
      return {
        ...state,
        dataUpdateStatusInstaller: action.payload.data,
        loadingUpdateStatusInstaller: false,
        statusUpdateStatusInstaller: action.payload.status,
      };
    case UPDATE_STATUS_INSTALLER_FAILURE:
      return {
        ...state,
        dataUpdateStatusInstaller: {},
        loadingUpdateStatusInstaller: false,
        statusUpdateStatusInstaller: 501,
      };

    default:
      return state;
  }
}
