import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { FormattedMessage } from "react-intl";

const status = (id) => {
  let textStatus = "Initial";
  switch (id) {
    case 1:
      textStatus = "Created";
      break;
    case 2:
      textStatus = "Assigned";
      break;
    case 3:
      textStatus = "Rejected";
      break;
    case 4:
      textStatus = "Accepted";
      break;
    case 5:
      textStatus = "Executed";
      break;
    case 6:
      textStatus = "Failed";
      break;
    case 7:
      textStatus = "Finished";
      break;
    default:
      textStatus = "Finished";
  }
  return textStatus;
};

const Task = (props) => {
  return (
    <Draggable draggableId={props.id} index={props.index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`module-board--task state-${props.data.tarea_estado.estado.id}`}
        >
          <h3>{props.data.cliente}</h3>
          <h4>
            {props.data.username_instalador} -{" "}
            <FormattedMessage id={status(props.data.tarea_estado.estado.id)} />
          </h4>
          <span
            className="drag-view-task"
            onClick={() =>
              props.handleModalTask({
                show: true,
                data: props.data,
              })
            }
          >
            <i className="fas fa-eye"></i>
          </span>
          <span
            className="drag-view-date"
            onClick={() =>
              props.handleModalUpdate({
                show: true,
                data: props.data,
              })
            }
          >
            <i className="fas fa-calendar-week"></i>
          </span>
        </div>
      )}
    </Draggable>
  );
};

export default Task;
