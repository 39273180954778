export const RESET_WARE_HOUSE = "RESET_WARE_HOUSE";

/* (ASYNC) */
export const GET_WARE_HOUSE_INIT = "GET_WARE_HOUSE_INIT";
export const GET_WARE_HOUSE_SUCCESS = "GET_WARE_HOUSE_SUCCESS";
export const GET_WARE_HOUSE_FAILURE = "GET_WARE_HOUSE_FAILURE";

export const RESET_ADD_STOCK = "RESET_ADD_STOCK";

export const ADD_STOCK_INIT = "ADD_STOCK_INIT";
export const ADD_STOCK_SUCCESS = "ADD_STOCK_SUCCESS";
export const ADD_STOCK_FAILURE = "ADD_STOCK_FAILURE";

export const RESET_MOVE_STOCK = "RESET_MOVE_STOCK";

export const MOVE_STOCK_INIT = "MOVE_STOCK_INIT";
export const MOVE_STOCK_SUCCESS = "MOVE_STOCK_SUCCESS";
export const MOVE_STOCK_FAILURE = "MOVE_STOCK_FAILURE";
