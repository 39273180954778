import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";

import {
  resetUpdateStatusClient,
  updateStatusClient,
  getClient,
} from "../../actions/clients";

function ModalStatus(props) {
  const state = useSelector((state) => state.clients);
  const dispatch = useDispatch();

  const postChange = () => {
    dispatch(updateStatusClient(props.data));
  };

  const closeModal = () => {
    props.setModal(false);
    dispatch(getClient({ loading: false, version: 2 }));
    dispatch(resetUpdateStatusClient());
  };

  return (
    <>
      <Modal
        onHide={() => props.setModal(false)}
        show={props.modal}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Status" />
          </Modal.Title>
        </Modal.Header>

        {state.statusUpdateStatusClient === 0 && (
          <Modal.Body>
            <FormattedMessage id="AreYouSure" />
          </Modal.Body>
        )}

        {state.statusUpdateStatusClient === 200 &&
          state.dataUpdateStatusClient.ejecucion.estado && (
            <Modal.Body>
              <p className="modal_down">
                <FormattedMessage id="WasSuccessfullyUpdated" />
              </p>
              <h4 className="modal_icon">
                <i className="fas fa-smile"></i>
              </h4>
            </Modal.Body>
          )}

        {state.statusUpdateStatusClient === 200 &&
          !state.dataUpdateStatusClient.ejecucion.estado && (
            <Modal.Body>
              <p className="modal_down">
                <FormattedMessage id="WasNotUpdated" />
              </p>
              <h4 className="modal_icon">
                <i className="fas fa-sad-tear"></i>
              </h4>
            </Modal.Body>
          )}

        {state.statusUpdateStatusClient === 0 && (
          <Modal.Footer>
            <Button
              disabled={state.loadingUpdateStatusClient}
              variant="info"
              type="submit"
              onClick={() => closeModal()}
            >
              <FormattedMessage id="Cancel" />
            </Button>
            <Button
              disabled={state.loadingUpdateStatusClient}
              variant="main"
              type="submit"
              onClick={() => postChange()}
            >
              {state.loadingUpdateStatusClient ? (
                <FormattedMessage id="Loading" />
              ) : (
                <FormattedMessage id="Update" />
              )}
            </Button>
          </Modal.Footer>
        )}
        {state.statusUpdateStatusClient === 200 && (
          <Modal.Footer>
            <Button
              disabled={state.loadingUpdateStatusClient}
              variant="info"
              type="submit"
              onClick={() => closeModal()}
            >
              <FormattedMessage id="Ok" />
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}

export default ModalStatus;
