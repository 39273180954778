import React from "react";
import { FormattedMessage } from "react-intl";

const MyButtons = (props) => {
  const fixSize = () => {
    props.setShowView(props.showView ? false : true);
    localStorage.setItem("planner", props.showView ? false : true);
  };
  return (
    <div className="module-planner--board-title">
      <div>
        <h1>
          <FormattedMessage id="Planner" />
        </h1>
      </div>
      <div className="module-planner--board-button">
        <FormattedMessage id={props.showView ? "NormalView" : "SmallView"}>
          {(message) => (
            <button onClick={() => fixSize()} className="right">
              {message}
            </button>
          )}
        </FormattedMessage>

        <FormattedMessage id={props.showTask ? "Filter" : "AddTask"}>
          {(message) => (
            <button
              onClick={() => props.setShowTask(props.showTask ? false : true)}
              className="right"
            >
              {message}
            </button>
          )}
        </FormattedMessage>
        <button
          onClick={() => props.lastWeek(props.valueCalendar[0])}
          className="right"
        >
          <i className="fas fa-arrow-left"></i>
        </button>
        <button onClick={() => props.nextWeek(props.valueCalendar[0])}>
          <i className="fas fa-arrow-right"></i>
        </button>
      </div>
    </div>
  );
};

export default MyButtons;
