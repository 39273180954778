import React from "react";

import Breadcrumbs from "../../components/create_supervisor/breadcrumbs";
import Form from "../../components/create_supervisor/form";
import { FormattedMessage } from "react-intl";

const CreateSupervisor = (props) => {
  let roles = localStorage.getItem("roles");
  roles = JSON.parse(roles);

  if (!roles.includes("esAdmin")) {
    return (
      <div className="module-message">
        <h1>
          <FormattedMessage id="YouCannotCreateSupervisor" />
        </h1>
      </div>
    );
  }
  return (
    <div>
      <Breadcrumbs />
      <Form history={props.history} />
    </div>
  );
};

export default CreateSupervisor;
