import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import { updatePlanner, resetPlannerUpdate } from "../../actions/planner";

function ModalUpdateError(props) {
  const dispatch = useDispatch();

  const [form, setForm] = useState({
    turno: "",
    turno_error: false,
  });

  const [startDate, setStartDate] = useState(null);

  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
      [`${event.target.name}_error`]: false,
    }));
  };

  useEffect(() => {
    if (props.data) {
      setStartDate(
        moment(props.data.inicio.substring(0, 10), "DD-MM-YYYY").toDate()
      );
    }
  }, [props.data]);

  const postChange = (event) => {
    event.preventDefault();
    event.persist();

    let allGood = true;
    if (form.turno === "") {
      setForm((prev) => ({ ...prev, turno_error: "RequiredField" }));
      allGood = false;
    }

    const obj = {
      tarea_id: props.data.tarea_id,
      timestamp: props.data.timestamp,
      fecha: moment(startDate).format("DD-MM-YYYY"),
      tramo_horario_id: parseInt(form.turno, 10),
    };

    if (allGood) {
      dispatch(updatePlanner(obj));
    }
  };

  const closeModal = () => {
    props.handleModalUpdate({
      show: false,
      data: false,
    });
    if (props.statusPlanner === 200) {
      props.closeModalUpdateError();
      dispatch(resetPlannerUpdate());
    }
  };

  return (
    <>
      <Modal
        onHide={closeModal}
        show={props.modal}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Update" />
          </Modal.Title>
        </Modal.Header>

        {props.myStatus === 200 && props.statusPlanner === 0 && (
          <Modal.Body>
            <div className="module--form__box">
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="Schedule" />
                  {form.turno_error && (
                    <span className="text-error">
                      {" "}
                      * ( <FormattedMessage id={form.turno_error} />)
                    </span>
                  )}
                </Form.Label>
                <Form.Control
                  as="select"
                  value={form.turno}
                  onChange={handleChange}
                  type="text"
                  name="turno"
                >
                  <FormattedMessage id="Selected">
                    {(message) => <option value="">{message}</option>}
                  </FormattedMessage>
                  {props.myData.datos.marco.tramos.map((item) => (
                    <option key={item.id} value={item.id}>
                      {`${item.comienza} - ${item.termina}`}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </div>
            <div className="module--form__box">
              <Form.Group controlId="code">
                <Form.Label>
                  <FormattedMessage id="DateStart" />
                  {form.startDate_error && (
                    <span className="text-error">
                      {" "}
                      * ( <FormattedMessage id={form.startDate_error} />)
                    </span>
                  )}
                </Form.Label>
                <DatePicker
                  className="dateInput"
                  dateFormat="dd-MM-yyyy"
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                />
              </Form.Group>
            </div>
          </Modal.Body>
        )}

        {props.statusPlanner === 200 && props.dataPlanner.ejecucion.estado && (
          <Modal.Body>
            <p className="modal_down">
              <FormattedMessage id="WasSuccessfullyUpdated" />
            </p>
            <h4 className="modal_icon">
              <i className="fas fa-smile"></i>
            </h4>
          </Modal.Body>
        )}

        {!props.statusPlanner === 200 && props.dataPlanner.ejecucion.estado && (
          <Modal.Body>
            <p className="modal_down">
              <FormattedMessage id="WasNotUpdated" />
            </p>
            <h4 className="modal_icon">
              <i className="fas fa-sad-tear"></i>
            </h4>
          </Modal.Body>
        )}

        {props.statusPlanner === 0 && (
          <Modal.Footer>
            <Button
              disabled={props.loadingPlanner}
              variant="main"
              type="submit"
              onClick={postChange}
            >
              {props.loadingPlanner ? (
                <FormattedMessage id="Loading" />
              ) : (
                <FormattedMessage id="Update" />
              )}
            </Button>
          </Modal.Footer>
        )}

        {props.statusPlanner === 200 && (
          <Modal.Footer>
            <Button variant="info" type="submit" onClick={closeModal}>
              <FormattedMessage id="Ok" />
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}

export default ModalUpdateError;
