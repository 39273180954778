import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

/* Actions */
import { getSupervisor, resetSupervisor } from "../../actions/supervisor";
import { getInstaller, resetInstaller } from "../../actions/installer";
import {
  getPlanner,
  resetPlanner,
  resetPlannerUpdate,
} from "../../actions/planner";

/* Components */
import Modal from "../../components/common/modal";
import MyCalendar from "../../components/planner/calendar";
import MyButtons from "../../components/planner/buttons";
import Supervisor from "../../components/planner/supervisor";
import Installer from "../../components/planner/installer";
import Estados from "../../components/planner/estados";
import Header from "../../components/planner/header";
import Board from "../../components/planner/board";
import ModalUpdateError from "../../components/planner/modalUpdateError";
import ModalUpdateDate from "../../components/planner/modalUpdateDate";
import ModalInfo from "../../components/planner/modalInfo";
import Form from "../../components/create_task/form";

const Planner = (props) => {
  const stateSupervisor = useSelector((state) => state.supervisor);
  const stateInstalador = useSelector((state) => state.installer);
  const statePlanner = useSelector((state) => state.planner);

  const [modalUpdateError, setModalUpdateError] = useState(true);
  const [modalInfo, setModalInfo] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [dataTask, setDataTask] = useState(false);
  const [supervisores, setSupervisores] = useState([]);
  const [instalador, setInstalador] = useState([]);
  const [estados, setEstados] = useState([1, 2, 3, 4, 5, 6, 7]);
  const [search, setSearch] = useState("");
  const [showTask, setShowTask] = useState(false);

  let planner = localStorage.getItem("planner");
  planner = planner === "true";

  const [showView, setShowView] = useState(planner);

  const dispatch = useDispatch();

  /* ----------  Planner  ----------l*/
  /* ----------  Planner  ----------l*/
  /* ----------  Planner  ----------l*/
  /* ----------  Planner  ----------l*/

  useEffect(() => {
    const callsAPI = async () => {
      dispatch(
        getPlanner({
          loading: true,
          supervisores: [],
          instalador: [],
          search: "",
          estados: [1, 2, 3, 4, 5, 6, 7],
          fecha: moment().format("DD-MM-YYYY"),
        })
      );
    };
    callsAPI();
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetPlanner());
    };
  }, [dispatch]);

  /* ----------  Calendar week control  ----------l*/
  /* ----------  Calendar week control  ----------l*/
  /* ----------  Calendar week control  ----------l*/
  /* ----------  Calendar week control  ----------l*/

  const selectWeeks = (myDate) => {
    const today = myDate;

    today.addDays = function (days) {
      let date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };

    let start, end;
    switch (today.getDay()) {
      case 0:
        start = today.addDays(-6);
        end = today.addDays(0);
        break;
      case 1:
        start = today.addDays(0);
        end = today.addDays(6);
        break;
      case 2:
        start = today.addDays(-1);
        end = today.addDays(5);
        break;
      case 3:
        start = today.addDays(-2);
        end = today.addDays(4);
        break;
      case 4:
        start = today.addDays(-3);
        end = today.addDays(3);
        break;
      case 5:
        start = today.addDays(-4);
        end = today.addDays(2);
        break;
      case 6:
        start = today.addDays(-5);
        end = today.addDays(1);
        break;
      default:
    }

    return { start, end };
  };

  let dates = selectWeeks(new Date());
  const [valueCalendar, setValueCalendar] = useState([dates.start, dates.end]);

  const changeWeek = (selectDay) => {
    let dates = selectWeeks(selectDay);

    dispatch(
      getPlanner({
        loading: true,
        supervisores,
        instalador,
        estados,
        search,
        fecha: moment(dates.start).format("DD-MM-YYYY"),
      })
    );
    setValueCalendar([dates.start, dates.end]);
  };

  const nextWeek = (selectDay) => {
    selectDay.addDays = function (days) {
      let date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
    const next = selectDay.addDays(7);
    let dates = selectWeeks(next);

    dispatch(
      getPlanner({
        loading: true,
        supervisores,
        instalador,
        estados,
        search,
        fecha: moment(dates.start).format("DD-MM-YYYY"),
      })
    );

    setValueCalendar([dates.start, dates.end]);
  };

  const lastWeek = (selectDay) => {
    selectDay.addDays = function (days) {
      let date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
    const next = selectDay.addDays(-7);
    let dates = selectWeeks(next);

    dispatch(
      getPlanner({
        loading: true,
        supervisores,
        instalador,
        estados,
        search,
        fecha: moment(dates.start).format("DD-MM-YYYY"),
      })
    );

    setValueCalendar([dates.start, dates.end]);
  };

  /* ---------- End Calendar week control  ----------l*/
  /* ---------- End Calendar week control  ----------l*/
  /* ---------- End Calendar week control  ----------l*/
  /* ---------- End Calendar week control  ----------l*/

  /* ----------  Supervisor  ----------l*/
  /* ----------  Supervisor  ----------l*/
  /* ----------  Supervisor  ----------l*/
  /* ----------  Supervisor  ----------l*/
  const [count, setCount] = useState(0);

  useEffect(() => {
    const callsAPI = async () => {
      dispatch(getSupervisor({ loading: true, version: 2 }));
    };
    callsAPI();
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetSupervisor());
    };
  }, [dispatch]);

  const onChangeSupervisor = (id) => {
    const arr = supervisores;
    const index = arr.indexOf(id);
    if (index > -1) {
      arr.splice(index, 1);
    } else {
      arr.push(id);
    }

    dispatch(
      getPlanner({
        loading: true,
        supervisores: arr,
        instalador,
        estados,
        search,
        fecha: moment(valueCalendar[0]).format("DD-MM-YYYY"),
      })
    );
    setSupervisores([...arr]);
    setCount(count + 1);
  };

  /* ----------  Instalador  ----------l*/
  /* ----------  Instalador  ----------l*/
  /* ----------  Instalador  ----------l*/
  /* ----------  Instalador  ----------l*/

  useEffect(() => {
    const callsAPI = async () => {
      dispatch(getInstaller({ loading: true, version: 2 }));
    };
    callsAPI();
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetInstaller());
    };
  }, [dispatch]);

  const onChangeInstalador = (id) => {
    const arr = instalador;
    const index = arr.indexOf(id);
    if (index > -1) {
      arr.splice(index, 1);
    } else {
      arr.push(id);
    }
    dispatch(
      getPlanner({
        loading: true,
        supervisores,
        instalador: arr,
        estados,
        search,
        fecha: moment(valueCalendar[0]).format("DD-MM-YYYY"),
      })
    );
    setInstalador([...arr]);
    setCount(count + 1);
  };

  /* ----------  Estados  ----------l*/
  /* ----------  Estados  ----------l*/
  /* ----------  Estados  ----------l*/
  /* ----------  Estados  ----------l*/

  const onChangeEstados = (id) => {
    const arr = estados;
    const index = arr.indexOf(id);
    if (index > -1) {
      arr.splice(index, 1);
    } else {
      arr.push(id);
    }
    dispatch(
      getPlanner({
        loading: true,
        supervisores,
        instalador,
        estados: arr,
        search,
        fecha: moment(valueCalendar[0]).format("DD-MM-YYYY"),
      })
    );
    setEstados([...arr]);
    setCount(count + 1);
  };

  /* ----------  Search  ----------l*/
  /* ----------  Search  ----------l*/
  /* ----------  Search  ----------l*/
  /* ----------  Search  ----------l*/
  const handleSearch = async (event) => {
    event.preventDefault();
    event.persist();
    dispatch(
      getPlanner({
        loading: true,
        supervisores,
        instalador,
        estados,
        search: event.target.value,
        fecha: moment(valueCalendar[0]).format("DD-MM-YYYY"),
      })
    );
    setSearch(event.target.value);
  };

  /* ----------  Modal  Task ----------l*/
  /* ----------  Modal  Task ----------l*/
  /* ----------  Modal  Task ----------l*/
  /* ----------  Modal  Task ----------l*/

  const handleModalTask = async (obj) => {
    setModalInfo(obj.show);
    setDataTask(obj.data);
  };

  const handleModalUpdate = async (obj) => {
    setModalUpdate(obj.show);
    setDataTask(obj.data);
  };

  /* ----------  Modal  ----------l*/
  /* ----------  Modal  ----------l*/
  /* ----------  Modal  ----------l*/
  /* ----------  Modal  ----------l*/

  const closeModalUpdateError = async () => {
    setModalUpdateError(true);
    dispatch(
      getPlanner({
        loading: true,
        supervisores,
        instalador,
        estados,
        search: search,
        fecha: moment(valueCalendar[0]).format("DD-MM-YYYY"),
      })
    );
    setShowTask(false);
    dispatch(resetPlannerUpdate());
  };

  if (
    statePlanner.statusPlannerUpdate === 401 ||
    statePlanner.statusPlannerUpdate === 501
  ) {
    return (
      <ModalUpdateError
        mensaje={false}
        closeModalUpdateError={closeModalUpdateError}
        modal={modalUpdateError}
      />
    );
  } else if (statePlanner.statusPlannerUpdate === 200) {
    if (!statePlanner.dataPlannerUpdate.ejecucion.estado) {
      return (
        <ModalUpdateError
          mensaje={statePlanner.dataPlannerUpdate}
          closeModalUpdateError={closeModalUpdateError}
          modal={modalUpdateError}
        />
      );
    }
  }

  if (
    stateSupervisor.statusSupervisor === 401 ||
    stateSupervisor.statusSupervisor === 501 ||
    stateInstalador.statusInstaller === 401 ||
    stateInstalador.statusInstaller === 501 ||
    statePlanner.statusPlanner === 401 ||
    statePlanner.statusPlanner === 501
  ) {
    return <Modal history={props.history} state={true} />;
  }

  if (stateSupervisor.loadingSupervisor || stateInstalador.loadingInstaller) {
    return (
      <div className="module--loading">
        <i className="fas fa-cog fa-spin"></i>
      </div>
    );
  }

  return (
    <div className="module-planner">
      <Header search={search} handleSearch={handleSearch} />
      <div className="module-planner--board">
        <MyButtons
          lastWeek={lastWeek}
          nextWeek={nextWeek}
          valueCalendar={valueCalendar}
          setShowTask={setShowTask}
          showTask={showTask}
          setShowView={setShowView}
          showView={showView}
        />
        <Board
          showView={showView}
          handleModalTask={handleModalTask}
          handleModalUpdate={handleModalUpdate}
          statusPlanner={statePlanner.statusPlannerUpdate}
          dataPlanner={statePlanner.dataPlannerUpdate}
          loading={statePlanner.loadingPlanner}
          status={statePlanner.statusPlanner}
          data={statePlanner.dataPlanner}
        />
      </div>
      <div className="module-planner--calendar">
        {showTask && (
          <Form closeModalUpdateError={closeModalUpdateError} planner={true} />
        )}
        {!showTask && (
          <React.Fragment>
            <MyCalendar
              changeWeek={changeWeek}
              valueCalendar={valueCalendar}
              setValueCalendar={setValueCalendar}
            />
            <Installer
              onChangeInstalador={onChangeInstalador}
              instalador={instalador}
              setInstalador={setInstalador}
              data={stateInstalador.dataInstaller}
            />
            <Supervisor
              onChangeSupervisor={onChangeSupervisor}
              supervisores={supervisores}
              setSupervisores={setSupervisores}
              data={stateSupervisor.dataSupervisor}
            />
            <Estados onChangeEstados={onChangeEstados} estados={estados} />
          </React.Fragment>
        )}
      </div>
      <ModalInfo
        data={dataTask}
        handleModalTask={handleModalTask}
        modal={modalInfo}
      />
      <ModalUpdateDate
        closeModalUpdateError={closeModalUpdateError}
        data={dataTask}
        handleModalUpdate={handleModalUpdate}
        modal={modalUpdate}
        statusPlanner={statePlanner.statusPlannerUpdate}
        dataPlanner={statePlanner.dataPlannerUpdate}
        loadingPlanner={statePlanner.loadingPlannerUpdate}
        myLoading={statePlanner.loadingPlanner}
        myStatus={statePlanner.statusPlanner}
        myData={statePlanner.dataPlanner}
      />
    </div>
  );
};

export default Planner;
