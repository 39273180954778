import React from "react";

const MyBoard = (props) => {
  return (
    <React.Fragment>
      <div className="module-board--without-day"></div>
      {props.data.map((item) => {
        return (
          <div key={item.label} className="module-board--with-day">
            <h4>{item.label.substring(0, 3)}</h4>
            <h5>{item.numero_dia_en_mes}</h5>
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default MyBoard;
