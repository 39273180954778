import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import ModalChange from "./modal";
import Paginator from "./paginator";

const MyTable = (props) => {
  const [modal, setModal] = useState(false);
  const [data, setData] = useState(false);

  let filter = props.data.datos.filter((o) => {
    return (
      o.producto.nombre.toLowerCase().indexOf(props.search.toLowerCase()) > -1
    );
  });

  const arr = filter.slice(props.paginator.start, props.paginator.end);

  return (
    <div className="box-table">
      <Table striped hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>
              <FormattedMessage id="Name" />
            </th>
            <th>
              <FormattedMessage id="Critical" />
            </th>
            <th>
              <FormattedMessage id="InStock" />
            </th>
            <th>
              <FormattedMessage id="Available" />
            </th>
            <th className="center">
              <FormattedMessage id="Action" />
            </th>
          </tr>
        </thead>
        <tbody>
          {arr.map((item, i) => (
            <tr key={i}>
              <td>{item.producto.id}</td>
              <td>{item.producto.nombre}</td>
              <td>
                <p className="mini xl">{item.nivel2.nivel_nombre}</p>
                <p className="mini xl">
                  <FormattedMessage id="Lower" /> = {item.nivel2.inferior}
                </p>
                <p className="mini xl">
                  <FormattedMessage id="Upper" /> = {item.nivel2.superior}
                </p>
              </td>
              <td>
                <p className="mini xl">{item.nivel3.nivel_nombre}</p>
                <p className="mini xl">
                  <FormattedMessage id="Lower" /> = {item.nivel3.inferior}
                </p>
                <p className="mini xl">
                  <FormattedMessage id="Upper" /> = {item.nivel3.superior}
                </p>
              </td>
              <td>
                <p className="mini xl">{item.nivel4.nivel_nombre}</p>
                <p className="mini xl">
                  <FormattedMessage id="Lower" /> = {item.nivel4.inferior}
                </p>
                <p className="mini xl">
                  <FormattedMessage id="Upper" /> = {item.nivel4.superior}
                </p>
              </td>
              <td className="center">
                <span href="#ver" className="table-button--normal buttons">
                  <i className="fas fa-ellipsis-h"></i>
                  <div className="table-button--normal--option">
                    <ul>
                      <li>
                        <span
                          onClick={() => {
                            setData(item);
                            setModal(true);
                          }}
                        >
                          <FormattedMessage id="Update" />
                        </span>
                      </li>
                    </ul>
                  </div>
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Paginator
        total={filter.length}
        handlePaginator={props.handlePaginator}
        paginator={props.paginator}
        data={props.dataCount}
      />
      <ModalChange data={data} modal={modal} setModal={setModal} />
    </div>
  );
};

export default MyTable;
