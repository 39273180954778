import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";

import { addClient, resetAddClient } from "../../actions/create_client";

function ModalClient(props) {
  const state = useSelector((state) => state.createClient);
  const dispatch = useDispatch();

  const closeModal = () => {
    props.setModal(false);
    dispatch(resetAddClient());
    if (state.statusAddClient === 200) {
      if (state.dataAddClient.ejecucion.estado) {
        props.history.push("/dashboard/clients");
      }
    }
  };

  const sendPostAddClient = () => {
    let pais = localStorage.getItem("pais");
    pais = JSON.parse(pais);

    let franquicia = localStorage.getItem("franquicia");
    franquicia = JSON.parse(franquicia);

    const obj = {
      pais_id: pais.id,
      division_id: parseInt(props.form.division_id, 10),
      franquicia_id: franquicia.id,
      subdivision_id: parseInt(props.form.subdivision_id, 10),
      modelo: "sitio" /*cliente|sitio|bodega*/,
      calle_numero: props.form.calle_numero,
      nombre: props.form.nombre,
      lugar: props.form.lugar,
      ciudad: props.form.ciudad,
      uid: props.form.uid,
      codigo_postal: props.form.codigo_postal,
      latitud: null,
      longitud: null,
      email: props.form.email,
      telefono: props.form.phone,
      lenguaje: props.form.language,
      password: props.form.password,
      activo: true,
    };

    dispatch(addClient(obj));
  };

  return (
    <>
      <Modal
        onHide={() => closeModal()}
        show={props.modal}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            <FormattedMessage id="AddClient" />
          </Modal.Title>
        </Modal.Header>
        {state.statusAddClient === 0 && (
          <Modal.Body>
            <p className="modal_down">
              <FormattedMessage id="AreYouSure" />
            </p>
            <h4 className="modal_icon">
              <i className="fas fa-info-circle"></i>
            </h4>
          </Modal.Body>
        )}
        {state.statusAddClient === 200 && state.dataAddClient.ejecucion.estado && (
          <Modal.Body>
            <p className="modal_down">
              <FormattedMessage id="WasSuccessfullyAdded" />
            </p>
            <h4 className="modal_icon">
              <i className="fas fa-smile"></i>
            </h4>
          </Modal.Body>
        )}
        {state.statusAddClient === 200 &&
          !state.dataAddClient.ejecucion.estado && (
            <Modal.Body>
              <p className="modal_down">
                <FormattedMessage id="WasNotAdded" />
              </p>
              <h4 className="modal_icon">
                <i className="fas fa-sad-tear"></i>
              </h4>
            </Modal.Body>
          )}
        {state.statusAddClient === 0 && (
          <Modal.Footer>
            <Button
              disabled={state.loadingAddClient}
              variant="info"
              type="submit"
              onClick={() => closeModal()}
            >
              <FormattedMessage id="Cancel" />
            </Button>
            <Button
              disabled={state.loadingAddClient}
              variant="main"
              type="submit"
              onClick={() => sendPostAddClient()}
            >
              {state.loadingAddClient ? (
                <FormattedMessage id="Loading" />
              ) : (
                <FormattedMessage id="Add" />
              )}
            </Button>
          </Modal.Footer>
        )}
        {state.statusAddClient === 200 && (
          <Modal.Footer>
            <Button
              disabled={state.loadingAddClient}
              variant="info"
              type="submit"
              onClick={() => closeModal()}
            >
              <FormattedMessage id="Ok" />
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}

export default ModalClient;
