import React from "react";
import { FormattedMessage } from "react-intl";
import Logo from "../../assets/images/logo-mini.png";
import Google from "../../assets/images/Google-Play.png";

import QR from "../../assets/images/qr.png";
import Container2 from "../../assets/images/banner2.png";
import Container1 from "../../assets/images/banner1.png";
import Container3 from "../../assets/images/ilustracion-container.png";

/* Components */

const Home = (props) => {
  return (
    <div className="module-home">
      <div className="module-home--text">
        <section>
          <div className="module-home--text--banner">
            <div className="container-box2">
              <img src={Container2} alt="banner-2" />
            </div>
            <div className="container-box3">
              <h1>
                <FormattedMessage id="Welcome" />
              </h1>
            </div>
            <div className="big-container">
              <div>
                <img className="logo--google" src={Container1} alt="banner-1" />
              </div>
            </div>
          </div>
          <section className="container-box1">
            <div className="home-box1">
              <p>
                Sunt in culpa qui officia deserunt mollit anim id est eopksio
                laborum. Sed ut perspiciatis unde omnis istpoe natus error sit
                voluptatem accusantium doloremque eopsloi laudantium, totam rem
                aperiam.
              </p>
            </div>
            <div className="home-box1">
              <p>
                Sunt in culpa qui officia deserunt mollit anim id est eopksio
                laborum. Sed ut perspiciatis unde omnis istpoe natus error sit
                voluptatem accusantium doloremque eopsloi laudantium, totam rem
                aperiam.
              </p>
            </div>
          </section>
        </section>
      </div>
      <div className="module-home--img">
        <div>
          <img className="qr" src={QR} alt="my codigo qr" />
        </div>
        <div className="my--app">
          <div>
            <img className="logo--mini" src={Logo} alt="logo" />
          </div>
          <div>
            <img className="logo--google" src={Google} alt="google banner" />
          </div>
        </div>
        <div>
          <p>
            <FormattedMessage id="HomeQA" />
          </p>
        </div>
        <img className="logo--container" src={Container3} alt="logo" />
      </div>
    </div>
  );
};

export default Home;
