import {
  GET_MOVEMENTS_INIT,
  GET_MOVEMENTS_SUCCESS,
  GET_MOVEMENTS_FAILURE,
  RESET_MOVEMENTS,
  GET_CONTROL_INIT,
  GET_CONTROL_SUCCESS,
  GET_CONTROL_FAILURE,
  RESET_CONTROL,
  ADD_CANTIDAD_INIT,
  ADD_CANTIDAD_SUCCESS,
  ADD_CANTIDAD_FAILURE,
  RESET_ADD_CANTIDAD,
  DELETE_PIEZA_INIT,
  DELETE_PIEZA_SUCCESS,
  DELETE_PIEZA_FAILURE,
  RESET_DELETE_PIEZA,
  DESACTIVAR_PIEZA_INIT,
  DESACTIVAR_PIEZA_SUCCESS,
  DESACTIVAR_PIEZA_FAILURE,
  RESET_DESACTIVAR_PIEZA,
} from "./types";
import API from "./api";

/* Actions Creators */

export function resetMovements() {
  return {
    type: RESET_MOVEMENTS,
  };
}

/* Get Movements (Async) */

function getMovementsSuccess(data) {
  return {
    type: GET_MOVEMENTS_SUCCESS,
    payload: data,
  };
}

function getMovementsFailure(error) {
  console.log(error);
  return {
    type: GET_MOVEMENTS_FAILURE,
    payload: error,
  };
}

function getMovementsInit(data) {
  return {
    type: GET_MOVEMENTS_INIT,
    payload: data.loading,
  };
}

export function getMovements(data) {
  return async (dispatch) => {
    dispatch(getMovementsInit(data));
    try {
      const resp = await API.data.getMovements(data);
      return dispatch(getMovementsSuccess(resp));
    } catch (error) {
      return dispatch(getMovementsFailure(error));
    }
  };
}

export function resetControl() {
  return {
    type: RESET_CONTROL,
  };
}

/* Get Movements (Async) */

function getControlSuccess(data) {
  return {
    type: GET_CONTROL_SUCCESS,
    payload: data,
  };
}

function getControlFailure(error) {
  console.log(error);
  return {
    type: GET_CONTROL_FAILURE,
    payload: error,
  };
}

function getControlInit(data) {
  return {
    type: GET_CONTROL_INIT,
    payload: data.loading,
  };
}

export function getControl(data) {
  return async (dispatch) => {
    dispatch(getControlInit(data));
    try {
      const resp = await API.data.getControl(data);
      return dispatch(getControlSuccess(resp));
    } catch (error) {
      return dispatch(getControlFailure(error));
    }
  };
}

export function resetAddCantidad() {
  return {
    type: RESET_ADD_CANTIDAD,
  };
}

/* Get AddCantidad (Async) */

function addCantidadSuccess(data) {
  return {
    type: ADD_CANTIDAD_SUCCESS,
    payload: data,
  };
}

function addCantidadFailure(error) {
  return {
    type: ADD_CANTIDAD_FAILURE,
    payload: error,
  };
}

function addCantidadInit(data) {
  return {
    type: ADD_CANTIDAD_INIT,
  };
}

export function addCantidad(data) {
  return async (dispatch) => {
    dispatch(addCantidadInit());
    try {
      const resp = await API.data.addCantidad(data);
      return dispatch(addCantidadSuccess(resp));
    } catch (error) {
      return dispatch(addCantidadFailure(error));
    }
  };
}

export function resetDeletePieza() {
  return {
    type: RESET_DELETE_PIEZA,
  };
}

/* Get delete pieza (Async) */

function deletePiezaSuccess(data) {
  return {
    type: DELETE_PIEZA_SUCCESS,
    payload: data,
  };
}

function deletePiezaFailure(error) {
  return {
    type: DELETE_PIEZA_FAILURE,
    payload: error,
  };
}

function deletePiezaInit(data) {
  return {
    type: DELETE_PIEZA_INIT,
  };
}

export function deletePieza(data) {
  return async (dispatch) => {
    dispatch(deletePiezaInit());
    try {
      const resp = await API.data.deletePieza(data);
      return dispatch(deletePiezaSuccess(resp));
    } catch (error) {
      return dispatch(deletePiezaFailure(error));
    }
  };
}

export function resetDesactivarPieza() {
  return {
    type: RESET_DESACTIVAR_PIEZA,
  };
}

/* Get delete pieza (Async) */

function desactivarPiezaSuccess(data) {
  return {
    type: DESACTIVAR_PIEZA_SUCCESS,
    payload: data,
  };
}

function desactivarPiezaFailure(error) {
  return {
    type: DESACTIVAR_PIEZA_FAILURE,
    payload: error,
  };
}

function desactivarPiezaInit(data) {
  return {
    type: DESACTIVAR_PIEZA_INIT,
  };
}

export function desactivarPieza(data) {
  return async (dispatch) => {
    dispatch(desactivarPiezaInit());
    try {
      const resp = await API.data.desactivarPieza(data);
      return dispatch(desactivarPiezaSuccess(resp));
    } catch (error) {
      return dispatch(desactivarPiezaFailure(error));
    }
  };
}
