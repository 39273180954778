import initialState from "./initialState";
import {
  ADD_INSTALLER_INIT,
  ADD_INSTALLER_SUCCESS,
  ADD_INSTALLER_FAILURE,
  RESET_ADD_INSTALLER,
} from "../../actions/create_installer/types";

export default function installer(state = initialState, action) {
  switch (action.type) {
    /* Setting */
    case RESET_ADD_INSTALLER:
      return {
        ...state,
        dataAddInstaller: {},
        loadingAddInstaller: false,
        statusAddInstaller: 0,
      };
    case ADD_INSTALLER_INIT:
      return {
        ...state,
        loadingAddInstaller: true,
      };
    case ADD_INSTALLER_SUCCESS:
      return {
        ...state,
        dataAddInstaller: action.payload.data,
        loadingAddInstaller: false,
        statusAddInstaller: action.payload.status,
      };
    case ADD_INSTALLER_FAILURE:
      return {
        ...state,
        dataAddInstaller: {},
        loadingAddInstaller: false,
        statusAddInstaller: 501,
      };

    default:
      return state;
  }
}
