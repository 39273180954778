import React from "react";

import { Table } from "react-bootstrap";

import { FormattedMessage } from "react-intl";

/* import Modal from "./modal"; */

const MyTable = (props) => {
  /*   const [modal, setModal] = useState(false); */

  return (
    <div className="box-table">
      {/*   <Modal data={data} modal={modal} setModal={setModal} /> */}
      <Table striped hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>
              <FormattedMessage id="Name" />
            </th>
            <th>
              <FormattedMessage id="Installer" />
            </th>
            <th>
              <FormattedMessage id="Date" />
            </th>
            <th>
              <FormattedMessage id="Email" />
            </th>
            <th>
              <FormattedMessage id="Description" />
            </th>
            <th>
              <FormattedMessage id="Quantity" />
            </th>
            <th className="center">
              <FormattedMessage id="Code" />
            </th>
            <th className="center">
              <FormattedMessage id="Supervisor" />
            </th>
          </tr>
        </thead>
        <tbody>
          {props.data.datos.map((item, i) => (
            <tr key={i}>
              <td>{item.bodega_id}</td>
              <td>{item.bodega_nombre}</td>
              <td>{item.instalador_nombre}</td>
              <td>{item.fecha_movimiento}</td>
              <td>{item.instalador_email}</td>
              <td>{item.descripcion_movimiento}</td>
              <td>{item.cantidad_movida}</td>
              <td>{item.pieza_codigo}</td>
              <td>{item.supervisor_nombre}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default MyTable;
