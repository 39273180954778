import React, { useEffect } from "react";

const Tramo = (props) => {
  useEffect(() => {
    const tramo = document.getElementById(`tramo-${props.focus}`);

    if (tramo) {
      window.scroll({
        top: tramo.offsetTop,
        behavior: "smooth",
      });
    }
  }, [props.focus]);

  return (
    <div className="module-board--horario-container">
      {props.data.map((item) => {
        return (
          <div
            key={item.id}
            id={`tramo-${item.id}`}
            className="module-board--horario"
          >
            <h5>{item.comienza}</h5>
          </div>
        );
      })}
    </div>
  );
};

export default Tramo;
