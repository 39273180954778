import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import Cliente from "../filters/filtersClient";
import Division from "../filters/filtersDivision";
import HoursStart from "../filters/filtersHoursStart";
import HoursEnd from "../filters/filtersHoursEnd";
import SubDivision from "../filters/filtersSubDivision";

import { setIdClient } from "../../actions/filters";
import {
  setIdDivision,
  addAddress,
  resetAddAddress,
} from "../../actions/clients";
import { getFilterSitio } from "../../actions/filters";
import { getGlosaAddress } from "../../actions//inventory";

function ModalAddress(props) {
  const [form, setForm] = useState({
    cliente_id: props.clienteId,
    cliente_id_error: false,
    division_id: "",
    division_id_error: false,
    subdivision_id: "",
    subdivision_id_error: false,
    calle_numero: "",
    calle_numero_error: false,
    ciudad: "",
    ciudad_error: false,
    lugar: "",
    lugar_error: false,
    codigo_postal: "",
    codigo_postal_error: false,
    nombre: "",
    nombre_error: false,
    hours_start_id: "17",
    hours_start_id_error: false,
    hours_end_id: "36",
    hours_end_id_error: false,
  });

  const [myHoursStart, setMyHoursStart] = useState("08:00");
  const [myHoursEnd, setMyHoursEnd] = useState("18:00");

  const stateInventory = useSelector((state) => state.inventory);
  const state = useSelector((state) => state.clients);

  const dispatch = useDispatch();

  useEffect(() => {
    const callsAPI = async () => {
      setForm((prev) => ({
        ...prev,
        cliente_id: props.clienteId,
      }));
    };
    callsAPI();
  }, [props.clienteId]);

  useEffect(() => {
    const callsAPI = async () => {
      dispatch(getGlosaAddress({ loading: true }));
    };
    callsAPI();
  }, [dispatch, props.clienteId]);

  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
      [`${event.target.name}_error`]: false,
    }));
    if (event.target.name === "cliente_id") {
      props.setClientId(event.target.value);
      dispatch(setIdClient(event.target.value));
    }
    if (event.target.name === "division_id") {
      dispatch(setIdDivision(event.target.value));
    }
    if (event.target.name === "hours_start_id") {
      let hoursStart =
        event.target.options[event.target.selectedIndex].dataset.start;
      setMyHoursStart(hoursStart);
    }
    if (event.target.name === "hours_end_id") {
      let hoursEnd =
        event.target.options[event.target.selectedIndex].dataset.end;
      setMyHoursEnd(hoursEnd);
    }
  };

  const closeModal = () => {
    if (state.statusAddAddress === 200) {
      if (state.dataAddAddress.ejecucion.estado) {
        dispatch(getFilterSitio({ id_cliente: form.cliente_id }));
        props.setSitioId(state.dataAddAddress.datos.sitio.id);
        setForm((prev) => ({
          ...prev,
          cliente_id_error: false,
          division_id: "",
          division_id_error: false,
          subdivision_id: "",
          subdivision_id_error: false,
          calle_numero: "",
          calle_numero_error: false,
          ciudad: "",
          ciudad_error: false,
          lugar: "",
          lugar_error: false,
          codigo_postal: "",
          codigo_postal_error: false,
          nombre: "",
          nombre_error: false,
          hours_start_id: "17",
          hours_start_id_error: false,
          hours_end_id: "36",
          hours_end_id_error: false,
        }));
      }
    }
    dispatch(resetAddAddress());
    props.setModal(false);
  };

  const postChange = (event) => {
    event.preventDefault();
    event.persist();
    let allGood = true;

    if (form.cliente_id === "") {
      setForm((prev) => ({ ...prev, cliente_id_error: "RequiredField" }));
      allGood = false;
    }
    if (form.division_id === "") {
      setForm((prev) => ({ ...prev, division_id_error: "RequiredField" }));
      allGood = false;
    }
    if (form.subdivision_id === "") {
      setForm((prev) => ({ ...prev, subdivision_id_error: "RequiredField" }));
      allGood = false;
    }
    if (form.calle_numero === "") {
      setForm((prev) => ({ ...prev, calle_numero_error: "RequiredField" }));
      allGood = false;
    }
    if (form.ciudad === "") {
      setForm((prev) => ({ ...prev, ciudad_error: "RequiredField" }));
      allGood = false;
    }
    if (form.lugar === "") {
      setForm((prev) => ({ ...prev, lugar_error: "RequiredField" }));
      allGood = false;
    }
    if (form.nombre === "") {
      setForm((prev) => ({ ...prev, nombre_error: "RequiredField" }));
      allGood = false;
    }

    let pais = localStorage.getItem("pais");
    pais = JSON.parse(pais);

    const obj = {
      pais_id: pais.id,
      division_id: parseInt(form.division_id, 10),
      subdivision_id: parseInt(form.subdivision_id, 10),
      modelo: "sitio" /*cliente|sitio|bodega*/,
      calle_numero: form.calle_numero,
      nombre: form.nombre,
      lugar: form.lugar,
      ciudad: form.ciudad,
      codigo_postal: form.codigo_postal,
      latitud: null,
      longitud: null,
      cliente_id: form.cliente_id,
      tramo_horario_id_comienzo: parseInt(form.hours_start_id, 10),
      tramo_horario_id_termina: parseInt(form.hours_end_id, 10),
    };

    if (allGood) {
      props.handleHours({
        hoursStart: myHoursStart,
        hoursEnd: myHoursEnd,
      });
      dispatch(addAddress(obj));
    }
  };

  return (
    <>
      <Modal
        onHide={() => props.setModal(false)}
        show={props.modal}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="NewSitio" />
          </Modal.Title>
        </Modal.Header>

        {state.statusAddAddress === 0 && (
          <Modal.Body>
            {stateInventory.statusGlosaAddress === 200 && (
              <React.Fragment>
                <Cliente
                  handleChange={handleChange}
                  clienteIdError={form.cliente_id_error}
                  clienteId={form.cliente_id}
                />
                <Division
                  label={stateInventory.dataGlosaAddress.datos.label_divisiones}
                  handleChange={handleChange}
                  divisionIdError={form.division_id_error}
                  divisionId={form.division_id}
                />
                <SubDivision
                  label={
                    stateInventory.dataGlosaAddress.datos.label_subdivisiones
                  }
                  handleChange={handleChange}
                  subDivisionIdError={form.subdivision_id_error}
                  subDivisionId={form.subdivision_id}
                />
                <div className="module--form__box">
                  <Form.Group>
                    <Form.Label>
                      <FormattedMessage id="StreetAndNumber" />
                      {form.calle_numero_error && (
                        <span className="text-error">
                          {" "}
                          * ( <FormattedMessage id={form.calle_numero_error} />)
                        </span>
                      )}
                    </Form.Label>
                    <Form.Control
                      defaultValue={form.calle_numero}
                      onChange={handleChange}
                      type="text"
                      name="calle_numero"
                    />
                  </Form.Group>
                </div>

                <div className="module--form__box">
                  <Form.Group>
                    <Form.Label>
                      <FormattedMessage id="City" />
                      {form.ciudad_error && (
                        <span className="text-error">
                          {" "}
                          * ( <FormattedMessage id={form.ciudad_error} />)
                        </span>
                      )}
                    </Form.Label>
                    <Form.Control
                      defaultValue={form.ciudad}
                      onChange={handleChange}
                      type="text"
                      name="ciudad"
                    />
                  </Form.Group>
                </div>

                <div className="module--form__box">
                  <Form.Group>
                    <Form.Label>
                      <FormattedMessage id="InstallationPlace" />
                      {form.lugar_error && (
                        <span className="text-error">
                          {" "}
                          * ( <FormattedMessage id={form.lugar_error} />)
                        </span>
                      )}
                    </Form.Label>
                    <Form.Control
                      defaultValue={form.lugar}
                      onChange={handleChange}
                      type="text"
                      name="lugar"
                    />
                  </Form.Group>
                </div>

                <div className="module--form__box">
                  <Form.Group>
                    <Form.Label>
                      <FormattedMessage id="Name" />
                      {form.nombre_error && (
                        <span className="text-error">
                          {" "}
                          * ( <FormattedMessage id={form.nombre_error} />)
                        </span>
                      )}
                    </Form.Label>
                    <Form.Control
                      defaultValue={form.nombre}
                      onChange={handleChange}
                      type="text"
                      name="nombre"
                    />
                  </Form.Group>
                </div>

                <div className="module--form__box">
                  <Form.Group>
                    <Form.Label>
                      <FormattedMessage id="PostCode" />
                      {form.codigo_postal_error && (
                        <span className="text-error">
                          * ( <FormattedMessage id={form.codigo_postal_error} />
                          )
                        </span>
                      )}
                    </Form.Label>
                    <Form.Control
                      defaultValue={form.codigo_postal}
                      onChange={handleChange}
                      type="text"
                      name="codigo_postal"
                    />
                  </Form.Group>
                </div>
                <HoursStart
                  handleChange={handleChange}
                  hoursStartIdError={form.hours_start_id_error}
                  hoursStartId={form.hours_start_id}
                />
                <HoursEnd
                  handleChange={handleChange}
                  hoursEndIdError={form.hours_end_id_error}
                  hoursEndId={form.hours_end_id}
                />
              </React.Fragment>
            )}
          </Modal.Body>
        )}

        {state.statusAddAddress === 200 &&
          state.dataAddAddress.ejecucion.estado && (
            <Modal.Body>
              <p className="modal_down">
                <FormattedMessage id="TheAddressWasSuccessfullyAdded" />
              </p>
              <h4 className="modal_icon">
                <i className="fas fa-smile"></i>
              </h4>
            </Modal.Body>
          )}

        {state.statusAddAddress === 200 &&
          !state.dataAddAddress.ejecucion.estado && (
            <Modal.Body>
              <p className="modal_down">
                <FormattedMessage id="TheAddressWasNotAdded" />
              </p>
              <h4 className="modal_icon">
                <i className="fas fa-sad-tear"></i>
              </h4>
            </Modal.Body>
          )}

        {state.statusAddAddress === 0 && (
          <Modal.Footer>
            <Button
              disabled={state.loadingAddAddress}
              variant="main"
              type="submit"
              onClick={postChange}
            >
              {state.loadingAddAddress ? (
                <FormattedMessage id="Loading" />
              ) : (
                <FormattedMessage id="Send" />
              )}
            </Button>
          </Modal.Footer>
        )}
        {state.statusAddAddress === 200 && (
          <Modal.Footer>
            <Button
              disabled={state.loadingAddAddress}
              variant="info"
              type="submit"
              onClick={() => closeModal()}
            >
              <FormattedMessage id="Ok" />
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}

export default ModalAddress;
