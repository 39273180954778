import initialState from "./initialState";
import {
  UPDATE_INSTALLER_INIT,
  UPDATE_INSTALLER_SUCCESS,
  UPDATE_INSTALLER_FAILURE,
  RESET_UPDATE_INSTALLER,
} from "../../actions/update_installer/types";

export default function installer(state = initialState, action) {
  switch (action.type) {
    case RESET_UPDATE_INSTALLER:
      return {
        ...state,
        dataUpdateInstaller: {},
        loadingUpdateInstaller: false,
        statusUpdateInstaller: 0,
      };
    case UPDATE_INSTALLER_INIT:
      return {
        ...state,
        loadingUpdateInstaller: true,
      };
    case UPDATE_INSTALLER_SUCCESS:
      return {
        ...state,
        dataUpdateInstaller: action.payload.data,
        loadingUpdateInstaller: false,
        statusUpdateInstaller: action.payload.status,
      };
    case UPDATE_INSTALLER_FAILURE:
      return {
        ...state,
        dataUpdateInstaller: {},
        loadingUpdateInstaller: false,
        statusUpdateInstaller: 501,
      };

    default:
      return state;
  }
}
