export const RESET_SUPERVISOR = "RESET_SUPERVISOR";

/* (ASYNC) */
export const GET_SUPERVISOR_INIT = "GET_SUPERVISOR_INIT";
export const GET_SUPERVISOR_SUCCESS = "GET_SUPERVISOR_SUCCESS";
export const GET_SUPERVISOR_FAILURE = "GET_SUPERVISOR_FAILURE";

export const RESET_UPDATE_STATUS_SUPERVISOR = "RESET_UPDATE_STATUS_SUPERVISOR";

/* (ASYNC) */
export const UPDATE_STATUS_SUPERVISOR_INIT = "UPDATE_STATUS_SUPERVISOR_INIT";
export const UPDATE_STATUS_SUPERVISOR_SUCCESS =
  "UPDATE_STATUS_SUPERVISOR_SUCCESS";
export const UPDATE_STATUS_SUPERVISOR_FAILURE =
  "UPDATE_STATUS_SUPERVISOR_FAILURE";
