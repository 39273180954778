import React from "react";

import Form from "../../components/create_task/form";

const CreateTask = (props) => {
  return (
    <div>
      <Form history={props.history} />
    </div>
  );
};

export default CreateTask;
