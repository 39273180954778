const initialState = {
  dataClient: {},
  loadingClient: true,
  statusClient: 0,
  dataDivision: {},
  loadingDivision: false,
  statusDivision: 0,
  dataSubDivision: {},
  loadingSubDivision: false,
  statusSubDivision: 0,
  dataAddAddress: {},
  loadingAddAddress: false,
  statusAddAddress: 0,
  id_division: "",
  dataUpdateStatusClient: {},
  loadingUpdateStatusClient: false,
  statusUpdateStatusClient: 0,
};

export default initialState;
