import {
  UPDATE_INSTALLER_INIT,
  UPDATE_INSTALLER_SUCCESS,
  UPDATE_INSTALLER_FAILURE,
  RESET_UPDATE_INSTALLER,
} from "./types";
import API from "./api";

export function resetUpdateInstaller() {
  return {
    type: RESET_UPDATE_INSTALLER,
  };
}

/* Get UpdateInstaller (Async) */

function updateInstallerSuccess(data) {
  return {
    type: UPDATE_INSTALLER_SUCCESS,
    payload: data,
  };
}

function updateInstallerFailure(error) {
  console.log(error);
  return {
    type: UPDATE_INSTALLER_FAILURE,
    payload: error,
  };
}

function updateInstallerInit() {
  return {
    type: UPDATE_INSTALLER_INIT,
  };
}

export function updateInstaller(data) {
  return async (dispatch) => {
    dispatch(updateInstallerInit());
    try {
      const resp = await API.data.updateInstaller(data);
      return dispatch(updateInstallerSuccess(resp));
    } catch (error) {
      return dispatch(updateInstallerFailure(error));
    }
  };
}
