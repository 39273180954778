import {
  GET_INSTALLER_INIT,
  GET_INSTALLER_SUCCESS,
  GET_INSTALLER_FAILURE,
  RESET_INSTALLER,
  UPDATE_STATUS_INSTALLER_INIT,
  UPDATE_STATUS_INSTALLER_SUCCESS,
  UPDATE_STATUS_INSTALLER_FAILURE,
  RESET_UPDATE_STATUS_INSTALLER,
} from "./types";
import API from "./api";

/* Actions Creators */

export function resetInstaller() {
  return {
    type: RESET_INSTALLER,
  };
}

/* Get Installer (Async) */

function getInstallerSuccess(data) {
  return {
    type: GET_INSTALLER_SUCCESS,
    payload: data,
  };
}

function getInstallerFailure(error) {
  console.log(error);
  return {
    type: GET_INSTALLER_FAILURE,
    payload: error,
  };
}

function getInstallerInit(data) {
  return {
    type: GET_INSTALLER_INIT,
    payload: data.loading,
  };
}

export function getInstaller(data) {
  return async (dispatch) => {
    dispatch(getInstallerInit(data));
    try {
      const resp = await API.data.getInstaller(data);
      return dispatch(getInstallerSuccess(resp));
    } catch (error) {
      return dispatch(getInstallerFailure(error));
    }
  };
}

export function resetUpdateStatusInstaller() {
  return {
    type: RESET_UPDATE_STATUS_INSTALLER,
  };
}

/* Get UpdateStatusInstaller (Async) */

function updateStatusInstallerSuccess(data) {
  return {
    type: UPDATE_STATUS_INSTALLER_SUCCESS,
    payload: data,
  };
}

function updateStatusInstallerFailure(error) {
  console.log(error);
  return {
    type: UPDATE_STATUS_INSTALLER_FAILURE,
    payload: error,
  };
}

function updateStatusInstallerInit() {
  return {
    type: UPDATE_STATUS_INSTALLER_INIT,
  };
}

export function updateStatusInstaller(data) {
  return async (dispatch) => {
    dispatch(updateStatusInstallerInit());
    try {
      const resp = await API.data.updateStatusInstaller(data);
      return dispatch(updateStatusInstallerSuccess(resp));
    } catch (error) {
      return dispatch(updateStatusInstallerFailure(error));
    }
  };
}
