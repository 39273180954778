import React from "react";
import Calendar from "react-calendar";
import { FormattedMessage } from "react-intl";
import "react-calendar/dist/Calendar.css";

const MyCalendarComponent = (props) => {
  return (
    <div>
      <FormattedMessage id="Leng">
        {(msg) => (
          <Calendar
            locale={`${msg}`}
            showFixedNumberOfWeeks={true}
            onChange={props.changeWeek}
            value={props.valueCalendar}
          />
        )}
      </FormattedMessage>
    </div>
  );
};

export default MyCalendarComponent;
