import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import Modal from "./modalAdd";
import ModalCodes from "./modalCodes";
import ModalMove from "./modalMove";
import Paginator from "./paginator";

const MyTable = (props) => {
  const [modalCodes, setModalCodes] = useState(false);
  const [modalMove, setModalMove] = useState(false);
  const [modal, setModal] = useState(false);
  const [data, setData] = useState(false);

  const critico = (obj) => {
    const result = obj.valor;
    if (result === 2) {
      return "state-red big";
    } else if (result === 3) {
      return "state-yellow big";
    } else if (result === 4) {
      return "state-green big";
    }
    return "state-gray big";
  };

  let filter = props.data.datos.filter((o) => {
    return (
      o.producto.nombre.toLowerCase().indexOf(props.search.toLowerCase()) >
        -1 ||
      o.producto.categoria.glosa
        .toLowerCase()
        .indexOf(props.search.toLowerCase()) > -1
    );
  });

  const arr = filter.slice(props.paginator.start, props.paginator.end);

  return (
    <div className="box-table">
      <Modal id={props.id} data={data} modal={modal} setModal={setModal} />
      <ModalCodes data={data} modal={modalCodes} setModal={setModalCodes} />
      <ModalMove
        id={props.id}
        data={data}
        modal={modalMove}
        setModal={setModalMove}
      />
      <Table striped hover>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="Name" />
            </th>
            <th>
              <FormattedMessage id="Category" />
            </th>
            <th>
              <FormattedMessage id="Type" />
            </th>
            <th>
              <FormattedMessage id="Quantity" />
            </th>
            <th>
              <FormattedMessage id="Critical" />
            </th>

            <th className="center">
              <FormattedMessage id="Action" />
            </th>
          </tr>
        </thead>
        <tbody>
          {arr.map((item, i) => (
            <tr key={i}>
              <td>{item.producto.nombre}</td>
              <td>{item.producto.categoria.glosa}</td>
              <td>
                {item.producto.dimension ? item.producto.dimension.glosa : ""}
              </td>
              <td>
                <span
                  className={critico({
                    valor: item.nivel.id,
                  })}
                >
                  {`${item.nivel.nombre} ${item.cantidad}`}
                </span>
              </td>
              <td>{item.critico}</td>
              <td className="center">
                <span href="#ver" className="table-button--normal buttons">
                  <i className="fas fa-ellipsis-h"></i>
                  <div className="table-button--normal--option">
                    <ul>
                      <li>
                        <span
                          onClick={() => {
                            setModal(true);
                            setData(item);
                          }}
                        >
                          <FormattedMessage id="Add" />
                        </span>
                      </li>
                      <li>
                        <span
                          onClick={() => {
                            setModalMove(true);
                            setData(item);
                          }}
                        >
                          <FormattedMessage id="Transfer" />
                        </span>
                      </li>
                      {item.piezas.length > 0 && (
                        <li>
                          <span
                            onClick={() => {
                              setModalCodes(true);
                              setData(item);
                            }}
                          >
                            <FormattedMessage id="Codes" />
                          </span>
                        </li>
                      )}
                    </ul>
                  </div>
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Paginator
        total={filter.length}
        handlePaginator={props.handlePaginator}
        paginator={props.paginator}
        data={props.dataCount}
      />
    </div>
  );
};

export default MyTable;
