const initialState = {
  data: {},
  loading: true,
  status: 0,
  dataGlosaAddress: {},
  loadingGlosaAddress: false,
  statusGlosaAddress: 0,
  dataListStockControl: {},
  loadingListStockControl: true,
  statusListStockControl: 0,
  dataUpdateListStockControl: {},
  loadingUpdateListStockControl: false,
  statusUpdateListStockControl: 0,
  dataProductsFilter: {},
  loadingProductsFilter: false,
  statusProductsFilter: 0,
};

export default initialState;
