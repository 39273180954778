export const RESET_CLIENT = "RESET_CLIENT";

/* (ASYNC) */
export const GET_CLIENT_INIT = "GET_CLIENT_INIT";
export const GET_CLIENT_SUCCESS = "GET_CLIENT_SUCCESS";
export const GET_CLIENT_FAILURE = "GET_CLIENT_FAILURE";

export const RESET_DIVISION = "RESET_DIVISION";

/* (ASYNC) */
export const GET_DIVISION_INIT = "GET_DIVISION_INIT";
export const GET_DIVISION_SUCCESS = "GET_DIVISION_SUCCESS";
export const GET_DIVISION_FAILURE = "GET_DIVISION_FAILURE";

export const RESET_SUBDIVISION = "RESET_SUBDIVISION";

/* (ASYNC) */
export const GET_SUBDIVISION_INIT = "GET_SUBDIVISION_INIT";
export const GET_SUBDIVISION_SUCCESS = "GET_SUBDIVISION_SUCCESS";
export const GET_SUBDIVISION_FAILURE = "GET_SUBDIVISION_FAILURE";

export const SET_ID_DIVISION = "SET_ID_DIVISION,";

export const RESET_ADD_ADDRESS = "RESET_ADD_ADDRESS";

export const ADD_ADDRESS_INIT = "ADD_ADDRESS_INIT";
export const ADD_ADDRESS_SUCCESS = "ADD_ADDRESS_SUCCESS";
export const ADD_ADDRESS_FAILURE = "ADD_ADDRESS_FAILURE";

export const RESET_UPDATE_STATUS_CLIENT = "RESET_UPDATE_STATUS_CLIENT";

/* (ASYNC) */
export const UPDATE_STATUS_CLIENT_INIT = "UPDATE_STATUS_CLIENT_INIT";
export const UPDATE_STATUS_CLIENT_SUCCESS = "UPDATE_STATUS_CLIENT_SUCCESS";
export const UPDATE_STATUS_CLIENT_FAILURE = "UPDATE_STATUS_CLIENT_FAILURE";
