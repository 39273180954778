export const RESET_INVENTORY = "RESET_INVENTORY";

/* (ASYNC) */
export const GET_INVENTORY_INIT = "GET_INVENTORY_INIT";
export const GET_INVENTORY_SUCCESS = "GET_INVENTORY_SUCCESS";
export const GET_INVENTORY_FAILURE = "GET_INVENTORY_FAILURE";

export const RESET_GLOSA_ADDRESS = "RESET_GLOSA_ADDRESS";

/* (ASYNC) */
export const GET_GLOSA_ADDRESS_INIT = "GET_GLOSA_ADDRESS_INIT";
export const GET_GLOSA_ADDRESS_SUCCESS = "GET_GLOSA_ADDRESS_SUCCESS";
export const GET_GLOSA_ADDRESS_FAILURE = "GET_GLOSA_ADDRESS_FAILURE";

export const RESET_LIST_STOCK_CONTROL = "RESET_LIST_STOCK_CONTROL";

export const LIST_STOCK_CONTROL_INIT = "LIST_STOCK_CONTROL_INIT";
export const LIST_STOCK_CONTROL_SUCCESS = "LIST_STOCK_CONTROL_SUCCESS";
export const LIST_STOCK_CONTROL_FAILURE = "LIST_STOCK_CONTROL_FAILURE";

export const RESET_UPDATE_LIST_STOCK_CONTROL =
  "RESET_UPDATE_LIST_STOCK_CONTROL";

export const UPDATE_LIST_STOCK_CONTROL_INIT = "UPDATE_LIST_STOCK_CONTROL_INIT";
export const UPDATE_LIST_STOCK_CONTROL_SUCCESS =
  "UPDATE_LIST_STOCK_CONTROL_SUCCESS";
export const UPDATE_LIST_STOCK_CONTROL_FAILURE =
  "UPDATE_LIST_STOCK_CONTROL_FAILURE";

export const RESET_PRODUCTS_FILTER = "RESET_PRODUCTS_FILTER";

export const GET_PRODUCTS_FILTER_INIT = "GET_PRODUCTS_FILTER_INIT";
export const GET_PRODUCTS_FILTER_SUCCESS = "GET_PRODUCTS_FILTER_SUCCESS";
export const GET_PRODUCTS_FILTER_FAILURE = "GET_PRODUCTS_FILTER_FAILURE";
