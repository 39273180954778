import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getProductsFilter } from "../../actions//inventory";
import { FormattedMessage } from "react-intl";

/* Component */
import { Form } from "react-bootstrap";

const FiltersBodegaHeader = (props) => {
  const state = useSelector((state) => state.inventory);
  const dispatch = useDispatch();
  useEffect(() => {
    const callsAPI = async () => {
      dispatch(getProductsFilter({ loading: false }));
    };
    callsAPI();
  }, [dispatch, props.bodegaId]);

  return (
    <div className="box-header left">
      <Form.Control
        as="select"
        value={props.productoId}
        onChange={props.handleChange}
        name="producto_id"
        className="input-search"
        requeried="true"
        data-number="ok"
      >
        <FormattedMessage id="SelectedProduct">
          {(message) => <option value="">{message}</option>}
        </FormattedMessage>
        {state.statusProductsFilter === 200 &&
          state.dataProductsFilter.datos.map((item) => (
            <option key={item.id} value={item.id}>
              {item.nombre}
            </option>
          ))}
      </Form.Control>
    </div>
  );
};

export default FiltersBodegaHeader;
