import React from "react";
import { FormattedMessage } from "react-intl";

const MyHeader = (props) => {
  return (
    <header className="header--search">
      <div className="box-header">
        <i className="fas fa-search"></i>
        <FormattedMessage id="Search1">
          {(msg) => (
            <input
              type="text"
              defaultValue={props.search}
              onChange={props.handleSearch}
              className="input-search"
              placeholder={msg}
            />
          )}
        </FormattedMessage>
      </div>
    </header>
  );
};

export default MyHeader;
