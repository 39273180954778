import initialState from "./initialState";
import {
  ADD_CLIENT_INIT,
  ADD_CLIENT_SUCCESS,
  ADD_CLIENT_FAILURE,
  RESET_ADD_CLIENT,
} from "../../actions/create_client/types";

export default function client(state = initialState, action) {
  switch (action.type) {
    /* Setting */
    case RESET_ADD_CLIENT:
      return {
        ...state,
        dataAddClient: {},
        loadingAddClient: false,
        statusAddClient: 0,
      };
    case ADD_CLIENT_INIT:
      return {
        ...state,
        loadingAddClient: true,
      };
    case ADD_CLIENT_SUCCESS:
      return {
        ...state,
        dataAddClient: action.payload.data,
        loadingAddClient: false,
        statusAddClient: action.payload.status,
      };
    case ADD_CLIENT_FAILURE:
      return {
        ...state,
        dataAddClient: {},
        loadingAddClient: false,
        statusAddClient: 501,
      };

    default:
      return state;
  }
}
