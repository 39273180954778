import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";

import {
  addSupervisor,
  resetAddSupervisor,
} from "../../actions/create_supervisor";

function ModalSupervisor(props) {
  const state = useSelector((state) => state.createSupervisor);
  const dispatch = useDispatch();

  const closeModal = () => {
    props.setModal(false);
    dispatch(resetAddSupervisor());
    if (state.statusAddSupervisor === 200) {
      if (state.dataAddSupervisor.ejecucion.estado) {
        props.history.push("/dashboard/supervisor");
      }
    }
  };

  const sendPostAddSupervisor = () => {
    let pais = localStorage.getItem("pais");
    pais = JSON.parse(pais);

    let franquicia = localStorage.getItem("franquicia");
    franquicia = JSON.parse(franquicia);

    const roles = [];

    if (props.form.admin === "esAdmin") {
      roles.push({
        id: 1,
        nombre: "esAdmin",
      });
    }

    const obj = {
      username: props.form.user,
      password: props.form.pass,
      names: props.form.nombre,
      last_names: props.form.apellido,
      pais_id: pais.id,
      uid: props.form.uid,
      franquicia_id: franquicia.id,
      email: props.form.email,
      telefono: props.form.phone,
      lenguaje: props.form.language,
      roles: roles,
    };
    dispatch(addSupervisor(obj));
  };

  return (
    <>
      <Modal
        onHide={() => closeModal()}
        show={props.modal}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            <FormattedMessage id="AddSupervisor" />
          </Modal.Title>
        </Modal.Header>
        {state.statusAddSupervisor === 0 && (
          <Modal.Body>
            <p className="modal_down">
              <FormattedMessage id="AreYouSure" />
            </p>
            <h4 className="modal_icon">
              <i className="fas fa-info-circle"></i>
            </h4>
          </Modal.Body>
        )}
        {state.statusAddSupervisor === 200 &&
          state.dataAddSupervisor.ejecucion.estado && (
            <Modal.Body>
              <p className="modal_down">
                <FormattedMessage id="WasSuccessfullyAdded" />
              </p>
              <h4 className="modal_icon">
                <i className="fas fa-smile"></i>
              </h4>
            </Modal.Body>
          )}
        {state.statusAddSupervisor === 200 &&
          !state.dataAddSupervisor.ejecucion.estado && (
            <Modal.Body>
              <p className="modal_down">
                <FormattedMessage id="WasNotAdded" />
              </p>
              <h4 className="modal_icon">
                <i className="fas fa-sad-tear"></i>
              </h4>
            </Modal.Body>
          )}
        {state.statusAddSupervisor === 0 && (
          <Modal.Footer>
            <Button
              disabled={state.loadingAddSupervisor}
              variant="info"
              type="submit"
              onClick={() => closeModal()}
            >
              <FormattedMessage id="Cancel" />
            </Button>
            <Button
              disabled={state.loadingAddSupervisor}
              variant="main"
              type="submit"
              onClick={() => sendPostAddSupervisor()}
            >
              {state.loadingAddSupervisor ? (
                <FormattedMessage id="Loading" />
              ) : (
                <FormattedMessage id="Add" />
              )}
            </Button>
          </Modal.Footer>
        )}
        {state.statusAddSupervisor === 200 && (
          <Modal.Footer>
            <Button
              disabled={state.loadingAddSupervisor}
              variant="info"
              type="submit"
              onClick={() => closeModal()}
            >
              <FormattedMessage id="Ok" />
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}

export default ModalSupervisor;
