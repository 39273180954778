const messages = {
  Home: "Home",
  InStock: "In stock",
  Available: "Available",
  Check: "Check",
  FormAndRegistration: "Form and registration",
  InInstaller: "In installer",
  AssignAnInstaller: "Assign an installer",
  Assign: "Assign",
  Supervisor: "Supervisor",
  RequiredField: "Required field",
  AddTask: "Add taks",
  UpdateTask: "Update taks",
  PendingTasks: "Pending tasks",
  TaskToAuthorize: "Task to authorize",
  TaskDetail: "Task detail",
  Cancel: "Cancel",
  WasSuccessfullyAdded: "It was successfully added",
  WasSuccessfullyAccepted: "It was successfully accepted",
  WasSuccessfullyUpdated: "It was successfully update",
  WasSuccessfullyAssigned: "It was successfully assigned",
  WasSuccessfullyDeleted: "It was successfully deleted",
  WasNotAdded: "It was not added",
  WasNotUpdated: "It was not updated",
  WasNotAssigned: "It was not assigned",
  WasNotDeleted: "It was not deleted",
  WasNotAccepted: "It was not accepted",
  MassageModalTask:
    "As soon as the installer downloads the task, the task can not  be modified",
  Description: "Description",
  InstallationDescription: "Installation description",
  Quantity: "Quantity",
  Products: "Products",
  Installer: "Installer",
  AddInstaller: "Add installer",
  InstallerList: "Installer list",
  GuideOrInvoice: "Guide number or invoice number",
  CreateTask: "Create task",
  DateStart: "Date start",
  DateEnd: "Date end",
  New: "New",
  Cancelled: "Cancelled",
  Process: "Process",
  InstallationFinished: "Instalation finished",
  InstallationPending: "Instalation pending",
  InstallationToAuthorize: "Instalation to autorize",
  MasterListOfCompletedInstallations: "Master list of completed installations",
  MasterListOfPendingInstallations: "Master list of pending installations",
  MasterListOfInstallationsToAuthorize:
    "Master list of installations to authorize",
  ScheduleNewInstallations: "Schedule new installations",
  Inventory: "Inventory",
  OrderID: "Order ID",
  Instalation: "Instalation",
  Setting: "Setting",
  Messages: "Messages",
  Documents: "Documents",
  Indicators: "Indicadores",
  Clients: "Clients",
  Client: "Client",
  Status: "Status",
  Moderator: "Moderator",
  Schedule: "Schedule",
  Product: "Product",
  Assigned: "Assigned",
  Rejected: "Rejected",
  Accepted: "Accepted",
  Executed: "Executed",
  Blocked: "Blocked",
  Failed: "Failed",
  Finished: "Finished",
  Created: "Created",
  Container: "Container",
  DueDate: "Due date",
  ToAuthorize: "To authorize",
  Address: "Address",
  Action: "Action",
  ToFinalize: "Finished",
  Pending: "Pending",
  Email: "Email",
  RecoverPassword: "Forget your password",
  SiteDevelopedBy: "Site developed by",
  Welcome: "Welcome",
  FifoSystem: "Fifo system",
  Password: "Password",
  WriteYourEmail: "Write your email",
  WriteYourPass: "Write your password",
  Loading: "Loading...",
  Name: "Name",
  Country: "Country",
  Countries: "Countries",
  Yes: "Yes",
  No: "No",
  NumberOfStore: "Number of avatar 1",
  Contract: "Contract",
  Edit: "Edit",
  AddAvatar: "Add avatar",
  Desactive: "Desactive",
  Active: "Active",
  Profile: "Profile",
  Logout: "Log out",
  Stores: "Stores",
  AddStore: "Add store",
  ListStores: "List stores",
  Companies: "Companies",
  AddCompany: "Add company",
  ListCompanies: "List companies",
  Options: "Options",
  Latitude: "Latitude",
  Longitude: "Longitude",
  CanAttendAfterHours: "Can attend after hours",
  Selected: "Selected",
  SelectedWarehouse: "Selected warehouse",
  SelectedProduct: "Selected producto",
  Contracts: "Contracts",
  TimeZone: "Time zone",
  Ok: "Ok",
  UserEmail: "User email",
  UserName: "User name",
  TheStoreWasAdded: "The store was added",
  TheStoreWasUpdated: "The store was updated",
  TheStoreWasDeleted: "The store was deleted",
  TheStoreWasNotAdded: "The store wasn't added",
  TheStoreWasNotUpdated: "The store wasn't updated",
  TheStoreWasNotDeleted: "The store wasn't deleted",
  Add: "Add",
  SearchStore: "Search stores",
  Search: "Search",
  Search1: "Search ...",
  Admin: "Admini",
  AfterHours: "After hours",
  AddAvatarClient: "Add avatar client",
  Avatar1IsOk: "Avatar 1 is ok",
  Avatar2IsOk: "Avatar 2 is ok",
  Avatar3IsOk: "Avatar 3 is ok",
  Avatar1: "Avatar 1",
  Avatar2: "Avatar 2",
  Avatar3: "Avatar 3",
  TheAvatar1WasAdded: "The avatar 1 was added",
  TheAvatar1WasUpdated: "The avatar 1 was updated",
  TheAvatar1WasDeleted: "The avatar 1 was deleted",
  TheAvatar1WasNotAdded: "The avatar 1 wasn't added",
  TheAvatar1WasNotUpdated: "The avatar 1 wasn't updated",
  TheAvatar1WasNotDeleted: "The avatar 1 wasn't deleted",
  TheAvatar2WasAdded: "The avatar 2 was added",
  TheAvatar2WasUpdated: "The avatar 2 was updated",
  TheAvatar2WasDeleted: "The avatar 2 was deleted",
  TheAvatar2WasNotAdded: "The avatar 2 wasn't added",
  TheAvatar2WasNotUpdated: "The avatar 2 wasn't updated",
  TheAvatar2WasNotDeleted: "The avatar 2 wasn't deleted",
  TheAvatar3WasAdded: "The avatar 3 was added",
  TheAvatar3WasUpdated: "The avatar 3 was updated",
  TheAvatar3WasDeleted: "The avatar 3 was deleted",
  TheAvatar3WasNotAdded: "The avatar 3 wasn't added",
  TheAvatar3WasNotUpdated: "The avatar 3 wasn't updated",
  TheAvatar3WasNotDeleted: "The avatar 3 wasn't deleted",
  UpdateStore: "Update store",
  Update: "Update",
  TheCompanyWasAdded: "The company was added",
  TheCompanyWasUpdated: "The company was updated",
  TheCompanyWasDeleted: "The company was deleted",
  TheCompanyWasNotAdded: "The company wasn't added",
  TheCompanyWasNotUpdated: "The company wasn't updated",
  TheCompanyWasNotDeleted: "The company wasn't deleted",
  UpdateCompany: "Update company",
  AddAvatarCompany: "Add avatar company",
  AreYouSure: "Are you sure?",
  Send: "Send",
  PleaseReconnect: "Please reconnect",
  KindOfLoad: "Kind of load",
  KindOfWeather: "Kind of weather",
  LoadStatus: "Load status",
  FileNumber: "File number",
  InsulatedProducts: "Insulated products",
  ComplementaryProducts: "Complementary products",
  ContainerNumber: "Container number",
  EmptyContainer: "Empty container",
  HalfLoadContainer: "Half load container",
  FullContainer: "Full container",
  ContainerClosedDoor: "Container closed door",
  Observations: "Observations",
  KindOfContainer: "Kind of container",
  Sign: "Sign",
  Accept: "Accept",
  Other: "Other",
  Revised: "Revised",
  YouCannotModifyTheTask: "You cannot modify the task",
  YouCannotViewTheTask: "You cannot view the task",
  View: "View",
  AddImage: "Add image",
  DeleteImage: "Delete Image",
  FieldsAreRequerided: "Fields are requerided",
  TheImageWasSuccessfullyAdded: "The image was successfully added",
  TheImageWasNotAdded: "The image was not added",
  TheImageWasSuccessfullyDeleted: "The image was successfully deleted",
  TheImageWasNotDeleted: "The image was not deleted",
  Delete: "Delete",
  DeleteTask: "Delete task",
  ForgetMyPassword: "Forgot my password",
  LogIn: "Log in",
  WrongUsernameOrPassword: "Wrong username or password",
  ThereWasAnError: "There was an error",
  NewPasswordWasSendingToYourEmail: "New password was sending to your email",
  ChangePassSuccessful: "Change pass successful",
  ChangeMyPassword: "Change my password",
  NewPass: "New pass",
  ExpiredSession: "Expired session",
  WriteYourOldPass: "Write your old password",
  WriteYourNewPass: "Write your new password",
  ChangeLanguageSuccessful: "Change language successful",
  ChangeMyLanguageword: "Change my language",
  Languages: "Languages",
  Spanish: "Spanish",
  English: "English",
  WarehouseList: "Warehouse list",
  Warehouse: "Warehouse",
  StreetAndNumber: "Street and number",
  ProductsList: "Products list",
  Critical: "Critical",
  Category: "Category",
  Codes: "Codes",
  YouCannotViewTheWareHouse: "You can not view the warehouse",
  AddProduct: "Add product",
  Code: "Code",
  CodeIsRequired: "Code is required",
  Used: "Used",
  TheProductWasSuccessfullyAdded: "The product was successfully added",
  TheProductWasSuccessfullyupdated: "The product was successfully updated",
  TheProductWasNotAdded: "The product was not added",
  TheProductWasSuccessfullyMoved: "The product was successfully moved",
  TheProductWasNotMoved: "The product was not moved",
  Transfer: "Transfer",
  MoveProduct: "Move product",
  TheFieldWasSuccessfullyUpdate: "The field was successfully update",
  TheFieldOtherWasNotUpdated: "The field other was not updated",
  HomeQA:
    "Download the E-Report application on your mobile device by scanning the QR code",
  Type: "Type",
  NewSitio: "New Address",
  City: "City",
  Place: "Place",
  InstallationPlace: "Installation place",
  WareHousePlace: "Ware houseplace",
  PostCode: "Post code",
  TheAddressWasSuccessfullyAdded: "TheAddressWasSuccessfullyAdded",
  TheAddressWasNotAdded: "The address was not added",
  ClientsList: "Clients List",
  AddClient: "Add client",
  Phone: "Phone",
  CreateClient: "Create client",
  UpdateClient: "Update client",
  Inactive: "Inactivo",
  Activate: "Activate",
  Deactivate: "Deactivate",
  User: "User",
  UpdateInstaller: "Update installer",
  CreateInstaller: "Create installer",
  LastName: "Last name",
  SupervisorList: "Supervisor list",
  AddSupervisor: "Add supervisor",
  SuperAdmin: "Super admin",
  CreateSupervisor: "Create supervisor",
  UpdateSupervisor: "Update supervisor",
  YouCannotCreateSupervisor: "You can not create supervisor",
  RangeControl: "Range control",
  Lower: "Lower",
  Upper: "Upper",
  ControlList: "Control list",
  From: "From",
  To: "To",
  StartTime: "Start time",
  EndTime: "End time",
  OutOfTime: "Out of time",
  Planner: "Planner",
  Filter: "Filter",
  NormalView: "Normal view",
  SmallView: "Small view",
  NewInstallationAddress: "New installation address",
  Leng: "en",
  Movements: "Movements",
  StockControl: "Stock control",
  YouhaveToSelectTheWarehouseAndProduct:
    "You have to select the warehouse and product",
  SeeUsedParts: "See used parts",
  NotSeeUsedParts: "Not see used parts",
  UnusedPart: "Unused part",
  UsedPart: "Used part",
  EditQuantity: "Edit quantity",
  DeletePart: "Delete part",
  DeletedPart: "Pieza eliminada",
  ThePartWasNotDeleted: "The part was not deleted",
  DisablePart: "Disable part",
  TheActionWasSuccessful: "The action was successful",
  Date: "Date",
};

export default messages;
