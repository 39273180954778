import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

function ModalUpdateError(props) {
  return (
    <>
      <Modal
        onHide={() =>
          props.handleModalTask({
            show: false,
            data: false,
          })
        }
        show={props.modal}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.data.tarea_correlativo}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="modal_down">
            <strong>
              <FormattedMessage id="Client" />:{" "}
            </strong>
            {props.data.cliente}
          </p>
          <p className="modal_down">
            <strong>
              <FormattedMessage id="Address" />:{" "}
            </strong>
            {props.data.direccion_sitio}
          </p>
          <p className="modal_down">
            <strong>
              <FormattedMessage id="Place" />:{" "}
            </strong>
            {props.data.sitio_nombre}
          </p>
          <p className="modal_down">
            <strong>
              <FormattedMessage id="Products" />:{" "}
            </strong>
            {props.data.productos}
          </p>
          <p className="modal_down">
            <strong>
              <FormattedMessage id="Installer" />:{" "}
            </strong>
            {props.data.username_instalador}
          </p>
          <p className="modal_down">
            <strong>
              <FormattedMessage id="Supervisor" />:{" "}
            </strong>
            {props.data.username_supervisor}
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="info"
            type="submit"
            onClick={() =>
              props.handleModalTask({
                show: false,
                data: false,
              })
            }
          >
            <FormattedMessage id="Ok" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalUpdateError;
