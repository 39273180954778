import {
  ADD_SUPERVISOR_INIT,
  ADD_SUPERVISOR_SUCCESS,
  ADD_SUPERVISOR_FAILURE,
  RESET_ADD_SUPERVISOR,
} from "./types";
import API from "./api";

/* Actions Creators */

export function resetAddSupervisor() {
  return {
    type: RESET_ADD_SUPERVISOR,
  };
}

/* Get AddSupervisor (Async) */

function addSupervisorSuccess(data) {
  return {
    type: ADD_SUPERVISOR_SUCCESS,
    payload: data,
  };
}

function addSupervisorFailure(error) {
  console.log(error);
  return {
    type: ADD_SUPERVISOR_FAILURE,
    payload: error,
  };
}

function addSupervisorInit() {
  return {
    type: ADD_SUPERVISOR_INIT,
  };
}

export function addSupervisor(data) {
  return async (dispatch) => {
    dispatch(addSupervisorInit());
    try {
      const resp = await API.data.addSupervisor(data);
      return dispatch(addSupervisorSuccess(resp));
    } catch (error) {
      return dispatch(addSupervisorFailure(error));
    }
  };
}
