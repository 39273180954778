const initialState = {
  dataMovements: {},
  loadingMovements: true,
  statusMovements: 0,
  dataControl: {},
  loadingControl: false,
  statusControl: 0,
  dataAddCantidad: {},
  loadingAddCantidad: false,
  statusAddCantidad: 0,
  dataDeletePieza: {},
  loadingDeletePieza: false,
  statusDeletePieza: 0,
  dataDesactivarPieza: {},
  loadingDesactivarPieza: false,
  statusDesactivarPieza: 0,
};

export default initialState;
