import React, { useState } from "react";

import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import Modal from "./modal";
import Paginator from "./paginator";

const MyTable = (props) => {
  const [modal, setModal] = useState(false);
  const [data, setData] = useState(false);

  const critico = (state) => {
    if (state) {
      return "state-green";
    }
    return "state-red";
  };

  let filter = props.data.datos.filter((o) => {
    return (
      o.username.toLowerCase().indexOf(props.search.toLowerCase()) > -1 ||
      o.names.toLowerCase().indexOf(props.search.toLowerCase()) > -1 ||
      o.last_names.toLowerCase().indexOf(props.search.toLowerCase()) > -1 ||
      o.email.toLowerCase().indexOf(props.search.toLowerCase()) > -1 ||
      o.telefono.toLowerCase().indexOf(props.search.toLowerCase()) > -1
    );
  });

  const arr = filter.slice(props.paginator.start, props.paginator.end);

  return (
    <div className="box-table">
      <Modal data={data} modal={modal} setModal={setModal} />
      <Table striped hover>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="User" />
            </th>
            <th>
              <FormattedMessage id="Name" />
            </th>
            <th>
              <FormattedMessage id="Email" />
            </th>
            <th>
              <FormattedMessage id="Phone" />
            </th>
            <th>
              <FormattedMessage id="Status" />
            </th>
            <th className="center">
              <FormattedMessage id="Action" />
            </th>
          </tr>
        </thead>
        <tbody>
          {arr.map((item, i) => (
            <tr key={i}>
              <td>{item.username}</td>
              <td>{`${item.names} ${item.last_names}`}</td>
              <td>{item.email}</td>
              <td>{item.telefono}</td>
              <td>
                <span className={critico(item.activo)}>
                  <FormattedMessage id={item.activo ? "Active" : "Inactive"} />
                </span>
              </td>
              <td className="center">
                {!item.bloqueada ? (
                  <span href="#ver" className="table-button--normal buttons">
                    <i className="fas fa-ellipsis-h"></i>
                    <div className="table-button--normal--option">
                      <ul>
                        <li>
                          <Link
                            to={{
                              pathname: `/dashboard/update_installer/${item.id}`,
                              state: {
                                items: item,
                              },
                            }}
                          >
                            <FormattedMessage id="Edit" />
                          </Link>
                        </li>
                        <li>
                          <span
                            onClick={() => {
                              setModal(true);
                              setData({
                                instalador_id: item.id,
                                activo: item.activo ? false : true,
                              });
                            }}
                          >
                            <FormattedMessage
                              id={!item.activo ? "Activate" : "Deactivate"}
                            />
                          </span>
                        </li>
                      </ul>
                    </div>
                  </span>
                ) : (
                  <span className={`state-6`}>
                    <FormattedMessage id="InInstaller" />
                  </span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Paginator
        total={filter.length}
        handlePaginator={props.handlePaginator}
        paginator={props.paginator}
        data={props.dataCount}
      />
    </div>
  );
};

export default MyTable;
