import React from "react";
import { FormattedMessage } from "react-intl";

const data = [
  {
    id: 1,
    name: "Created",
  },
  {
    id: 2,
    name: "Assigned",
  },
  {
    id: 3,
    name: "Rejected",
  },
  {
    id: 4,
    name: "Executed",
  },
  {
    id: 5,
    name: "Accepted",
  },
  {
    id: 6,
    name: "Failed",
  },
  {
    id: 7,
    name: "Finished",
  },
];

const MyEstados = ({ estados, onChangeEstados }) => {
  return (
    <div className="module-planner--filter">
      <h3>
        <FormattedMessage id="Status" />
      </h3>
      <div className="module-planner--filter-check">
        {data.map((item) => {
          const found = estados.find((element) => element === item.id);

          return (
            <label key={item.id} className="container-check">
              <span className="checkmark-label">
                <FormattedMessage id={item.name} />
              </span>
              <input
                onChange={() => onChangeEstados(item.id)}
                type="checkbox"
                checked={found ? "checked" : ""}
                value={item.id}
              />
              <span className="checkmark"></span>
            </label>
          );
        })}
      </div>
    </div>
  );
};

export default MyEstados;
