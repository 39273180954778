import initialState from "./initialState";
import {
  GET_INVENTORY_INIT,
  GET_INVENTORY_SUCCESS,
  GET_INVENTORY_FAILURE,
  RESET_INVENTORY,
  GET_PRODUCTS_FILTER_INIT,
  GET_PRODUCTS_FILTER_SUCCESS,
  GET_PRODUCTS_FILTER_FAILURE,
  RESET_PRODUCTS_FILTER,
  GET_GLOSA_ADDRESS_INIT,
  GET_GLOSA_ADDRESS_SUCCESS,
  GET_GLOSA_ADDRESS_FAILURE,
  RESET_GLOSA_ADDRESS,
  LIST_STOCK_CONTROL_INIT,
  LIST_STOCK_CONTROL_SUCCESS,
  LIST_STOCK_CONTROL_FAILURE,
  RESET_LIST_STOCK_CONTROL,
  UPDATE_LIST_STOCK_CONTROL_INIT,
  UPDATE_LIST_STOCK_CONTROL_SUCCESS,
  UPDATE_LIST_STOCK_CONTROL_FAILURE,
  RESET_UPDATE_LIST_STOCK_CONTROL,
} from "../../actions/inventory/types";

export default function inventory(state = initialState, action) {
  switch (action.type) {
    /* Setting */
    case RESET_INVENTORY:
      return {
        ...state,
        data: {},
        loading: true,
        status: 0,
      };
    case GET_INVENTORY_INIT:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_INVENTORY_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        status: action.payload.status,
      };
    case GET_INVENTORY_FAILURE:
      return {
        ...state,
        data: {},
        loading: false,
        status: 501,
      };
    case RESET_GLOSA_ADDRESS:
      return {
        ...state,
        dataGlosaAddress: {},
        loadingGlosaAddress: true,
        statusGlosaAddress: 0,
      };
    case GET_GLOSA_ADDRESS_INIT:
      return {
        ...state,
        loadingGlosaAddress: action.payload,
      };
    case GET_GLOSA_ADDRESS_SUCCESS:
      return {
        ...state,
        dataGlosaAddress: action.payload.data,
        loadingGlosaAddress: false,
        statusGlosaAddress: action.payload.status,
      };
    case GET_GLOSA_ADDRESS_FAILURE:
      return {
        ...state,
        dataGlosaAddress: {},
        loadingGlosaAddress: false,
        statusGlosaAddress: 501,
      };
    case RESET_LIST_STOCK_CONTROL:
      return {
        ...state,
        dataListStockControl: {},
        loadingListStockControl: true,
        statusListStockControl: 0,
      };
    case LIST_STOCK_CONTROL_INIT:
      return {
        ...state,
        loadingListStockControl: action.payload,
      };
    case LIST_STOCK_CONTROL_SUCCESS:
      return {
        ...state,
        dataListStockControl: action.payload.data,
        loadingListStockControl: false,
        statusListStockControl: action.payload.status,
      };
    case LIST_STOCK_CONTROL_FAILURE:
      return {
        ...state,
        dataListStockControl: {},
        loadingListStockControl: false,
        statusListStockControl: 501,
      };
    case RESET_UPDATE_LIST_STOCK_CONTROL:
      return {
        ...state,
        dataUpdateListStockControl: {},
        loadingUpdateListStockControl: false,
        statusUpdateListStockControl: 0,
      };
    case UPDATE_LIST_STOCK_CONTROL_INIT:
      return {
        ...state,
        loadingUpdateListStockControl: true,
      };
    case UPDATE_LIST_STOCK_CONTROL_SUCCESS:
      return {
        ...state,
        dataUpdateListStockControl: action.payload.data,
        loadingUpdateListStockControl: false,
        statusUpdateListStockControl: action.payload.status,
      };
    case UPDATE_LIST_STOCK_CONTROL_FAILURE:
      return {
        ...state,
        dataUpdateListStockControl: {},
        loadingUpdateListStockControl: false,
        statusUpdateListStockControl: 501,
      };
    case RESET_PRODUCTS_FILTER:
      return {
        ...state,
        dataProductsFilter: {},
        loadingProductsFilter: true,
        statusProductsFilter: 0,
      };
    case GET_PRODUCTS_FILTER_INIT:
      return {
        ...state,
        loadingProductsFilter: action.payload,
      };
    case GET_PRODUCTS_FILTER_SUCCESS:
      return {
        ...state,
        dataProductsFilter: action.payload.data,
        loadingProductsFilter: false,
        statusProductsFilter: action.payload.status,
      };
    case GET_PRODUCTS_FILTER_FAILURE:
      return {
        ...state,
        dataProductsFilter: {},
        loadingProductsFilter: false,
        statusProductsFilter: 501,
      };
    default:
      return state;
  }
}
