import initialState from "./initialState";
import {
  UPDATE_SUPERVISOR_INIT,
  UPDATE_SUPERVISOR_SUCCESS,
  UPDATE_SUPERVISOR_FAILURE,
  RESET_UPDATE_SUPERVISOR,
} from "../../actions/update_supervisor/types";

export default function supervisor(state = initialState, action) {
  switch (action.type) {
    case RESET_UPDATE_SUPERVISOR:
      return {
        ...state,
        dataUpdateSupervisor: {},
        loadingUpdateSupervisor: false,
        statusUpdateSupervisor: 0,
      };
    case UPDATE_SUPERVISOR_INIT:
      return {
        ...state,
        loadingUpdateSupervisor: true,
      };
    case UPDATE_SUPERVISOR_SUCCESS:
      return {
        ...state,
        dataUpdateSupervisor: action.payload.data,
        loadingUpdateSupervisor: false,
        statusUpdateSupervisor: action.payload.status,
      };
    case UPDATE_SUPERVISOR_FAILURE:
      return {
        ...state,
        dataUpdateSupervisor: {},
        loadingUpdateSupervisor: false,
        statusUpdateSupervisor: 501,
      };

    default:
      return state;
  }
}
