import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

/* Actions  */
import { getForgetPass } from "../../actions/login";

function ModalLogOut(props) {
  /*  Use  states  */
  const [email, setEmail] = useState("");

  /*  User Select from redux  */
  const state = useSelector((state) => state.login);
  const dispatch = useDispatch();

  const postForgetPass = (event) => {
    event.preventDefault();
    dispatch(
      getForgetPass({
        email: email,
      })
    );
  };
  let error = "";
  if (!state.dataForgetPass) {
    error = (
      <p className="text-error">
        <FormattedMessage id="ThereWasAnError" />
      </p>
    );
  }

  if (state.statusForgetPass === 200) {
    error = (
      <h3 className="text-success">
        <FormattedMessage id="NewPasswordWasSendingToYourEmail" />
      </h3>
    );
  }

  return (
    <>
      <Modal
        show={props.modal}
        onHide={() => props.setModal(false)}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            <FormattedMessage id="ForgetMyPassword" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="pass">
            <Form.Label>
              <FormattedMessage id="Email" />
            </Form.Label>
            <FormattedMessage id="WriteYourEmail">
              {(msg) => (
                <Form.Control
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  name={"email"}
                  autoComplete="current-password"
                  type="test"
                  placeholder={msg}
                />
              )}
            </FormattedMessage>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {error}
          <Button
            disabled={state.loadingForgetPass}
            variant="main"
            type="submit"
            onClick={postForgetPass}
          >
            {state.loadingForgetPass ? (
              <FormattedMessage id="Loading" />
            ) : (
              <FormattedMessage id="Send" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalLogOut;
