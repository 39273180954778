import React, { useEffect } from "react";

/* Components */
import Breadcrumbs from "../../components/update_installer/breadcrumbs";
import Form from "../../components/update_installer/form";

const UpdateInstaller = (props) => {
  useEffect(() => {
    const callsAPI = async () => {
      if (!props.location.state) {
        props.history.push("/");
      }
    };
    callsAPI();
  }, [props.history, props.location.state]);
  return (
    <div>
      <Breadcrumbs />
      {props.location.state && (
        <Form history={props.history} item={props.location.state.items} />
      )}
    </div>
  );
};

export default UpdateInstaller;
