import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const MyButton = (props) => {
  return (
    <div className="module-table--button">
      <div className="module-table--button-text">
        <h1>
          <FormattedMessage id="Installer" />
        </h1>
        <h2>
          <FormattedMessage id="InstallerList" />
        </h2>
      </div>
      <div className="module-table--button-add">
        <Link
          to={{
            pathname: "/dashboard/create_installer",
          }}
        >
          <button>
            <i className="fas fa-plus"></i>{" "}
            <FormattedMessage id="AddInstaller" />
          </button>
        </Link>
      </div>
    </div>
  );
};

export default MyButton;
