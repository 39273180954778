const initialState = {
  dataInstaller: {},
  loadingInstaller: true,
  statusInstaller: 0,
  dataUpdateStatusInstaller: {},
  loadingUpdateStatusInstaller: false,
  statusUpdateStatusInstaller: 0,
};

export default initialState;
