import {
  GET_INVENTORY_INIT,
  GET_INVENTORY_SUCCESS,
  GET_INVENTORY_FAILURE,
  GET_GLOSA_ADDRESS_INIT,
  GET_GLOSA_ADDRESS_SUCCESS,
  GET_GLOSA_ADDRESS_FAILURE,
  RESET_INVENTORY,
  RESET_GLOSA_ADDRESS,
  LIST_STOCK_CONTROL_INIT,
  LIST_STOCK_CONTROL_SUCCESS,
  LIST_STOCK_CONTROL_FAILURE,
  RESET_LIST_STOCK_CONTROL,
  UPDATE_LIST_STOCK_CONTROL_INIT,
  UPDATE_LIST_STOCK_CONTROL_SUCCESS,
  UPDATE_LIST_STOCK_CONTROL_FAILURE,
  RESET_UPDATE_LIST_STOCK_CONTROL,
  GET_PRODUCTS_FILTER_INIT,
  GET_PRODUCTS_FILTER_SUCCESS,
  GET_PRODUCTS_FILTER_FAILURE,
  RESET_PRODUCTS_FILTER,
} from "./types";
import API from "./api";

/* Actions Creators */

export function resetInventory() {
  return {
    type: RESET_INVENTORY,
  };
}

/* Get Inventory (Async) */

function getInventorySuccess(data) {
  return {
    type: GET_INVENTORY_SUCCESS,
    payload: data,
  };
}

function getInventoryFailure(error) {
  console.log(error);
  return {
    type: GET_INVENTORY_FAILURE,
    payload: error,
  };
}

function getInventoryInit(data) {
  return {
    type: GET_INVENTORY_INIT,
    payload: data.loading,
  };
}

export function getInventory(data) {
  return async (dispatch) => {
    dispatch(getInventoryInit(data));
    try {
      const resp = await API.data.getInventory(data);
      return dispatch(getInventorySuccess(resp));
    } catch (error) {
      return dispatch(getInventoryFailure(error));
    }
  };
}

export function resetProductsFilter() {
  return {
    type: RESET_PRODUCTS_FILTER,
  };
}

/* Get ProductsFilter (Async) */

function getProductsFilterSuccess(data) {
  return {
    type: GET_PRODUCTS_FILTER_SUCCESS,
    payload: data,
  };
}

function getProductsFilterFailure(error) {
  console.log(error);
  return {
    type: GET_PRODUCTS_FILTER_FAILURE,
    payload: error,
  };
}

function getProductsFilterInit(data) {
  return {
    type: GET_PRODUCTS_FILTER_INIT,
    payload: data.loading,
  };
}

export function getProductsFilter(data) {
  return async (dispatch) => {
    dispatch(getProductsFilterInit(data));
    try {
      const resp = await API.data.getProductsFilter(data);
      return dispatch(getProductsFilterSuccess(resp));
    } catch (error) {
      return dispatch(getProductsFilterFailure(error));
    }
  };
}

export function resetGlosaAddress() {
  return {
    type: RESET_GLOSA_ADDRESS,
  };
}

/* Get GlosaAddress (Async) */

function getGlosaAddressSuccess(data) {
  return {
    type: GET_GLOSA_ADDRESS_SUCCESS,
    payload: data,
  };
}

function getGlosaAddressFailure(error) {
  return {
    type: GET_GLOSA_ADDRESS_FAILURE,
    payload: error,
  };
}

function getGlosaAddressInit(data) {
  return {
    type: GET_GLOSA_ADDRESS_INIT,
    payload: data.loading,
  };
}

export function getGlosaAddress(data) {
  return async (dispatch) => {
    dispatch(getGlosaAddressInit(data));
    try {
      const resp = await API.data.getGlosaAddress(data);
      return dispatch(getGlosaAddressSuccess(resp));
    } catch (error) {
      return dispatch(getGlosaAddressFailure(error));
    }
  };
}

export function resetListStockControl() {
  return {
    type: RESET_LIST_STOCK_CONTROL,
  };
}

/* Get ListStockControl (Async) */

function listStockControlSuccess(data) {
  return {
    type: LIST_STOCK_CONTROL_SUCCESS,
    payload: data,
  };
}

function listStockControlFailure(error) {
  console.log(error);
  return {
    type: LIST_STOCK_CONTROL_FAILURE,
    payload: error,
  };
}

function listStockControlInit(data) {
  return {
    type: LIST_STOCK_CONTROL_INIT,
    payload: data.loading,
  };
}

export function listStockControl(data) {
  return async (dispatch) => {
    dispatch(listStockControlInit(data));
    try {
      const resp = await API.data.listStockControl(data);
      return dispatch(listStockControlSuccess(resp));
    } catch (error) {
      return dispatch(listStockControlFailure(error));
    }
  };
}

export function resetUpdateListStockControl() {
  return {
    type: RESET_UPDATE_LIST_STOCK_CONTROL,
  };
}

/* Get UpdateListStockControl (Async) */

function updateListStockControlSuccess(data) {
  return {
    type: UPDATE_LIST_STOCK_CONTROL_SUCCESS,
    payload: data,
  };
}

function updateListStockControlFailure(error) {
  console.log(error);
  return {
    type: UPDATE_LIST_STOCK_CONTROL_FAILURE,
    payload: error,
  };
}

function updateListStockControlInit(data) {
  return {
    type: UPDATE_LIST_STOCK_CONTROL_INIT,
    payload: data.loading,
  };
}

export function updateListStockControl(data) {
  return async (dispatch) => {
    dispatch(updateListStockControlInit(data));
    try {
      const resp = await API.data.updateListStockControl(data);
      return dispatch(updateListStockControlSuccess(resp));
    } catch (error) {
      return dispatch(updateListStockControlFailure(error));
    }
  };
}
