import React from "react";
import { FormattedMessage } from "react-intl";
import DatePicker from "react-datepicker";
import Bodega from "../filters/filtersBodegaHeader";
import Producto from "../filters/filtersProductHeader";

import "react-datepicker/dist/react-datepicker.css";

const MyHeader = (props) => {
  return (
    <header className="header--search">
      <div className="box-header left">
        <i className="far fa-calendar-alt"></i>
        <FormattedMessage id="From">
          {(msg) => (
            <DatePicker
              placeholderText={msg}
              className="dateInput"
              dateFormat="dd-MM-yyyy"
              selected={props.startDate}
              onChange={(date) => {
                props.handleDateStart(date);
              }}
            />
          )}
        </FormattedMessage>
      </div>
      <div className="box-header left">
        <i className="far fa-calendar-alt"></i>
        <FormattedMessage id="To">
          {(msg) => (
            <DatePicker
              placeholderText={msg}
              className="dateInput"
              dateFormat="dd-MM-yyyy"
              selected={props.endDate}
              onChange={(date) => {
                props.handleDateEnd(date);
              }}
            />
          )}
        </FormattedMessage>
      </div>
      <Bodega
        id={false}
        bodegaIdError={props.form.bodega_id_error}
        handleChange={props.handleChange}
        bodegaId={props.form.bodega_id}
      />
      <Producto
        bodegaIdError={props.form.bodega_id_error}
        handleChange={props.handleChange}
        bodegaId={props.form.bodega_id}
        productoId={props.form.producto_id}
      />
    </header>
  );
};

export default MyHeader;
