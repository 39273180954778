import initialState from "./initialState";
import {
  ADD_SUPERVISOR_INIT,
  ADD_SUPERVISOR_SUCCESS,
  ADD_SUPERVISOR_FAILURE,
  RESET_ADD_SUPERVISOR,
} from "../../actions/create_supervisor/types";

export default function supervisor(state = initialState, action) {
  switch (action.type) {
    /* Setting */
    case RESET_ADD_SUPERVISOR:
      return {
        ...state,
        dataAddSupervisor: {},
        loadingAddSupervisor: false,
        statusAddSupervisor: 0,
      };
    case ADD_SUPERVISOR_INIT:
      return {
        ...state,
        loadingAddSupervisor: true,
      };
    case ADD_SUPERVISOR_SUCCESS:
      return {
        ...state,
        dataAddSupervisor: action.payload.data,
        loadingAddSupervisor: false,
        statusAddSupervisor: action.payload.status,
      };
    case ADD_SUPERVISOR_FAILURE:
      return {
        ...state,
        dataAddSupervisor: {},
        loadingAddSupervisor: false,
        statusAddSupervisor: 501,
      };

    default:
      return state;
  }
}
