import { combineReducers } from "redux";
import home from "./home";
import instalationPending from "./instalation_pending";

import login from "./login";
import filters from "./filters";
import createTask from "./create_task";
import createClient from "./create_client";
import createInstaller from "./create_installer";
import updateTask from "./update_task";
import updateClient from "./update_client";
import updateInstaller from "./update_installer";
import updateSupervisor from "./update_supervisor";
import viewTask from "./view_task";
import inventory from "./inventory";
import wareHouse from "./ware_house";
import clients from "./clients";
import movements from "./movements";
import installer from "./installer";
import planner from "./planner";
import supervisor from "./supervisor";
import createSupervisor from "./create_supervisor";

const rootReducer = combineReducers({
  home,
  instalationPending,
  login,
  filters,
  inventory,
  createTask,
  createClient,
  createInstaller,
  updateTask,
  updateClient,
  updateInstaller,
  updateSupervisor,
  viewTask,
  wareHouse,
  clients,
  installer,
  supervisor,
  planner,
  createSupervisor,
  movements,
});

export default rootReducer;
